import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, IconButton, Input, Modal, ModalClose, Typography } from '@mui/joy';
import palette from '../../styles/theme';
import { SxProps } from '@mui/material';
import PlayerAvatar from '../PlayerAvatar/PlayerAvatar';
import { useAuthContext } from '../../context/AuthContext';
import { usePlayerNickname } from '../../hook/helper/usePlayerNickname';
import EditIcon from '@mui/icons-material/Edit';
import AvatarSelector from './AvatarSelector';
import CheckIcon from '@mui/icons-material/Check';
import useLazyApi from '../../hook/api/useLazyApi';
import { usePlayerContext } from '../../context/PlayerContext';
import { useTranslation } from 'react-i18next';
import { Player } from '@ztp/shared';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from '../../context/SnackbarContext';
import { useResponsive } from '../../hook/helper/useResponsive';
import { useWeb3Disconnect } from '../../hook/web3/useWeb3Disconnect';

interface ProfileButtonProps {}

const ProfileButton: React.FC<ProfileButtonProps> = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'disconnect-wallet' });
  const { isMobile } = useResponsive();
  const [open, setOpen] = useState(false);
  const { loading, fetch } = useLazyApi<Player, any>('POST', 'player/update');
  const [selectAvatar, setSelectAvatar] = useState(false);
  const [editNickname, setEditNickname] = useState(false);
  const [inputNickname, setInputNickname] = useState('');
  const { player, setPlayer } = useAuthContext();
  const { nickname } = usePlayerNickname(player);
  const { state } = usePlayerContext();
  const { disconnect } = useWeb3Disconnect();
  const { showMessage } = useSnackbar();

  const address = useMemo(() => {
    return player?.smartAccount || player?.id || '';
  }, [player]);

  const shortAddress = useMemo(() => {
    return `${address.substring(0, 7)}...${address.substring(address.length - 6, address.length - 1)}`;
  }, [address]);

  const shortId = useMemo(() => {
    const id = player?.id || '';
    return `${id.substring(0, 7)}...${id.substring(id.length - 6, id.length - 1)}`;
  }, [player]);

  const handleAvatarSelected = useCallback(
    (avatarId: number) => {
      setSelectAvatar(false);

      if (avatarId == -1 || !player) {
        return;
      }

      fetch({
        player: {
          id: player.id,
          avatarId,
        },
        tableId: state.table?.id,
      }).then((success) => {
        if (!success) {
          return;
        }

        player.avatarId = avatarId;
        setPlayer({
          ...player,
        });
      });
    },
    [fetch, setSelectAvatar, setPlayer, state, player]
  );

  const handleChangeNickname = useCallback(() => {
    if (!inputNickname || !player) {
      return;
    }

    fetch({
      player: {
        id: player.id,
        nickname: inputNickname,
      },
      tableId: state.table?.id,
    }).then((success) => {
      if (!success) {
        return;
      }

      player.nickname = inputNickname;
      setPlayer({
        ...player,
      });
    });
    setEditNickname(false);
  }, [fetch, setPlayer, state, inputNickname, player]);

  const copyAddressToClipboard = useCallback(() => {
    navigator.clipboard.writeText(address).then(
      () => {
        showMessage('Copied to clipboard', 'success');
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  }, [address, showMessage]);

  const copyIdToClipboard = useCallback(() => {
    navigator.clipboard.writeText(player?.id || '').then(
      () => {
        showMessage('Copied to clipboard', 'success');
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  }, [player, showMessage]);

  if (!player) {
    return <></>;
  }

  return (
    <>
      {selectAvatar && <AvatarSelector onSelect={handleAvatarSelected} />}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={styles.container}>
          <ModalClose color="neutral" variant="plain" />
          <Box sx={styles.content}>
            <IconButton onClick={() => setSelectAvatar(true)} variant="plain" sx={styles.iconButton}>
              <Box width={'128px'} height={'128px'}>
                <PlayerAvatar avatarId={player.avatarId} />
              </Box>
              <Box sx={{ position: 'absolute', top: 5, right: 10 }}>
                <EditIcon sx={{ color: 'white' }} />
              </Box>
            </IconButton>

            <Box sx={{ marginTop: '20px' }} />
            <Typography level="body-sm">{'Address:'}</Typography>
            <Typography sx={{ overflow: 'hidden' }} width={'150px'} id="nested-modal-title" level="body-lg">
              {shortAddress}
            </Typography>
            <IconButton disabled={loading} onClick={copyAddressToClipboard} variant="plain" sx={styles.iconButton}>
              <ContentCopyIcon sx={{ color: 'white' }} />
            </IconButton>

            <Box sx={{ marginTop: '10px' }} />
            <Typography level="body-sm">{'Player ID:'}</Typography>
            <Typography sx={{ overflow: 'hidden' }} width={'150px'} id="nested-modal-title" level="body-lg">
              {shortId}
            </Typography>
            <IconButton disabled={loading} onClick={copyIdToClipboard} variant="plain" sx={styles.iconButton}>
              <ContentCopyIcon sx={{ color: 'white' }} />
            </IconButton>

            <Box sx={{ marginTop: '10px' }} />
            <Typography level="body-sm">{'Nickname:'}</Typography>
            {editNickname ? (
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} marginTop={'5px'}>
                <Input
                  disabled={loading}
                  sx={{ width: '150px' }}
                  value={inputNickname}
                  variant="solid"
                  onKeyDown={(e) => e.key == 'Enter' && handleChangeNickname()}
                  onChange={(e) => setInputNickname(e.target.value.substring(0, 12))}
                />
                <IconButton disabled={loading} onClick={handleChangeNickname} variant="plain" sx={styles.iconButton}>
                  <CheckIcon sx={{ color: 'white' }} />
                </IconButton>
              </Box>
            ) : (
              <IconButton onClick={() => setEditNickname(true)} size="sm" variant="plain" sx={styles.iconButton}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Typography width={'150px'} id="nested-modal-title" level="body-lg">
                    {nickname}
                  </Typography>
                  <EditIcon sx={{ color: 'white', marginTop: '5px' }} />
                </Box>
              </IconButton>
            )}
            <Box
              sx={{
                mt: 1,
                display: 'flex',
                gap: 1,
                marginTop: 'auto',
                flexDirection: 'column',
              }}
            >
              <Button
                variant="solid"
                color="neutral"
                sx={{
                  color: palette.text.primary,
                  minHeight: '40px',
                  height: '40px',
                  maxHight: '40px',
                  borderRadius: 5,
                  borderWidth: 0.5,
                  hover: { borderColor: 'red', backgroundColor: 'transparent' },
                }}
                onClick={() => disconnect()}
              >
                <Typography color="neutral" level="body-lg">
                  {t('btn')}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box>
        {isMobile ? (
          <IconButton onClick={() => setOpen(true)}>
            <Box width={'38px'} height={'38px'}>
              <PlayerAvatar avatarId={player.avatarId} />
            </Box>
          </IconButton>
        ) : (
          <Button
            variant="solid"
            color="neutral"
            sx={{
              color: palette.text.primary,
              minHeight: '40px',
              height: '40px',
              maxHight: '40px',
              borderRadius: 20,
              borderWidth: 0.5,
              hover: { borderColor: 'red', backgroundColor: 'transparent' },
            }}
            endDecorator={
              <Box width={'32px'} height={'32px'}>
                <PlayerAvatar avatarId={player.avatarId} />
              </Box>
            }
            onClick={() => setOpen(true)}
          >
            {!isMobile && <Typography level="body-lg">{nickname}</Typography>}
          </Button>
        )}
      </Box>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    height: 530,
    backgroundColor: palette.primary.solidBg,
    borderRadius: 30,
    border: 'solid',
    borderColor: palette.primary.solidBorder,
    borderWidth: 0.5,
    boxShadow: '24',
  } as SxProps,
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    p: 4,
  } as SxProps,
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    fontSize: '13px',
  } as SxProps,
  button: {
    color: palette.text.primary,
    hover: { color: 'transparent' },
  } as SxProps,
};

export default ProfileButton;
