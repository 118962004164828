import { Box, Typography } from '@mui/joy';
import React, { useMemo } from 'react';
import PlayerBet from '../PlayerBet/PlayerBet';
import { useAuthContext } from '../../context/AuthContext';
import { RoundStage, TablePlayer, TableState } from '@ztp/shared';
import CardView from '../Card/CardView';
import DealerPosition from '../DealerPosition/DealerPosition';
import TurnTimer from '../TurnTimer/TurnTimer';
import Crown from '../Crown/Crown';
import logo from '../../assets/logo.png';
import '../../styles/effect.css';
import Pots from '../Pots/Pots';
import TotalPots from '../Pots/TotalPots';
import { useEndGamePresenter } from '../../hook/games/useEndGamePresenter';
import { useCashGameSoundEffect } from '../../hook/sound/useCashGameSoundEffect';
import { TableViewProps } from './TableView';
import PlayerState from '../PlayerState/PlayerState';
import PlayerNotification from '../PlayerNotification/PlayerNotification';
import CardPair from '../Card/CardPair';
import PlayerAvatar from '../PlayerAvatar/PlayerAvatar';
import PlayerRank from '../PlayerRank/PlayerRank';

interface PlayerPosition {
  position: number;
  px: number; // Table Player point x
  py: number; // Table Player point y
  dx: number; // Dealer position point x
  dy: number; // Dealer position point y
  bx: number; // Player bet point x
  by: number; // Player bet point y
}

const logoPX = 350;
const logoPY = 465;
const cardWidth = 150;
const cardHeight = 200;
const totalPotsPX = 950;
const totalPotsPY = 400;
const communityCardsPX = 670;
const communityCardsPY = 440;
const communityCardsMargin = 20;
const potsPX = 600;
const potsPY = 650;
const potsWidth = 900;
const potsHeight = 200;
const playerWidth = 240;
const playerHeight = 250;
const timerWidth = 240;
const timerHeight = 12;

const TableViewDesktop: React.FC<TableViewProps> = ({ table, round, tournament }) => {
  const { player } = useAuthContext();
  const { potWinners } = useEndGamePresenter();
  useCashGameSoundEffect(potWinners);

  const myAddress = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const communityCards = round?.deck.communityCards || [];
  const tableType = table.type;
  const active = table.tableState === TableState.InProgress;
  const pots = round?.pots;
  const stakePot = round?.stakePot || 0;
  const playerTurn = round?.currentPlayerTurn.toLocaleLowerCase();
  const dealerSeatNumber = round?.dealerSeatNumber || 0;
  const roundStage = round?.roundStage || RoundStage.Finish;

  const players: TablePlayer[] = useMemo(
    () => (table ? Object.keys(table.players).map((k) => table.players[k]) : []),
    [table]
  );

  const myPlayer = players.find((p) => p.address.toLocaleLowerCase() == myAddress.toLocaleLowerCase());

  const playersPositions = useMemo(() => {
    const positions: PlayerPosition[] = [];

    if (tableType.maxPlayer <= 6) {
      positions.push({ position: 0, px: 950, py: 930, dx: 950, dy: 930, bx: 1020, by: 800 });
      positions.push({ position: 0, px: 140, py: 780, dx: 320, dy: 780, bx: 420, by: 700 });
      positions.push({ position: 0, px: 140, py: 120, dx: 320, dy: 120, bx: 420, by: 340 });
      positions.push({ position: 0, px: 950, py: 40, dx: 1130, dy: 40, bx: 1020, by: 330 });
      positions.push({ position: 0, px: 1800, py: 120, dx: 1800, dy: 120, bx: 1660, by: 330 });
      positions.push({ position: 0, px: 1800, py: 780, dx: 1800, dy: 780, bx: 1660, by: 700 });
    } else if (tableType.maxPlayer <= 9) {
      positions.push({ position: 0, px: 950, py: 930, dx: 950, dy: 930, bx: 1000, by: 800 });
      positions.push({ position: 0, px: 280, py: 880, dx: 460, dy: 880, bx: 520, by: 770 });
      positions.push({ position: 0, px: 10, py: 480, dx: 190, dy: 480, bx: 320, by: 550 });
      positions.push({ position: 0, px: 220, py: 70, dx: 400, dy: 70, bx: 420, by: 340 });
      positions.push({ position: 0, px: 720, py: 40, dx: 900, dy: 40, bx: 790, by: 330 });
      positions.push({ position: 0, px: 1200, py: 40, dx: 1380, dy: 40, bx: 1270, by: 330 });
      positions.push({ position: 0, px: 1700, py: 70, dx: 1700, dy: 70, bx: 1660, by: 340 });
      positions.push({ position: 0, px: 1940, py: 480, dx: 1940, dy: 480, bx: 1760, by: 550 });
      positions.push({ position: 0, px: 1620, py: 880, dx: 1620, dy: 880, bx: 1520, by: 770 });
    } else {
      console.error('Max players for table is 9 !!!!!!!!!!!');
    }

    let currentPosition = myPlayer?.seatNumber || 0;
    positions.forEach((p) => {
      p.position = currentPosition;
      currentPosition++;
      if (currentPosition == positions.length) {
        currentPosition = 0;
      }
    });

    return positions.sort((p1, p2) => (p1.position < p2.position ? -1 : 1));
  }, [tableType, myPlayer]);

  return (
    // <Box position="relative" height={'100%'} flex={1} flexDirection="column" display="flex">
    <div
      style={{
        flex: 1,
        height: '100%',
        margin: 0,
        padding: 0,
        overflow: 'hidden' /* Prevents scrolling */,
        width: '100%',
        alignSelf: 'center',
        justifySelf: 'center',
        aspectRatio: 3 / 2,
        flexDirection: 'column' as const,
        marginTop: '0',
        fontSize: '24px',
      }}
    >
      <svg width="100%" height="100%" viewBox="0 0 2200 1200" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <radialGradient id="pipeGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
            <stop offset="0%" style={{ stopColor: '#2a2a2a' }} />
            <stop offset="30%" style={{ stopColor: '#2a2a2a' }} />
            <stop offset="60%" style={{ stopColor: '#2a2a2a' }} />
            <stop offset="100%" style={{ stopColor: '#0e0e0e' }} />
          </radialGradient>

          <pattern id="roughPattern" patternUnits="userSpaceOnUse" width="1" height="1">
            <circle cx="0.5" cy="0.5" r="0.50" fill="#07332D" />
            <circle cx="0.9" cy="0.9" r="0.35" fill="#06302A" />
            <circle cx="0.7" cy="0.1" r="0.35" fill="#06302A" />
            <circle cx="0.5" cy="0.5" r="0.35" fill="#06302A" />
            <circle cx="0.1" cy="0.7" r="0.35" fill="#06302A" />
            <circle cx="0.3" cy="0.3" r="0.50" fill="#06302A" />
          </pattern>
        </defs>
        {/* ---- TABLE BOARD ----- */}
        <rect
          x="50"
          y="100"
          width="2100"
          height="1000"
          rx="550"
          fill="url(#pipeGradient)"
          stroke="#000000"
          strokeWidth="0.01"
        />
        <rect
          x="150"
          y="200"
          width="1900"
          height="800"
          rx="450"
          fill="url(#roughPattern)"
          stroke="#000000"
          strokeWidth="0.01"
        ></rect>

        <foreignObject x={logoPX} y={logoPY} width={1500} height={150}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            justifyItems={'center'}
            sx={{ opacity: 0.2 }}
          >
            <img
              style={{ height: '150px', width: '150px', marginRight: '5px' }}
              src={logo}
              alt="Zero Trust Poker Logo"
            />
            <Typography sx={{ color: 'white', fontStyle: 'italic', fontSize: '104px' }}>Zero Trust Poker</Typography>
          </Box>
        </foreignObject>

        {/* ---- Total Pots ----- */}
        <foreignObject x={totalPotsPX} y={totalPotsPY} width={220} height={30}>
          {roundStage > RoundStage.PreFlop && <TotalPots pots={pots} />}
        </foreignObject>

        {/* ---- Community Cards ----- */}
        {communityCards.map((c, i) => {
          return (
            <foreignObject
              key={i}
              x={communityCardsPX + i * (cardWidth + communityCardsMargin)}
              y={communityCardsPY}
              width={cardWidth}
              height={cardHeight}
            >
              <CardView key={c.card} card={c.card} />
            </foreignObject>
          );
        })}
        {/* ---- Players ----- */}

        {players.map((p) => {
          const roundPlayer = round?.players.find(
            (rp) => rp.address.toLocaleLowerCase() == p.address.toLocaleLowerCase()
          );
          return (
            <React.Fragment key={p.address}>
              <foreignObject
                x={playersPositions[p.seatNumber].bx}
                y={playersPositions[p.seatNumber].by}
                width="130"
                height="120"
              >
                {roundStage < RoundStage.Presentation && <PlayerBet player={p} />}
              </foreignObject>

              <foreignObject
                x={playersPositions[p.seatNumber].px + 70}
                y={playersPositions[p.seatNumber].py - 60}
                width="100"
                height="100"
              >
                <Crown
                  visable={
                    roundStage == RoundStage.Presentation &&
                    !!potWinners?.winners &&
                    potWinners.winners.some((w) => w.playerAddress == p.address)
                  }
                />
              </foreignObject>

              <foreignObject
                key={p.address}
                x={playersPositions[p.seatNumber].px}
                y={playersPositions[p.seatNumber].py}
                width={playerWidth}
                height={playerHeight}
              >
                <PlayerRank tournament={tournament} playerAddress={p.address}>
                  <PlayerAvatar avatarId={p.player.avatarId} />
                </PlayerRank>
              </foreignObject>

              <foreignObject
                x={playersPositions[p.seatNumber].dx}
                y={playersPositions[p.seatNumber].dy}
                width="60"
                height="50"
              >
                <DealerPosition visable={roundStage < RoundStage.Presentation && dealerSeatNumber == p.seatNumber} />
              </foreignObject>

              {roundPlayer && active && (
                <foreignObject
                  x={playersPositions[p.seatNumber].px + 15}
                  y={playersPositions[p.seatNumber].py + 65}
                  width={playerWidth - 30}
                  height={playerHeight - 120}
                >
                  <CardPair roundPlayer={roundPlayer} />
                </foreignObject>
              )}

              <foreignObject
                x={playersPositions[p.seatNumber].px}
                y={playersPositions[p.seatNumber].py + 185}
                width={playerWidth}
                height={100}
              >
                <PlayerState
                  player={p}
                  visible={roundStage < RoundStage.Presentation && p.address.toLocaleLowerCase() == playerTurn}
                />
              </foreignObject>

              <foreignObject
                x={playersPositions[p.seatNumber].px}
                y={playersPositions[p.seatNumber].py + 165}
                width={playerWidth}
                height={100}
              >
                <PlayerNotification active={active} player={p} potWinners={potWinners} />
              </foreignObject>

              <foreignObject
                x={playersPositions[p.seatNumber].px}
                y={playersPositions[p.seatNumber].py + playerHeight + 5} // Position timer below the player
                width={timerWidth}
                height={timerHeight}
              >
                <TurnTimer
                  visible={roundStage < RoundStage.Presentation && p.address.toLocaleLowerCase() == playerTurn}
                  tableType={tableType}
                />
              </foreignObject>
            </React.Fragment>
          );
        })}
        {/* ------- POT ------ */}
        <foreignObject x={potsPX} y={potsPY} width={potsWidth} height={potsHeight}>
          {roundStage != RoundStage.Finish && (
            <Pots
              pots={pots}
              stakePot={stakePot}
              distributePotIndex={potWinners?.potIndex}
              smallBlind={tableType.small}
            />
          )}
        </foreignObject>
      </svg>
    </div>
    // </Box>
  );
};

export default TableViewDesktop;
