import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import { PlayerContextProvider } from './context/PlayerContext';
import { WebSocketProvider } from './context/WebSocketContext';
import { WagmiContextProvider } from './context/WagmiContext';
import { P2PEventProvider } from './context/P2PEventContext';
import Shell from './container/ShellContainer';
import GameContainer from './container/GameContainer';
import { SnackbarContextProvider } from './context/SnackbarContext';
import { ModalContextProvider } from './context/ModalContext';
import { ThemeProvider } from './context/ThemeProvider';
import { SmartAccounctContextProvider } from './context/SmartAccounctContext';

function App() {
  return (
    <WagmiContextProvider>
      <SmartAccounctContextProvider>
        <AuthContextProvider>
          <PlayerContextProvider>
            <ThemeProvider>
              <SnackbarContextProvider>
                <ModalContextProvider>
                  <WebSocketProvider>
                    <P2PEventProvider>
                      <Router>
                        <Routes>
                          <Route
                            path="/"
                            element={
                              <Shell>
                                <GameContainer />
                              </Shell>
                            }
                          />
                        </Routes>
                      </Router>
                    </P2PEventProvider>
                  </WebSocketProvider>
                </ModalContextProvider>
              </SnackbarContextProvider>
            </ThemeProvider>
          </PlayerContextProvider>
        </AuthContextProvider>
      </SmartAccounctContextProvider>
    </WagmiContextProvider>
  );
}

export default App;
