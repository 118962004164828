import React from 'react';
import PlayerActions from './PlayerActions';
import PostRoundActions from './PostRoundActions';

interface PlayerPreActionProps {
  myTurn: boolean;
  roundEnded: boolean;
}

const PlayerActionView: React.FC<PlayerPreActionProps> = ({ myTurn, roundEnded }) => {
  if (roundEnded) {
    return <PostRoundActions />;
  }

  return <PlayerActions myTurn={myTurn} />;
};

export default PlayerActionView;
