import EventEmitter from 'events';
import React, { useEffect, useContext, useCallback, useMemo } from 'react';
import useCryptoApi from '../hook/cryptography/useCryptoApi';
import { P2PEvent, P2PMessage, P2PWsMsgPayload } from '@ztp/shared';
import { useAuthContext } from './AuthContext';
import { useWsContext } from './WebSocketContext';
import { ethers } from 'ethers';

interface IP2PEventContext {
  subscribe?: <T>(p2pEvent: P2PEvent, lisener: (payload: P2PWsMsgPayload, msg: P2PMessage<T>) => void) => () => void;
  emit?: <T>(event: P2PEvent, publicKey: string, to: string, payload: T) => Promise<void>;
  ready: boolean;
}

const defaultValue = {} as IP2PEventContext;

const P2PEventContext = React.createContext<IP2PEventContext>(defaultValue);
export const useP2PEventContext = () => useContext(P2PEventContext);

export function P2PEventProvider({ children }: any) {
  const { encryptMessage, decryptMessage } = useCryptoApi();
  const { player, keyPairs } = useAuthContext();
  const ws = useWsContext();
  const eventEmmiter = useMemo(() => {
    const emitter = new EventEmitter();
    emitter.setMaxListeners(1000);
    return emitter;
  }, []);

  const verifyPlayer = useCallback((p2pMessage: P2PMessage<any>) => {
    try {
      const message = {
        encryptionPublicKey: p2pMessage.encryptionPublicKey,
        challengeMessage: p2pMessage.challengeMessage,
      };

      const signerAddress = ethers.verifyMessage(JSON.stringify(message), p2pMessage.signedMessage);
      if (signerAddress === p2pMessage.from) {
        return true;
      }
    } catch (error) {
      console.error('verifyPlayer failed with error: ' + error);
    }

    return false;
  }, []);

  useEffect(() => {
    if (!ws?.ready) {
      return;
    }

    const unsunscribe = ws.subscribe!<P2PWsMsgPayload>('p2p-private-message', async (payload) => {
      const { encryptedMessage, enceyptedKey, iv } = payload;
      try {
        const { decryptedMessage } = await decryptMessage(encryptedMessage, enceyptedKey, iv);
        if (!decryptedMessage) {
          console.error(`failed to decrypt message from player: ${payload.from}`);
          return;
        }

        const message: P2PMessage<any> = JSON.parse(decryptedMessage);
        if (!message) {
          console.error(`failed to decrypt message from player: ${payload.from}`);
          return;
        }

        if (!verifyPlayer(message)) {
          console.error(`failed to verfiy player: ${message.from}`);
          return;
        }

        eventEmmiter.emit(message.event, payload, message);
      } catch (error) {
        console.error('failed to handle P2P message with error: ' + error);
      }
    });

    return () => unsunscribe();
  }, [eventEmmiter, ws.subscribe, verifyPlayer, decryptMessage, ws.ready]);

  const subscribe = useCallback(
    <T,>(event: P2PEvent, lisener: (payload: P2PWsMsgPayload, msg: P2PMessage<T>) => void) => {
      eventEmmiter.on(event, lisener);
      return () => eventEmmiter.removeListener(event, lisener);
    },
    [eventEmmiter]
  );

  const emit = useCallback(
    async <T,>(event: P2PEvent, publicKey: string, toPlayer: string, payload: T) => {
      if (!ws.emit || !player) {
        console.error('WebSocket emit not ready');
        return;
      }

      if (!keyPairs?.encryptionKeyPair?.publicKey || !keyPairs?.signingKeyPair?.publicKey) {
        console.error('key-pair not ready');
        return;
      }

      const p2pMsg: P2PMessage<T> = {
        event: event,
        from: player.id,
        encryptionPublicKey: keyPairs.encryptionKeyPair.publicKey,
        challengeMessage: keyPairs.challengeMessage,
        signedMessage: keyPairs.signedMessage,
        payload: payload,
      };

      try {
        const json = JSON.stringify(p2pMsg);
        const { encryptedMessage, enceyptedKey, iv } = await encryptMessage(json, publicKey);

        const P2PMessage: P2PWsMsgPayload = {
          to: [toPlayer],
          from: player.id,
          iv,
          enceyptedKey,
          encryptedMessage,
        };

        ws.emit('p2p-private-message', 'p2p', false, P2PMessage);
      } catch (error) {
        console.error(`failed to emit P2P event:${event}, to player: ${toPlayer}, error: ${error}`);
      }
    },
    [ws, encryptMessage, player, keyPairs]
  );

  return (
    <P2PEventContext.Provider value={ws.ready ? { emit, subscribe, ready: ws.ready } : { ready: false }}>
      {children}
    </P2PEventContext.Provider>
  );
}
