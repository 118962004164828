import React, { useEffect, useMemo } from 'react';
import { TournamentEvent } from '@ztp/shared';
import { Box, Typography } from '@mui/joy';
import palette from '../../styles/theme';
import { useTranslation } from 'react-i18next';
import { useCountdown } from '../../hook/helper/useCountdown';
import { useModal } from '../../context/ModalContext';

interface TourneyBreakTimeProps {
  tournament: TournamentEvent;
}

export const TourneyBreakTime: React.FC<TourneyBreakTimeProps> = ({ tournament }) => {
  const { t: tourneyT } = useTranslation('translation', { keyPrefix: 'tourney' });
  const backTime = useMemo(() => (tournament?.backFromBreak ? new Date(tournament.backFromBreak) : null), [tournament]);
  const { timeLeftFormat, done } = useCountdown(backTime);
  const { close } = useModal();

  useEffect(() => {
    if (done) {
      close();
    }
  }, [close, done]);

  return (
    <Box
      flex={1}
      width={'280px'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      marginTop={'10px'}
      textAlign={'center'}
      alignSelf={'center'}
    >
      <Typography sx={{ color: palette.text.primary, whiteSpace: 'pre-line' }} level="body-md">
        {tourneyT('break')}
      </Typography>
      <Typography sx={{ color: palette.text.secondary, marginTop: '10px' }} level="h1">
        {timeLeftFormat}
      </Typography>
    </Box>
  );
};
