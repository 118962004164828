import React from 'react';
import { useResponsive } from '../../hook/helper/useResponsive';
import TourneyTableDesktop from './TourneyTableDesktop';
import TourneyTableMobile from './TourneyTableMobile';
import { useDealerEventHandler } from '../../hook/events/useDealerEventHandler';
import { useBakupperKey } from '../../hook/events/useBakupperKey';
import { useBakupperCards } from '../../hook/events/useBakupperCards';
import { useBackupCards } from '../../hook/events/useBackupCards';

const TourneyTable: React.FC = () => {
  const { isMobile } = useResponsive();
  useDealerEventHandler();
  useBackupCards();
  useBakupperKey();
  useBakupperCards();

  return isMobile ? <TourneyTableMobile /> : <TourneyTableDesktop />;
};

export default TourneyTable;
