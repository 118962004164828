import React, { useState } from 'react';
//import { Styles } from '../../styles/type';
import Badge from '@mui/joy/Badge';
import Chat from './Chat';
import { Box, IconButton } from '@mui/joy';
import ChatIcon from './ChatIcon';

const ChatButton = () => {
  const [open, setOpen] = useState(false);
  const [unreadMessage, setUnreadMessage] = useState<boolean>(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
    setUnreadMessage(false);
  };

  return (
    <Box width={'32px'}>
      <Chat visable={open} setUnreadMessage={setUnreadMessage} />
      <IconButton
        color="primary"
        sx={{ paddingInline: '0', width: '100%', minHeight: '100%', minWidth: '100%', height: '100%' }}
        onClick={handleClick}
      >
        <Badge
          invisible={!unreadMessage}
          sx={{ width: '100%', height: '100%', borderWidth: 0 }}
          variant="solid"
          color="warning"
          badgeInset="3px 3px 0 0"
        >
          <ChatIcon />
        </Badge>
      </IconButton>
    </Box>
  );
};

// const styles: Styles = {};

export default ChatButton;
