import React, { useMemo } from 'react';
import { TournamentEvent } from '@ztp/shared';
import { Box, Typography } from '@mui/joy';
import palette from '../../styles/theme';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../context/AuthContext';
import { Styles } from '../../styles/type';

interface TourneyPlayerResultProps {
  tourneyEvent: TournamentEvent;
}

export const TourneyPlayerResult: React.FC<TourneyPlayerResultProps> = ({ tourneyEvent }) => {
  const { t: tourneyT } = useTranslation('translation', { keyPrefix: 'tourney' });
  const { player } = useAuthContext();

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const myPlayer = useMemo(() => {
    return tourneyEvent?.players.find((p) => p.address == address);
  }, [tourneyEvent, address]);

  return (
    <Box
      flex={1}
      width={'260px'}
      display={'flex'}
      flexDirection={'column'}
      // justifyContent={'center'}
      alignItems={'center'}
      marginTop={'0px'}
      textAlign={'center'}
      alignSelf={'center'}
    >
      <Typography sx={{ color: palette.text.primary }} level="body-md">
        {tourneyT('thank-you')}
      </Typography>
      <Typography sx={{ color: palette.text.primary }} level="h1">
        🙏
      </Typography>

      <Typography sx={{ color: palette.text.primary, marginTop: 2 }} level="body-md">
        {`${tourneyT('your.place')}: `}
      </Typography>
      <Box style={styles.circule}>{myPlayer?.place || 0}</Box>
      <Typography sx={{ color: palette.text.primary, marginTop: 2 }} level="body-md">
        {`${tourneyT('you.win')}: `}
      </Typography>
      <Typography sx={{ color: palette.text.secondary }}> {`$${myPlayer?.prize || 0}`}</Typography>
    </Box>
  );
};

const styles: Styles = {
  circule: {
    marginTop: 2,
    backgroundColor: palette.warning.solidBorder,
    borderRadius: '50%',
    border: 'solid',
    borderWidth: '0.0vh 0.00vh 0.12vh 0.0vh',
    borderColor: palette.warning.solidBorder,
    width: '30px',
    height: '30px',
    textAlign: 'center',
    fontSize: '18px',
    display: 'flex',
    fontFamily: 'Audiowide',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
