import { useCallback } from 'react';
import { ethers } from 'ethers';
import { IERC20Factory } from '@ztp/shared';
import { Address, ContractType } from './type';
import { useContractAddress } from './useContractAddress';
import { sleep } from '../../helper/sleep';
import { useSmartAccount } from './useSmartAccount';

const FINALIZED_DELY = 6000;

export const useContarctAllowance = (contractType: ContractType) => {
  const { address, smartClient } = useSmartAccount();
  const { contractAddress } = useContractAddress(contractType);

  const approve = useCallback(
    async (tokenAddress: Address, amount: number, max: number) => {
      if (amount == 0 || !smartClient) {
        return true;
      }
      const allowance = await smartClient.readContract({
        address: tokenAddress as `0x${string}`,
        abi: IERC20Factory.abi,
        functionName: 'allowance',
        args: [address, contractAddress],
      });

      if (allowance) {
        const allowanceUsdt = parseFloat(ethers.formatUnits(allowance || 0, 6));
        if (allowanceUsdt >= amount) {
          return true;
        }
      }

      const iface = new ethers.Interface(IERC20Factory.abi);
      const data = iface.encodeFunctionData('approve', [contractAddress, ethers.parseUnits(max.toString(), 6)]);

      try {
        const approvalTx = await smartClient.sendUserOperation({
          uo: {
            target: tokenAddress as `0x${string}`,
            data: data as `0x${string}`,
            value: 0n,
          },
        });

        await sleep(FINALIZED_DELY);

        return true;
      } catch (error) {
        console.error(`failed to approve, err: ${error}`);
        return false;
      }
    },
    [contractAddress, address, smartClient]
  );

  return { approve };
};
