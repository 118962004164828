import useMental from '../../hook/cryptography/useMental';
import { useCallback, useMemo } from 'react';
import { usePlayerContext } from '../../context/PlayerContext';
import { useAuthContext } from '../../context/AuthContext';
import { CardKeys } from '@ztp/shared';

const useRevealMyCards = () => {
  const { player } = useAuthContext();
  const { revealCards, validateRevealCards } = useMental();
  const {
    state: { gameRound },
  } = usePlayerContext();

  const myAddress = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const reveal = useCallback(async () => {
    if (!gameRound) {
      return;
    }

    const deck = gameRound.deck;
    const myPlayer = gameRound?.players.find((p) => p.address == myAddress);
    if (!myPlayer) {
      console.warn('playerRevealCardsHandler player not found in round');
      return;
    }

    const cardKeys: CardKeys[] = deck.playerCardsIndexes[myAddress].map((index) => {
      return {
        index,
        keys: deck.playerEncryptedKeys[myAddress][index],
      };
    });

    const cards = await revealCards(cardKeys, gameRound.deck);
    if (!cards || !validateRevealCards(cards.map((c) => c.card))) {
      console.error(`useRevealMyCards falid to reveal cards`);
      return;
    }

    return cards;
  }, [gameRound, myAddress, validateRevealCards, revealCards]);

  return { reveal };
};

export default useRevealMyCards;
