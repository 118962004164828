import React, { useCallback, useContext, useEffect, useState } from 'react';
import usePersistState from '../hook/state/usePersistState';
import { Card, Table, GameRound, TournamentEvent, TableCurrentResponse, IEntitiy } from '@ztp/shared';
import { useTabActive } from '../hook/events/useTabActive';
import useLazyApi from '../hook/api/useLazyApi';
import { useAuthContext } from './AuthContext';

interface IPlayerContext {
  state: PlayerState;
  updateState: (state: Partial<PlayerState>) => void;
  settings: PlayerSettings;
  updateSettings: (settings: Partial<PlayerSettings>) => void;
  clearState: () => void;
  clearRoundStates: () => void;
  addTourney: (tourney: TournamentEvent) => void;
  removeTourney: (tourney: TournamentEvent) => void;
  reload: () => void;
  syncTableRound: () => Promise<void>;
}

interface PlayerRevealCard {
  [key: string]: Card[];
}

interface PlayerSettings {
  soundEnabled: boolean;
  volume: number;
}

interface PlayerState {
  table?: Table;
  gameRound?: GameRound;
  tourney?: TournamentEvent[];
  playerRevealCards?: PlayerRevealCard;
  myPlayerRevealCard: boolean;
}

const defaultState = { tourney: [] as TournamentEvent[] } as PlayerState;

const defaultValue = {
  state: defaultState,
} as IPlayerContext;

const defaultSettings = {
  soundEnabled: false,
} as PlayerSettings;

const PlayerContext = React.createContext<IPlayerContext>(defaultValue);
export const usePlayerContext = () => useContext(PlayerContext);

export function PlayerContextProvider({ children }: any) {
  //TODO useREDUCER
  const [used, setUsed] = useState(false);
  const { state, setState, reload: reloadPlayerState } = usePersistState<PlayerState>('player-state', defaultState);
  const { player } = useAuthContext();
  const {
    state: settings,
    setState: setSettings,
    reload: reloadPlayerSettings,
  } = usePersistState<PlayerSettings>('playerSettings', defaultSettings);

  const { data, code, called, fetch: sendRequest } = useLazyApi<TableCurrentResponse, any>('POST', 'table/current');

  const { isTabActive } = useTabActive();

  const compareEntity = useCallback((entity1?: IEntitiy, entity2?: IEntitiy) => {
    if (entity1?.id != entity2?.id) {
      return true;
    }
    if (entity1?.version != entity2?.version) {
      return true;
    }

    return false;
  }, []);

  const compareState = useCallback(
    (currentState: PlayerState, newState: Partial<PlayerState>) => {
      if (newState.myPlayerRevealCard || newState.playerRevealCards || newState.tourney) {
        return true;
      }

      if (compareEntity(currentState.gameRound, newState.gameRound)) {
        return true;
      }

      if (compareEntity(currentState.table, newState.table)) {
        return true;
      }

      return false;
    },
    [compareEntity]
  );

  const updateState = useCallback(
    (state: Partial<PlayerState>) => {
      setState((prev) => {
        if (!compareState(prev, state)) {
          return prev;
        }
        return {
          ...prev,
          ...state,
        };
      });
    },
    [setState, compareState]
  );

  const updateSettings = useCallback(
    (settings: Partial<PlayerSettings>) => {
      setSettings((prev) => {
        return {
          ...prev,
          ...settings,
        };
      });
    },
    [setSettings]
  );

  const clearState = useCallback(() => {
    setState((prev) => {
      return {
        ...defaultState,
        tourney: prev.tourney,
      } as PlayerState;
    });
  }, [setState]);

  const clearRoundStates = useCallback(() => {
    setState((prev) => {
      return {
        table: prev.table,
        tourney: prev.tourney,
      } as PlayerState;
    });
  }, [setState]);

  const addTourney = useCallback(
    (tourney: TournamentEvent) => {
      setState((prev) => {
        if (!prev.tourney) {
          prev.tourney = [];
        }
        const index = prev.tourney.findIndex((t) => t.id == tourney.id || t.type.id == tourney.type.id);
        if (index > -1) {
          prev.tourney.splice(index, 1);
        }
        prev.tourney.push(tourney);

        return {
          ...prev,
          tourney: [...prev.tourney],
        } as PlayerState;
      });
    },
    [setState]
  );

  const removeTourney = useCallback(
    (tourney: TournamentEvent) => {
      setState((prev) => {
        if (!prev.tourney) {
          prev.tourney = [];
        }

        const newState = {
          ...prev,
          tourney: prev.tourney.filter((t) => {
            return t.id.trim().toLocaleLowerCase() != tourney.id.trim().toLocaleLowerCase();
          }),
        } as PlayerState;

        return newState;
      });
    },
    [setState]
  );

  const reload = useCallback(() => {
    reloadPlayerSettings();
    reloadPlayerState();
  }, [reloadPlayerSettings, reloadPlayerState]);

  useEffect(() => {
    if (isTabActive) {
      reload();
    }
  }, [isTabActive, reload]);

  useEffect(() => {
    if (state.table && !called) {
      sendRequest({
        tableId: state.table.id,
      }).catch(() => {});
    }
  }, [sendRequest, state.table, called]);

  useEffect(() => {
    if (!called || used) {
      return;
    }

    setUsed(true);

    if (code == 404) {
      return clearState();
    }

    if (!data) {
      return;
    }

    updateState({
      table: data.table,
      gameRound: data.gameRound,
    });
  }, [data, code, updateState, clearState, called, used, player]);

  return (
    <PlayerContext.Provider
      value={{
        state,
        settings,
        updateState,
        clearRoundStates,
        clearState,
        updateSettings,
        addTourney,
        removeTourney,
        reload,
        syncTableRound: async () => {
          await sendRequest();
        },
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
}
