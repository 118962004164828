import React, { CSSProperties, ReactNode } from 'react';

type Style = CSSProperties | CSSProperties[];

interface TextProps {
  children: ReactNode;
  style?: Style;
  bold?: boolean;
  italic?: boolean;
}

const styles: { [key: string]: CSSProperties } = {
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
};

const Text: React.FC<TextProps> = ({ children, style = {}, bold = false, italic = false, ...props }) => {
  // Flatten style array if provided
  const flattenedStyle = Array.isArray(style) ? style.reduce((acc, curr) => ({ ...acc, ...curr }), {}) : style;

  const combinedStyles: CSSProperties = {
    ...styles.default,
    ...(bold ? styles.bold : {}),
    ...(italic ? styles.italic : {}),
    ...flattenedStyle,
  };

  return (
    <span style={combinedStyles} {...props}>
      {children}
    </span>
  );
};

export default Text;
