import { Box } from '@mui/joy';
import React, { useMemo } from 'react';
import { Pot } from '@ztp/shared';
import { motion } from 'framer-motion';
import PotChips from '../Chip/PotChips';

export interface PotsProps {
  pots?: Pot[];
  stakePot: number;
  distributePotIndex?: number;
  smallBlind: number;
}

const Pots: React.FC<PotsProps> = ({ pots, stakePot, smallBlind, distributePotIndex }) => {
  const actualPots = useMemo(() => {
    if (!pots || pots.length == 0) {
      return null;
    }
    return pots.filter((p) => p.eligiblePlayers.length > 1);
  }, [pots]);

  if (!actualPots) {
    if (stakePot > 0) {
      return (
        <Box sx={{ marginLeft: '40px' }}>
          <PotChips amount={stakePot} smallBlind={smallBlind} />
        </Box>
      );
    }
    return <></>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        {actualPots.map((pot, index) =>
          index === distributePotIndex ? (
            <Box key={index}>
              <motion.div initial={{ scale: 0.9 }} animate={{ scale: 1 }} transition={{ scale: { duration: 0.5 } }}>
                <PotChips amount={pot.amount} smallBlind={smallBlind} />
              </motion.div>
            </Box>
          ) : (
            index >= (distributePotIndex || -1) && (
              <Box key={index} sx={{ marginLeft: '40px' }}>
                <PotChips amount={pot.amount} smallBlind={smallBlind} />
              </Box>
            )
          )
        )}
      </Box>
    </Box>
  );
};

export default Pots;
