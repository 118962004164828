import { useMemo } from 'react';
import { useSmartAccounctContext } from '../../context/SmartAccounctContext';
import { useAccount } from 'wagmi';
import { Address } from 'viem';

export const useSmartAccount = () => {
  const { smartClient } = useSmartAccounctContext();
  const { isConnected: wagmiIsConnected, address: ownerAddress } = useAccount();
  const chainId = 42161;

  const address = useMemo(() => {
    if (!smartClient) {
      return '' as Address;
    }
    return smartClient?.account?.address || ('' as Address);
  }, [smartClient]);

  const isConnected = useMemo(() => {
    return wagmiIsConnected && !!smartClient;
  }, [wagmiIsConnected, smartClient]);

  return { address, ownerAddress, isConnected, chainId, wagmiIsConnected, smartClient };
};
