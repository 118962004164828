import { useCallback, useMemo } from 'react';
import { BackupKeyPayload, CardsDeck, KeyIndex, KeyPart, Player } from '@ztp/shared';
import { useAuthContext } from '../../context/AuthContext';
import { useP2PEventContext } from '../../context/P2PEventContext';
import useMental from '../cryptography/useMental';
import { usePlayerContext } from '../../context/PlayerContext';

interface KeyState {
  serverPublicKey: string;
  keys: KeyIndex[];
  roundId: string;
  minShares: number;
  totalShares: number;
}

interface KeyParts {
  parts: KeyPart[];
}

export const useBackupKeys = () => {
  const {
    state: { table },
  } = usePlayerContext();
  const { player } = useAuthContext();
  const { emit: emitP2P, ready: readyP2P } = useP2PEventContext();
  const { shareKey, calculateShares } = useMental();

  const myAdress = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const sharedParts = useCallback(
    async (state: KeyState) => {
      const keyParts: KeyParts[] = [];
      for (let x = 0; x < state.totalShares; x++) {
        keyParts.push({
          parts: [],
        });
      }

      for (const ki of state.keys) {
        const parts = await shareKey(ki.key.toString(), state.serverPublicKey, state.totalShares, state.minShares);

        for (let x = 0; x < parts.length; x++) {
          const part = parts[x];
          keyParts[x].parts.push({
            index: ki.index,
            part,
            minShares: state.minShares,
          });
        }
      }

      return keyParts;
    },
    [shareKey]
  );

  const availablePlayers = useCallback(() => {
    if (!table) {
      return;
    }

    const players = Object.values(table.players)
      .filter((p) => p.address != myAdress)
      .map((p) => p.player);

    return players;
  }, [table, myAdress]);

  const sendBackupP2P = useCallback(
    async (parts: KeyPart[], dstPlayer: Player, state: KeyState) => {
      if (!readyP2P || !emitP2P) {
        return;
      }

      emitP2P<BackupKeyPayload>('backup-key', dstPlayer.encryptionPublicKey, dstPlayer.id, {
        keyParts: parts,
        roundId: state.roundId,
      });
    },
    [readyP2P, emitP2P]
  );

  const backup = useCallback(
    async (keys: KeyIndex[], deck: CardsDeck) => {
      if (!table) {
        return;
      }

      const { totalShares, minShares } = calculateShares(Object.values(table.players).length);
      const state: KeyState = {
        serverPublicKey: deck.serverPublicKey,
        roundId: deck.roundId,
        keys,
        totalShares,
        minShares,
      };

      const parts = await sharedParts(state);
      const players = availablePlayers();
      if (!players || !parts) {
        return;
      }

      for (const player of players) {
        const index = players.indexOf(player);
        const part = parts[index];
        sendBackupP2P(part.parts, player, state);
      }
    },
    [calculateShares, sharedParts, availablePlayers, sendBackupP2P, table]
  );

  return { backup };
};
