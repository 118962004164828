import React, { CSSProperties, FC, useCallback, useContext, useState } from 'react';
import { Box, Modal, ModalClose, Typography, Divider } from '@mui/joy';
import palette from '../styles/theme';

interface IModalContext {
  showModal: (title: string, Component: FC<any>, props: Record<string, any>) => void;
  close: (e?: any, reason?: string) => void;
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 350,
    minHeight: 280,
    backgroundColor: palette.primary.solidBg,
    borderRadius: 30,
    border: 'solid',
    borderColor: palette.primary.solidBorder,
    borderWidth: 0.5,
    boxShadow: '24',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    p: 4,
    height: '100%',
  } as CSSProperties,
};

const defaultValue = {} as IModalContext;

const ModalContext = React.createContext<IModalContext>(defaultValue);
export const useModal = () => useContext(ModalContext);

export function ModalContextProvider({ children }: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [component, setComponent] = useState<FC<any>>();
  const [props, setProps] = useState<Record<string, any>>({});
  const [title, setTitle] = useState<string>('');

  const showModal = useCallback((title: string, Component: FC<any>, props: Record<string, any> = {}) => {
    setOpen(true);
    setComponent(() => Component);
    setProps(props);
    setTitle(title);
  }, []);

  const close = useCallback((e?: any, reason?: string) => {
    if (reason === 'backdropClick') {
      return;
    }

    setOpen(false);
    setComponent(undefined);
    setTitle('');
  }, []);

  return (
    <ModalContext.Provider value={{ showModal, close }}>
      <>
        <Modal open={open} onClose={close} hideBackdrop={false}>
          <>
            {component != undefined && (
              <Box sx={styles.container}>
                <Typography sx={{ color: palette.text.primary, alignSelf: 'center', margin: 2 }} level="body-lg">
                  {title}
                </Typography>
                <ModalClose color="neutral" variant="plain" />
                <Divider />
                <Box sx={styles.content}>{React.createElement(component, { ...props, onClose: close })}</Box>
              </Box>
            )}
          </>
        </Modal>
        {children}
      </>
    </ModalContext.Provider>
  );
}
