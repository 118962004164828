import { useCallback } from 'react';

export const useDateFormat = () => {
  const shortDateTime = useCallback((date: Date) => {
    if (!date) {
      return '';
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month} ${hours}:${minutes}`;
  }, []);

  return { shortDateTime };
};
