import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider, IconButton, Input, Typography } from '@mui/joy';
import { Connector, useAccount, useConnect } from 'wagmi';
import EmailIcon from '@mui/icons-material/Email';
import { useModal } from '../../context/ModalContext';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import metamaskIcon from '../../assets/metamask.svg';
import googleIcon from '../../assets/google.svg';
import discordIcon from '../../assets/color_discord.svg';
import faceboockIcon from '../../assets/facebook.svg';
import walletConnectIcon from '../../assets/wallet-connect.svg';
import coinBaseIcon from '../../assets/coin-base.svg';
import twitterIcon from '../../assets/color_twitter.svg';

interface index {
  [key: string]: any;
}

const conectorIcon: index = {
  coinbaseWalletSDK: coinBaseIcon,
  walletConnect: walletConnectIcon,
  metaMaskSDK: metamaskIcon,
};

const Connect: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const { connectors, connect } = useConnect();
  const { isConnected } = useAccount();
  const { close } = useModal();

  const filterConnectors = useMemo(() => {
    return connectors.filter((v, i) => {
      if (v.id == 'ztpoker') {
        return false;
      }
      if (v.id == 'metaMaskSDK' && connectors.some((v2, i2) => v2.id.indexOf('metamask') > 0 && i2 != i)) {
        return false;
      }
      return true;
    });
  }, [connectors]);

  const social = useMemo(() => {
    return [
      {
        provider: 'google',
        logo: googleIcon,
      },
      {
        provider: 'discord',
        logo: discordIcon,
      },
      {
        provider: 'facebook',
        logo: faceboockIcon,
      },
      {
        provider: 'twitter',
        logo: twitterIcon,
      },
    ];
  }, []);

  // Function to handle email login
  const handleEmailLogin = useCallback(async () => {
    try {
      connect({
        connector: connectors[0],
        chainId: {
          loginProvider: 'email_passwordless',
          extraLoginOptions: {
            login_hint: email, // email to send the OTP to
          },
        } as any,
      });
    } catch (err) {
      setError((err as Error).message);
      console.error(err);
    }
  }, [email, connect, connectors]);

  useEffect(() => {
    if (isConnected) {
      close();
    }
  }, [isConnected, close]);

  return (
    <Box
      flex={1}
      display={'flex'}
      flexDirection={'column'}
      sx={{
        maxWidth: 400,
        width: 300,
        mx: 'auto',
        textAlign: 'center',
      }}
    >
      <Box flex={1} display={'flex'} flexDirection={'column'}>
        <Box flex={1} display={'flex'} flexDirection={'column'}>
          <Input
            startDecorator={<EmailIcon />}
            endDecorator={
              <IconButton disabled={email.length < 5} onClick={() => handleEmailLogin()}>
                <ArrowForwardIosIcon sx={{ width: 16 }} />
              </IconButton>
            }
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleEmailLogin();
              }
            }}
            sx={{ mb: 2, width: '100%' }}
          />
          <Box
            flex={1}
            height={'100%'}
            display="flex"
            justifyContent={'center'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            marginBottom={'5px'}
          >
            {social.map((s) => (
              <IconButton
                sx={{
                  margin: 1,
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderRadius: 15,
                  width: 54,
                  height: 54,
                  //backgroundColor: '#E1E1E1',
                }}
                key={s.provider}
                onClick={() => {
                  connect({
                    connector: connectors[0],
                    chainId: {
                      loginProvider: s.provider,
                    } as any,
                  });
                }}
              >
                <img width={42} height={42} src={s.logo} />
              </IconButton>
            ))}
          </Box>
        </Box>
        <Divider>Or</Divider>
        <Box
          flex={1}
          height={'100%'}
          display="flex"
          justifyContent={'center'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          marginTop={'10px'}
        >
          {filterConnectors.map((connector: Connector) => (
            <IconButton
              sx={{
                margin: 1,
                borderWidth: '2px',
                borderStyle: 'solid',
                borderRadius: 15,
                width: 54,
                height: 54,
                //   backgroundColor: palette.primary.solidBorder,
              }}
              key={connector.uid}
              onClick={() => {
                if (connector.id == 'walletConnect') {
                  close();
                }
                connect({ connector });
              }}
            >
              <img width={42} height={42} src={connector.icon || conectorIcon[connector.id]} />
            </IconButton>
          ))}
        </Box>
      </Box>
      {error && (
        <Typography color="danger" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default Connect;
