import React, { useCallback, useEffect, useMemo } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import Level1 from '../../assets/cash_table_level1.png';
import Level2 from '../../assets/cash_table_level2.png';
import Level3 from '../../assets/cash_table_level3.png';
import Level4 from '../../assets/cash_table_level4.png';
import palette from '../../styles/theme';
import { TournamentEvent, TournamentTableType, TournamentTablesPayload } from '@ztp/shared';
import { useAuthContext } from '../../context/AuthContext';
import Button from '@mui/joy/Button';
import { Box, IconButton } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useWeb3Connect } from '../../hook/web3/useWeb3Connect';
import GroupsIcon from '@mui/icons-material/Groups';
import { useDateFormat } from '../../hook/helper/useDateFormat';
import { usePlayerContext } from '../../context/PlayerContext';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useLazyApi from '../../hook/api/useLazyApi';
import { eventCloseTime, eventOpenTime, eventStatTime, getNextEvent } from '@ztp/shared';
import { useCountdown } from '../../hook/helper/useCountdown';
import TableList from '../TableList/TableList';
import { useModal } from '../../context/ModalContext';

interface TableTypeCardProps {
  tableType: TournamentTableType;
  active: boolean;
  onSelect: (tourneyEvent: TournamentEvent) => void;
}

const levelImage = (level: number) => {
  switch (level) {
    case 1:
      return Level1;
    case 2:
      return Level2;
    case 3:
      return Level3;
    case 4:
      return Level4;
  }
};

export default function TourneyTableCard({ tableType, active, onSelect }: TableTypeCardProps) {
  const { t: tourneyT } = useTranslation('translation', { keyPrefix: 'tourney' });
  const { t: readyToOpenT } = useTranslation('translation', { keyPrefix: 'tourney.readyToOpen' });
  const { authenticated, player } = useAuthContext();
  const { showModal } = useModal();
  const { connect } = useWeb3Connect();
  const { state, addTourney } = usePlayerContext();
  const { shortDateTime } = useDateFormat();
  const { fetch, data } = useLazyApi<TournamentEvent, TournamentTablesPayload>('POST', 'tournament/get');

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const tournamentId = useMemo(() => tableType.currentTournament, [tableType]);
  useEffect(() => {
    if (!tournamentId) {
      return;
    }
    fetch({ tournamentId: tournamentId });

    const interval = setInterval(() => {
      fetch({ tournamentId: tournamentId });
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [fetch, tournamentId]);

  const nextEvent = useMemo(() => {
    return getNextEvent(tableType.events);
  }, [tableType]);

  const openTime = useMemo(() => {
    return eventOpenTime(nextEvent);
  }, [nextEvent]);
  const closeTime = useMemo(() => {
    return eventCloseTime(nextEvent);
  }, [nextEvent]);
  const startTime = useMemo(() => {
    return eventStatTime(nextEvent);
  }, [nextEvent]);

  const { timeLeftFormat: openLeftTime, done: openDone } = useCountdown(openTime);
  const { timeLeftFormat: startLeftTime, done: startDone } = useCountdown(startTime);
  const { timeLeftFormat: closeLeftTime, done: closeDone } = useCountdown(closeTime);
  const { tourney } = state;

  useEffect(() => {
    if (openDone || startDone || closeDone) {
      setTimeout(() => {
        if (tournamentId) {
          fetch({ tournamentId: tournamentId });
        }
      }, 10000);
    }
  }, [fetch, openDone, startDone, closeDone, tournamentId]);

  const tourneyEvent = useMemo(() => {
    let tournament = tourney?.find((t) => t.id == data?.id);
    if (!tournament) {
      tournament = data || undefined;
    } else {
      if (data && data?.version > tournament.version) {
        tournament = data;
      }
    }

    if (tournament?.state == 'ended' || tournament?.state == 'created') {
      return null;
    }

    return tournament;
  }, [data, tourney]);

  const full = useMemo(() => {
    return (
      tourneyEvent &&
      tourneyEvent.type.maxRegisteredPlayers == tourneyEvent.players.filter((p) => p.state != 'eliminated').length
    );
  }, [tourneyEvent]);

  useEffect(() => {
    const storeTorney = tourney?.find((t) => t.id == data?.id);
    const player = tourneyEvent?.players?.find((p) => p.address == address);
    if (player && !storeTorney && data) {
      addTourney(data);
    }
  }, [tourneyEvent, data, tourney, address, addTourney]);

  const playerState = useMemo(() => {
    const player = tourneyEvent?.players?.find((p) => p.address == address);
    if (!player) {
      return 'unregisterd';
    }

    return player.state;
  }, [tourneyEvent, address]);

  const walletConnected = useMemo(() => {
    return authenticated == 'authenticated';
  }, [authenticated]);

  const handleOpen = useCallback(() => {
    if (walletConnected) {
      if (!tourneyEvent) {
        return;
      }
      onSelect(tourneyEvent);
    } else {
      connect();
    }
  }, [walletConnected, tourneyEvent, connect, onSelect]);

  return (
    <Card
      variant="outlined"
      color="primary"
      sx={{ marginLeft: '10px', width: 250, height: 350, bgcolor: palette.primary.solidBg }}
    >
      <CardOverflow>
        <AspectRatio ratio="2">
          <img src={levelImage(tableType.level)} loading="lazy" alt="" />
        </AspectRatio>
        {!active && (
          <Box position={'absolute'} top={'40%'}>
            <Typography
              sx={{
                color: palette.text.secondary,
                background: palette.neutral.solidBg,
                textTransform: 'uppercase',
                transform: 'rotate(10deg) skew(10deg)',
              }}
              level="h2"
            >
              {tourneyT('notAvailable')}
            </Typography>
          </Box>
        )}
        {active && full && (
          <Box position={'absolute'} top={'40%'}>
            <Typography
              sx={{
                color: palette.text.secondary,
                background: palette.neutral.solidBg,
                textTransform: 'uppercase',
                transform: 'rotate(10deg) skew(10deg)',
              }}
              level="h2"
            >
              {tourneyT('full')}
            </Typography>
          </Box>
        )}
      </CardOverflow>
      <CardContent>
        <Typography sx={{ color: palette.text.primary }} level="body-lg">
          {tableType.name}
        </Typography>
        {active && (
          <>
            {!tourneyEvent ? (
              <>
                <Typography sx={{ color: palette.text.primary }} level="body-sm">
                  {`${tourneyT('open')}: ${shortDateTime(openTime)}  `}
                  <Typography sx={{ color: palette.text.secondary }} level="body-sm">{`${openLeftTime}`}</Typography>
                </Typography>
                <Typography sx={{ color: palette.text.primary }} level="body-sm">
                  {`${tourneyT('start')}: ${shortDateTime(startTime)}  `}
                  <Typography sx={{ color: palette.text.secondary }} level="body-sm">{`${startLeftTime}`}</Typography>
                </Typography>
              </>
            ) : tourneyEvent.state == 'enrollment' ? (
              <>
                <Typography sx={{ color: palette.text.primary }} level="body-sm">
                  {`${tourneyT('start')}: ${shortDateTime(startTime)}  `}
                  <Typography sx={{ color: palette.text.secondary }} level="body-sm">{`${startLeftTime}`}</Typography>
                </Typography>
                <Typography sx={{ color: palette.text.primary }} level="body-sm">
                  {`${tourneyT('close')}: ${shortDateTime(closeTime)}`}
                </Typography>
              </>
            ) : (
              tourneyEvent.state == 'started' && (
                <Typography sx={{ color: palette.text.primary }} level="body-sm">
                  {`${tourneyT('close')}: ${shortDateTime(closeTime)}  `}
                  <Typography sx={{ color: palette.text.secondary }} level="body-sm">{`${closeLeftTime}`}</Typography>
                </Typography>
              )
            )}
          </>
        )}
        {tourneyEvent?.prizePool ? (
          <Typography sx={{ color: palette.text.primary }} level="body-sm">
            {`Current Prize: $${tourneyEvent?.prizePool}`}
          </Typography>
        ) : (
          <Typography sx={{ color: palette.text.primary }} level="body-sm">
            {`Starting Prize: $${tableType.prize}`}
          </Typography>
        )}
        <Typography sx={{ color: palette.text.primary }} level="body-sm">
          {`${tourneyT('buyIn')}: ${tableType.buyIn > 0 ? `$${tableType.buyIn}` : tourneyT('free')}`}
        </Typography>
        {active && tourneyEvent && tourneyEvent.state != 'ended' && (
          <Button
            color={'primary'}
            sx={{ color: palette.text.primary, marginTop: 'auto', hover: { color: 'transparent' } }}
            onClick={handleOpen}
            variant="soft"
          >
            {readyToOpenT(String(walletConnected))}
          </Button>
        )}
      </CardContent>
      <CardOverflow variant="plain">
        <Divider inset="context" />
        <CardContent sx={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }} orientation="horizontal">
          <GroupsIcon sx={{ fontSize: 25, color: 'white' }} />
          <Typography level="body-sm">
            {tourneyEvent?.players?.filter((p) => p.state != 'eliminated')?.length || 0}
          </Typography>
          <Divider orientation="vertical" />
          <TableRestaurantIcon sx={{ fontSize: 22, color: 'white' }} />
          <Typography level="body-sm">{tourneyEvent?.numOfTables || 0}</Typography>
          {walletConnected && tourneyEvent && tourneyEvent.numOfTables > 0 && playerState != 'alocated' && (
            <IconButton
              variant="plain"
              sx={{
                '&:hover': {
                  backgroundColor: palette.primary.softHoverBg,
                },
                padding: 0,
                minHeight: '0px',
              }}
              onClick={() => showModal('Tournament Tables', TableList, { tournament: tourneyEvent })}
            >
              <VisibilityIcon sx={{ fontSize: 20, color: 'white' }} />
            </IconButton>
          )}
        </CardContent>
      </CardOverflow>
    </Card>
  );
}
