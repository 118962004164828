import { split } from 'shamir-secret-sharing';
import { MentalCryptoBase } from '@ztp/shared';

export class MenatalCrypto extends MentalCryptoBase {
  protected randomBigInt(sizeInBytes: number): bigint {
    const randomBytes = new Uint8Array(sizeInBytes);

    window.crypto.getRandomValues(randomBytes);
    const hexString = this.toHexString(randomBytes);
    const randomBigInt = this.hexStringToBigInt(hexString);

    return randomBigInt;
  }

  private toHexString(byteArray: Uint8Array) {
    return Array.from(byteArray, (byte) => {
      return ('0' + (byte & 0xff).toString(16)).slice(-2);
    }).join('');
  }

  private hexStringToBigInt(hexString: string) {
    return BigInt('0x' + hexString);
  }

  public async shareKey(key: string, total: number, min: number) {
    const parts = await split(this.toUint8Array(key), total, min);
    return parts.map<string>((p: Uint8Array) => this.uint8ArrayToBase64(p));
  }

  private toUint8Array(data: string) {
    return new TextEncoder().encode(data);
  }

  private uint8ArrayToBase64(byteArray: Uint8Array) {
    const charArray = Array.from(byteArray, (byte) => String.fromCharCode(byte));

    return btoa(charArray.join(''));
  }
}
