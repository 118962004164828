"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compress = compress;
exports.decompress = decompress;
const pako_1 = require("pako");
function compress(data) {
    if (data == undefined) {
        throw new Error('invalid data to compress');
    }
    const jsonString = JSON.stringify(data);
    const compressed = (0, pako_1.deflate)(jsonString);
    return Buffer.from(compressed).toString('base64');
}
function decompress(compressed) {
    if (!compressed) {
        throw new Error('invalid data to decompress');
    }
    const buffer = base64ToBuffer(compressed);
    const decompressed = (0, pako_1.inflate)(buffer, { to: 'string' });
    return JSON.parse(decompressed);
}
function base64ToBuffer(base64) {
    const buffer = Buffer.from(base64, 'base64');
    const arrayBuffer = new ArrayBuffer(buffer.length);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < buffer.length; i++) {
        view[i] = buffer[i];
    }
    return view;
}
