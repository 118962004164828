import { Box } from '@mui/joy';
import React, { useCallback } from 'react';
import { Card } from '@ztp/shared';
import { Styles } from '../../styles/type';
import Text from '../Text/Text';
import Suit from './Suit';
import { useCardText } from './useCardText';
import { useResponsive } from '../../hook/helper/useResponsive';

type CardSize = 'large' | 'small';

export interface CardProps {
  card: Card;
  size?: CardSize;
}

const CardView: React.FC<CardProps> = ({ card, size = 'large' }) => {
  const { isMobile } = useResponsive();
  const { splitCard, getRankSymbol } = useCardText();
  const { rank, suit } = splitCard(card);
  const rankSymbol = getRankSymbol(rank);

  const getColorStyle = useCallback(
    (suit: number) => {
      if (suit == 0 || suit == 3) {
        return styles(isMobile).black;
      }
      return styles(isMobile).red;
    },
    [isMobile]
  );

  let cardStyle = styles(isMobile).card;
  if (size == 'small') {
    cardStyle = {
      ...cardStyle,
      ...styles(isMobile).cardSm,
    };
  }

  return (
    <Box sx={[cardStyle, getColorStyle(suit)]}>
      <Text style={styles(isMobile).rank}>{rankSymbol}</Text>
      <Box sx={styles(isMobile).suitContainer}>
        <Box sx={[size == 'large' ? styles(isMobile).suit : styles(isMobile).suiteSm]}>
          <Suit suit={suit} />
        </Box>
      </Box>
      {size == 'large' && <Text style={[styles(isMobile).rank, styles(isMobile).bottomValue]}>{rankSymbol}</Text>}
    </Box>
  );
};

const styles = (isMobile: boolean): Styles => {
  return {
    card: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      borderColor: 'white',
      borderRadius: '10px',
      justifyContent: 'space-between',
      padding: '10px 10px 5px 10px',
    },
    rank: {
      fontSize: isMobile ? '50px' : '40px',
    },
    suitContainer: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      justifyItems: 'center',
    },
    suit: {
      width: isMobile ? '55px' : '45px',
      height: isMobile ? '55px' : '45px',
    },
    suiteSm: {
      width: isMobile ? '45px' : '35px',
      height: isMobile ? '45px' : '35px',
      marginLeft: '20px',
      marginTop: '-25px',
    },
    red: {
      color: 'red',
    },
    black: {
      color: 'black',
    },
    bottomValue: {
      alignSelf: 'flex-end',
      fontSize: isMobile ? '42px' : '30px',
      marginRight: '1px',
      marginTop: '-1px',
    },
    none: {
      opacity: 1,
    },
  };
};

export default CardView;
