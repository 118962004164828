import { useMemo } from 'react';
import { useNetworkConfig } from './useNetworkConfig';
import { ContractType } from './type';

export const useContractAddress = (contractType: ContractType) => {
  const { networkConfig } = useNetworkConfig();

  const contractAddress = useMemo(() => {
    switch (contractType) {
      case 'cash-game':
        return networkConfig.contracts.CashGameCashier.address || '0x24';
      case 'tournament':
        return networkConfig.contracts.TournamentCashier.address || '0x25';
      default:
        return '0x26';
    }
  }, [networkConfig, contractType]);

  return { contractAddress };
};
