import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import Level1 from '../../assets/cash_table_level1.png';
import Level2 from '../../assets/cash_table_level2.png';
import Level3 from '../../assets/cash_table_level3.png';
import Level4 from '../../assets/cash_table_level4.png';
import palette from '../../styles/theme';
import { CashTableType, TableType } from '@ztp/shared';
import { useAuthContext } from '../../context/AuthContext';
import Button from '@mui/joy/Button';
import { Box, IconButton } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useWeb3Connect } from '../../hook/web3/useWeb3Connect';
import GroupsIcon from '@mui/icons-material/Groups';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useModal } from '../../context/ModalContext';
import TableList from '../TableList/TableList';

interface TableTypeCardProps {
  tableType: CashTableType;
  active: boolean;
  onSelect: (tableType: TableType) => void;
}

const levelImage = (level: number) => {
  switch (level) {
    case 1:
      return Level1;
    case 2:
      return Level2;
    case 3:
      return Level3;
    case 4:
      return Level4;
  }
};

export default function CashTableCard({ tableType, active, onSelect }: TableTypeCardProps) {
  const { t: tableT } = useTranslation('translation', { keyPrefix: 'cash' });
  const { t: readyToPlayT } = useTranslation('translation', { keyPrefix: 'cash.readyToPlay' });
  const [playNowClicked, setPlayNowClicked] = useState<TableType>();
  const { authenticated } = useAuthContext();
  const { showModal } = useModal();
  const { connect } = useWeb3Connect();

  const readyToPlay = useMemo(() => {
    return authenticated == 'authenticated';
  }, [authenticated]);

  const handlePlayNow = useCallback(() => {
    if (readyToPlay) {
      onSelect(tableType);
    } else {
      setPlayNowClicked(tableType);
      connect();
    }
  }, [readyToPlay, tableType, connect, onSelect]);

  useEffect(() => {
    if (playNowClicked && readyToPlay) {
      onSelect(playNowClicked);
      setPlayNowClicked(undefined);
    }
  }, [readyToPlay, playNowClicked, onSelect]);

  return (
    <Card
      variant="outlined"
      color="primary"
      sx={{ marginLeft: '10px', width: 250, height: 350, bgcolor: palette.primary.solidBg }}
    >
      <CardOverflow>
        <AspectRatio ratio="2">
          <img src={levelImage(tableType.level)} loading="lazy" alt="" />
        </AspectRatio>
        {!active && (
          <Box position={'absolute'} top={'40%'}>
            <Typography
              sx={{
                color: palette.text.secondary,
                background: palette.neutral.solidBg,
                textTransform: 'uppercase',
                transform: 'rotate(10deg) skew(10deg)',
              }}
              level="h2"
            >
              {tableT('notAvailable')}
            </Typography>
          </Box>
        )}
      </CardOverflow>
      <CardContent>
        <Typography sx={{ color: palette.text.primary }} level="body-lg">
          {tableType.name}
        </Typography>
        <Typography sx={{ color: palette.text.primary }} level="body-sm">
          {`${tableT('stakes')}: $${tableType.small} / $${tableType.big}`}
        </Typography>
        <Typography sx={{ color: palette.text.primary }} level="body-sm">
          {`${tableT('buy-in')}: $${tableType.minBuyIn}`}
        </Typography>
        <Button
          disabled={!active}
          color={!active ? 'neutral' : 'primary'}
          sx={{ color: palette.text.primary, marginTop: 'auto', hover: { color: 'transparent' } }}
          onClick={handlePlayNow}
          variant="soft"
        >
          {readyToPlayT(String(readyToPlay))}
        </Button>
      </CardContent>
      <CardOverflow variant="plain">
        <Divider inset="context" />
        <CardContent sx={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }} orientation="horizontal">
          <GroupsIcon sx={{ fontSize: 25, color: 'white' }} />
          <Typography level="body-sm">{tableType.numOfPlayers || 0}</Typography>
          {/* <Typography level="body-sm">{tableT('num.of.players')}</Typography> */}
          <Divider orientation="vertical" />
          <TableRestaurantIcon sx={{ fontSize: 22, color: 'white' }} />
          <Typography level="body-sm">{tableType.numOfTables || 0}</Typography>
          {readyToPlay && tableType.numOfTables > 0 && (
            <IconButton onClick={() => showModal('Cash Tables', TableList, { typeId: tableType.id })}>
              <VisibilityIcon sx={{ fontSize: 20, color: 'white' }} />
            </IconButton>
          )}
        </CardContent>
      </CardOverflow>
    </Card>
  );
}
