import { useCallback } from 'react';
import { useAuthContext } from '../../context/AuthContext';

const useCryptoApi = () => {
  const { keyPairs } = useAuthContext();

  const bufferToBase64 = useCallback((buffer: ArrayBuffer): string => {
    const byteArray = new Uint8Array(buffer);
    const charArray = Array.from(byteArray, (byte) => String.fromCharCode(byte));

    return btoa(charArray.join(''));
  }, []);

  const base64ToBuffer = useCallback((base64: string): ArrayBuffer => {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
  }, []);

  const importKeyPair = useCallback(
    async (exportedKey: string, isPrivateKey: boolean, keyUsages: KeyUsage[], algorithm: string) => {
      const arrayBufferKey = base64ToBuffer(exportedKey);
      const format = isPrivateKey ? 'pkcs8' : 'spki';

      return await window.crypto.subtle.importKey(
        format,
        arrayBufferKey,
        {
          name: algorithm,
          hash: 'SHA-256',
        },
        true, // whether the key is extractable
        keyUsages
      );
    },
    [base64ToBuffer]
  );

  const decrypt = useCallback(
    async (encrypted: string) => {
      if (!keyPairs?.encryptionKeyPair?.privateKey) {
        return '';
      }

      const key = await importKeyPair(keyPairs.encryptionKeyPair.privateKey, true, ['decrypt'], 'RSA-OAEP');
      const encryptedBuffer = base64ToBuffer(encrypted);
      const decryptedBuffer = await window.crypto.subtle.decrypt(
        {
          name: 'RSA-OAEP',
        },
        key,
        encryptedBuffer
      );
      return new TextDecoder().decode(decryptedBuffer);
    },
    [keyPairs, base64ToBuffer, importKeyPair]
  );

  const exportAESKey = useCallback(
    async (key: CryptoKey) => {
      const exportedKey = await window.crypto.subtle.exportKey(
        'raw', // format
        key // the key you want to export
      );
      const buffer = new Uint8Array(exportedKey);
      return bufferToBase64(buffer);
    },
    [bufferToBase64]
  );

  const importAESKey = useCallback(
    async (base64Key: string) => {
      const buffer = base64ToBuffer(base64Key);

      return window.crypto.subtle.importKey(
        'raw', // format
        buffer, // the raw key
        {
          name: 'AES-GCM',
        },
        true, // whether the key is extractable
        ['encrypt', 'decrypt'] // what the key can be used for
      );
    },
    [base64ToBuffer]
  );

  const encrypt = useCallback(
    async (msg: string, publicKey: string) => {
      const pubKey = await importKeyPair(publicKey, false, ['encrypt'], 'RSA-OAEP');

      const encoded = new TextEncoder().encode(msg);

      const encryptedBuffer = await window.crypto.subtle.encrypt(
        {
          name: 'RSA-OAEP',
        },
        pubKey,
        encoded
      );
      return bufferToBase64(encryptedBuffer);
    },
    [bufferToBase64, importKeyPair]
  );

  const encryptMessage = useCallback(
    async (msg: string, publicKey: string) => {
      const aesKey = await window.crypto.subtle.generateKey(
        {
          name: 'AES-GCM',
          length: 256,
        },
        true,
        ['encrypt', 'decrypt']
      );

      // Encrypt the message with AES
      const encodedMessage = new TextEncoder().encode(msg);
      const iv = window.crypto.getRandomValues(new Uint8Array(12));
      const encryptedData = await window.crypto.subtle.encrypt(
        {
          name: 'AES-GCM',
          iv: iv,
        },
        aesKey,
        encodedMessage
      );

      const keyBase64 = await exportAESKey(aesKey);
      const enceyptedKey = await encrypt(keyBase64, publicKey);
      const ivBase64 = bufferToBase64(iv);
      const encryptedMessage = bufferToBase64(encryptedData);

      return { encryptedMessage, enceyptedKey, iv: ivBase64 };
    },
    [encrypt, exportAESKey, bufferToBase64]
  );

  const decryptMessage = useCallback(
    async (messageBase64: string, enceyptedKey: string, ivBase64: string) => {
      const base64Key = await decrypt(enceyptedKey);
      const aesKey = await importAESKey(base64Key);
      const encryptedMessage = base64ToBuffer(messageBase64);

      // Encrypt the message with AES
      // const encodedMessage = new TextEncoder().encode(msg);
      const iv = base64ToBuffer(ivBase64);
      const decryptedData = await window.crypto.subtle.decrypt(
        {
          name: 'AES-GCM',
          iv: iv,
        },
        aesKey,
        encryptedMessage
      );

      const decryptedMessage = new TextDecoder().decode(decryptedData);

      return { decryptedMessage };
    },
    [decrypt, importAESKey, base64ToBuffer]
  );

  const exportKeyPair = useCallback(
    async (key: CryptoKey, isPrivateKey: boolean) => {
      const format = isPrivateKey ? 'pkcs8' : 'spki';
      const exportedKey = await window.crypto.subtle.exportKey(format, key);
      const base64Key = bufferToBase64(exportedKey);
      return base64Key;
    },
    [bufferToBase64]
  );

  const encryptWithMyKey = useCallback(
    async (msg: string) => {
      if (!keyPairs?.encryptionKeyPair?.publicKey) {
        console.error('not find encryption key');
        return null;
      }

      return encrypt(msg, keyPairs.encryptionKeyPair.publicKey);
    },
    [keyPairs, encrypt]
  );

  const generateEncryptionKeyPair = useCallback(async () => {
    try {
      const keyPairs = await window.crypto.subtle.generateKey(
        {
          name: 'RSA-OAEP',
          modulusLength: 2048, // Can be 1024, 2048, or 4096
          publicExponent: new Uint8Array([1, 0, 1]),
          hash: 'SHA-256',
        },
        true, // Whether the key is extractable (i.e., can be used in exportKey)
        ['encrypt', 'decrypt'] // Use "sign" and "verify" for a signing key pair
      );
      const publicKey = await exportKeyPair(keyPairs.publicKey, false);
      const privateKey = await exportKeyPair(keyPairs.privateKey, true);

      return { publicKey, privateKey };
    } catch (error) {
      console.error('Error generating key pair:', error);
    }
  }, [exportKeyPair]);

  const generateSigningKeyPair = useCallback(async () => {
    try {
      const keyPairs = await window.crypto.subtle.generateKey(
        {
          name: 'RSA-PSS',
          modulusLength: 2048, // Can be 1024, 2048, or 4096
          publicExponent: new Uint8Array([1, 0, 1]),
          hash: 'SHA-256',
        },
        true, // Exportable
        ['sign', 'verify']
      );
      const publicKey = await exportKeyPair(keyPairs.publicKey, false);
      const privateKey = await exportKeyPair(keyPairs.privateKey, true);

      return { publicKey, privateKey };
    } catch (error) {
      console.error('Error generating key pair:', error);
    }
  }, [exportKeyPair]);

  const generateKeyPairs = useCallback(async () => {
    const encryptionKeyPair = await generateEncryptionKeyPair();
    const signingKeyPair = await generateSigningKeyPair();

    return { encryptionKeyPair, signingKeyPair };
  }, [generateEncryptionKeyPair, generateSigningKeyPair]);

  return {
    generateKeyPairs,
    bufferToBase64,
    base64ToBuffer,
    importKeyPair,
    encrypt,
    encryptWithMyKey,
    encryptMessage,
    decrypt,
    decryptMessage,
  };
};

export default useCryptoApi;
