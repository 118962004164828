import { useMemo } from 'react';
import { Table } from '@ztp/shared';
import { useAuthContext } from '../../context/AuthContext';
import { useTableCurrent } from './useTableCurrent';

export const useReadOnlyTableEvent = (table?: Table) => {
  const { player } = useAuthContext();

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const tableId = useMemo(() => {
    return table?.id;
  }, [table]);

  const readOnly = useMemo(() => {
    return table?.players[address] == undefined;
  }, [table, address]);

  useTableCurrent(readOnly, tableId);

  return { readOnly };
};
