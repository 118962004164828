import React, { useCallback, useEffect, useState } from 'react';
import useInstallPWA from './usePwaInstall';
import { useResponsive } from '../../hook/helper/useResponsive';
import usePersistState from '../../hook/state/usePersistState';
import { useModal } from '../../context/ModalContext';
import PwaDialog from '../../components/PWA/PwaDialog';
import usePwaInstalled from './usePwaInstalled';

const usePwaDialog = () => {
  const { canInstall, install } = useInstallPWA();
  const { isInstalled } = usePwaInstalled();
  const { isIOS } = useResponsive();
  const { showModal, close } = useModal();
  const { state: hide, setState: updateHide } = usePersistState<boolean>('pwa-hide', false);
  const [isTriggered, setIsTriggered] = useState(false);

  useEffect(() => {
    if (hide || isTriggered || isInstalled) {
      return;
    }

    if (!isIOS && !canInstall) {
      return;
    }

    setIsTriggered(true);
    showModal('Install', PwaDialog, { close, install, updateHide });
  }, [hide, canInstall, isIOS, isTriggered, isInstalled, showModal, updateHide, install, close]);
};

export default usePwaDialog;
