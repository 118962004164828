import React, { useMemo } from 'react';
import { Box, IconButton, Modal, Stack } from '@mui/joy';
import PlayerAvatar from '../PlayerAvatar/PlayerAvatar';
import palette from '../../styles/theme';
import { SxProps } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

interface AvatarSelectorProps {
  onSelect: (avatarId: number) => void;
}

const AvatarSelector: React.FC<AvatarSelectorProps> = ({ onSelect }) => {
  const avatarIds = useMemo(() => {
    const result: number[] = [];
    for (let x = 1; x <= 61; x++) {
      result.push(x);
    }
    return result;
  }, []);

  return (
    <Modal open={true} onClose={() => onSelect(-1)}>
      <Box sx={styles.container}>
        <IconButton
          sx={{ alignSelf: 'flex-end' }}
          color="primary"
          size="lg"
          variant="plain"
          onClick={() => onSelect(-1)}
        >
          <ArrowRightAltIcon sx={{ color: 'white', fontSize: '35px' }} />
        </IconButton>
        <Stack flexWrap="wrap" direction={'row'}>
          {avatarIds.map((avatarId) => (
            <Box key={avatarId} sx={{ marginTop: '5px' }}>
              <IconButton
                onClick={() => onSelect(avatarId)}
                key={avatarId}
                variant="plain"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Box width={'72px'}>
                  <PlayerAvatar avatarId={avatarId} />
                </Box>
              </IconButton>
            </Box>
          ))}
        </Stack>
      </Box>
    </Modal>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    padding: '15px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 440,
    height: 400,
    backgroundColor: palette.primary.solidBg,
    borderRadius: 30,
    border: 'solid',
    borderColor: palette.primary.solidBorder,
    borderWidth: 0.5,
    boxShadow: '24',
    overflow: 'auto',
  } as SxProps,
};

export default AvatarSelector;
