import { Box, IconButton, Typography } from '@mui/joy';
import React, { useCallback, useMemo } from 'react';
import { useSnackbar } from '../../context/SnackbarContext';
import { useAuthContext } from '../../context/AuthContext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { QRCode } from 'react-qrcode-logo';
import { CoinIcon } from './CoinIcon';
import { useChainId } from 'wagmi';
import { useTokenBalance } from '../../hook/web3/useTokenBalance';
import { NetworkIcon } from './NetworkIcon';
import palette from '../../styles/theme';

const Recive: React.FC = () => {
  const { player } = useAuthContext();
  const { showMessage } = useSnackbar();
  const chainId = useChainId();
  const { token } = useTokenBalance();

  const address = useMemo(() => {
    return player?.smartAccount || player?.id || '';
  }, [player]);

  const copyAddressToClipboard = useCallback(() => {
    navigator.clipboard.writeText(address).then(
      () => {
        showMessage('Address copied to clipboard', 'success');
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  }, [address, showMessage]);

  return (
    <Box
      height={'420px'}
      width={'300px'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'space-around'}
    >
      <Typography sx={{ color: palette.text.secondary, textAlign: 'center' }} level="body-md">
        * Make sure you are sending USDT tokens on the Arbitrum chain!
      </Typography>

      <QRCode eyeRadius={25} qrStyle="fluid" size={160} value={address} />
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-around'}
        alignContent={'space-around'}
        width={'100px'}
      >
        {token && <CoinIcon name={token.symbol} />}
        {chainId && <NetworkIcon chainId={chainId} />}
      </Box>
      <Typography sx={{ textWrap: 'wrap', wordBreak: 'break-word' }} level="body-sm">
        {address}
      </Typography>
      <IconButton
        onClick={copyAddressToClipboard}
        variant="plain"
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          fontSize: '13px',
        }}
      >
        <ContentCopyIcon sx={{ color: 'white' }} />
      </IconButton>
    </Box>
  );
};

export default Recive;
