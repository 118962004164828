import React, { useEffect, useMemo } from 'react';
import { usePlayerContext } from '../../context/PlayerContext';
import Loading from '../Loading/Loading';
import PlayerActionsView from '../PlayerAction/PlayerActionView';
import { Styles } from '../../styles/type';
import { Box } from '@mui/joy';
import TableView from '../Table/TableView';
import { RoundStage, TableState } from '@ztp/shared';
import { useAuthContext } from '../../context/AuthContext';
import SoundButton from '../Sound/SoundButton';
import { useReadOnlyTableEvent } from '../../hook/events/useReadOnlyTableEvent';
import { useModal } from '../../context/ModalContext';
import { TourneyBreakTime } from '../Tournament/TourneyBreakTime';

const TourneyTableMobile: React.FC = () => {
  const { state } = usePlayerContext();
  const { table, tourney, gameRound } = state;
  const { readOnly } = useReadOnlyTableEvent(table);
  const { showModal } = useModal();
  const { player } = useAuthContext();

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const currenttournament = useMemo(() => {
    return tourney?.find((t) => t.id == table?.tournamentId);
  }, [tourney, table]);

  const round = useMemo(() => {
    if (table?.tableState != TableState.InProgress) {
      return undefined;
    }
    return gameRound;
  }, [gameRound, table]);

  const roundEnded = !round?.roundStage || round.roundStage >= RoundStage.Presentation;
  const myTurn = round?.currentPlayerTurn == address;

  useEffect(() => {
    if (currenttournament?.state == 'break' && table?.tableState == TableState.Break) {
      showModal('Break Time', TourneyBreakTime, { tournament: currenttournament });
    }
  }, [showModal, currenttournament, gameRound, table]);

  if (!table) {
    return <Loading size={120} />;
  }

  return (
    <Box style={styles.contianer}>
      <Box position={'absolute'} alignSelf={'flex-end'}>
        <SoundButton hideVol={true} />
      </Box>
      <TableView table={table} round={round} tournament={currenttournament} />

      <Box display="flex" flexDirection="row" marginLeft="4px" marginRight={'4px'}>
        <Box style={styles.actionContainer}>
          {!readOnly && <PlayerActionsView myTurn={myTurn} roundEnded={roundEnded} />}
        </Box>
      </Box>
    </Box>
  );
};

const styles: Styles = {
  contianer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'center',
    height: '100%',
    margin: 0,
    padding: 0,
    overflow: 'hidden' /* Prevents scrolling */,
    width: '100%',
    flexDirection: 'column',
  },
  actionContainer: {
    alignItems: 'flex-end',
    flex: 1,
    display: 'flex',
    marginBottom: '20px',
    height: '36px',
  },
};

export default TourneyTableMobile;
