import React, { useCallback, useState } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/joy';
import palette from '../../styles/theme';
import { SxProps } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTranslation } from 'react-i18next';

interface LeaveButtonProps {
  onClick: () => void;
  rotate: boolean;
}

const LeaveButton: React.FC<LeaveButtonProps> = ({ onClick, rotate }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'leave' });
  const [open, setOpen] = useState(false);

  const hadleClose = useCallback(
    (leave: boolean = false) => {
      setOpen(false);
      if (leave) {
        onClick();
      }
    },
    [onClick]
  );

  return (
    <>
      <Modal open={open} onClose={() => hadleClose(false)}>
        <Box sx={styles.container}>
          <Box sx={styles.content}>
            <Typography id="nested-modal-title" level="body-md">
              {t('confirmation')}
            </Typography>
            <Box
              sx={{
                mt: 1,
                display: 'flex',
                gap: 1,
                marginTop: 'auto',
                flexDirection: { xs: 'column', sm: 'row-reverse' },
              }}
            >
              <Button color="primary" fullWidth sx={styles.button} variant="soft" onClick={() => hadleClose(true)}>
                {t('continue')}
              </Button>
              <Button
                color="neutral"
                fullWidth
                sx={{ ...styles.button, color: 'blac' }}
                variant="soft"
                onClick={() => hadleClose(false)}
              >
                {t('cancel')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box display="flex" alignItems="center">
        <IconButton
          color="primary"
          sx={{
            paddingInline: '0',
            '--IconButton-size': '54px',
            maxHeight: '42px',
            maxWidth: '42px',
            transform: rotate ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
          onClick={() => setOpen(true)}
        >
          <ExitToAppIcon sx={{ color: 'white' }} />
        </IconButton>
      </Box>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    height: 180,
    backgroundColor: palette.primary.solidBg,
    borderRadius: 30,
    border: 'solid',
    borderColor: palette.primary.solidBorder,
    borderWidth: 0.5,
    boxShadow: '24',
  } as SxProps,
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    p: 4,
  } as SxProps,
  button: {
    color: palette.text.primary,
    hover: { color: 'transparent' },
  } as SxProps,
};

export default LeaveButton;
