import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useAccount, useWalletClient } from 'wagmi';
import { WalletClientSigner, UserOperationContext } from '@aa-sdk/core';
import { alchemy, AlchemySmartAccountClient, arbitrum, createAlchemySmartAccountClient } from '@account-kit/infra';
import { createLightAccount, LightAccount } from '@account-kit/smart-contracts';
import { Chain } from 'viem';

const apiKey = 'VBOlYs_378Bjw2uCEAt6uAUs_PNR5Jx-';
const policyId = '1db266e0-7a43-4aef-ab67-aed55d8a83ff';
const alchemyTransport = alchemy({ apiKey: apiKey });

interface ISmartAccounctContext {
  smartClient:
    | AlchemySmartAccountClient<
        Chain,
        LightAccount<WalletClientSigner, 'v2.0.0'>,
        Record<string, never>,
        UserOperationContext | undefined
      >
    | undefined;
}
const defaultValue = {} as ISmartAccounctContext;

const SmartAccounctContext = React.createContext<ISmartAccounctContext>(defaultValue);
export const useSmartAccounctContext = () => useContext(SmartAccounctContext);

export function SmartAccounctContextProvider({ children }: any) {
  const { isConnected } = useAccount();
  const { data: walletClient } = useWalletClient();
  const [ownerAddress, setOwnerAddress] = useState('');
  const [smartClient, setSmartClient] =
    useState<
      AlchemySmartAccountClient<
        Chain,
        LightAccount<WalletClientSigner, 'v2.0.0'>,
        Record<string, never>,
        UserOperationContext | undefined
      >
    >();

  useEffect(() => {
    if (!walletClient) {
      return;
    }

    if (walletClient.account.address != ownerAddress) {
      setSmartClient(undefined);
    }
  }, [ownerAddress, walletClient]);

  const init = useCallback(async (walletClient: any) => {
    const signer = new WalletClientSigner(walletClient, 'wallet');

    const a = await createLightAccount({
      chain: arbitrum,
      transport: alchemyTransport,
      signer: signer,
    });
    const client = createAlchemySmartAccountClient({
      transport: alchemyTransport,
      policyId: policyId,
      chain: arbitrum,
      account: a,
    });
    setSmartClient(client);
    setOwnerAddress(walletClient.account.address);
  }, []);

  useEffect(() => {
    if (!walletClient || !isConnected) {
      return;
    }

    if (!smartClient) {
      init(walletClient);
    }
  }, [walletClient, isConnected, smartClient, init]);

  return <SmartAccounctContext.Provider value={{ smartClient }}>{children}</SmartAccounctContext.Provider>;
}
