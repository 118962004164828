import { useCallback, useEffect, useState } from 'react';

export default function usePersistState<T>(key: string, defualtState: T) {
  const [state, setState] = useState<T>(() => {
    const persistedState = localStorage.getItem(key);

    let iniState = defualtState;
    if (persistedState !== null && persistedState !== undefined) {
      try {
        iniState = JSON.parse(persistedState) as T;
      } catch (e) {
        console.error('Error parsing JSON from localStorage:', e);
      }
    }
    return iniState;
  });

  useEffect(() => {
    if (state !== undefined) {
      localStorage.setItem(key, JSON.stringify(state));
    }
  }, [state, key]);

  const reload = useCallback(() => {
    const persistedState = localStorage.getItem(key);
    if (persistedState !== null && persistedState !== undefined) {
      try {
        setState(JSON.parse(persistedState) as T);
      } catch (e) {
        console.error('Error parsing JSON from localStorage:', e);
      }
    }
  }, [key]);

  return { state, setState, reload };
}
