import React, { FC } from 'react';
import { SliderProps } from './Slider';
import { Swiper } from 'swiper/react';
import { Pagination, EffectCards } from 'swiper/modules';
import styles from '../../styles/swiper.module.css';

const SliderMobile: FC<SliderProps> = ({ children }) => {
  return (
    <Swiper
      width={330}
      effect={'cards'}
      grabCursor={true}
      loop={true}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, EffectCards]}
      className={styles.mobile}
    >
      {children}
    </Swiper>
  );
};

export default SliderMobile;
