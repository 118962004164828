import React, { useEffect, useState } from 'react';

interface PlayerAvatarProps {
  avatarId: number;
}

const PlayerAvatar: React.FC<PlayerAvatarProps> = ({ avatarId }) => {
  const [imageSrc, setImageSrc] = useState('');

  const loadImage = (avatrId: number) => {
    const imagePath = `${process.env.PUBLIC_URL}/assets/avatars/avatar${avatrId}.png`;
    setImageSrc(imagePath);
  };

  useEffect(() => {
    loadImage(avatarId);
  }, [avatarId]);

  return <img src={imageSrc} style={{ borderRadius: '50%', width: '100%', objectFit: 'cover' }} />;
};

export default PlayerAvatar;
