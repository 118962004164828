import { useEffect, useMemo } from 'react';
import { useWsContext } from '../../context/WebSocketContext';
import { useBackupKeys } from '../events/useBackupKey';
import {
  Card,
  DealPlayerCardsKeysEvent,
  RevealKeyCommunityCardsRequest,
  PlayerRevealHisCardsPayload,
  EncryptDeckEvent,
  CardsDeck,
  KeyIndex,
  CardKeys,
} from '@ztp/shared';
import { useGameValidation } from '../games/useGameValidation';
import { useP2PEventContext } from '../../context/P2PEventContext';
import { usePlayerContext } from '../../context/PlayerContext';
import useMental from '../cryptography/useMental';
import useGameRules from '../games/useGameRules';
import useRevealMyCards from './useRevealMyCards';
import { useAuthContext } from '../../context/AuthContext';
import { useBackupCards } from './useBackupCards';

export const useDealerEventHandler = () => {
  const { backup } = useBackupKeys();
  const { backupCards } = useBackupCards();
  const { player } = useAuthContext();
  const { reveal } = useRevealMyCards();
  const { subscribe, emit } = useWsContext();
  const { encryptDeck, decryptCard, shuffle, revealKey, replaceKey, revealCards, encrypt } = useMental();
  const { nextCommunityCardsIndexes } = useGameRules();
  const { validateRevealCards, validatePlayerRevealHisCardEvent } = useGameValidation();
  const { subscribe: subscribeP2P } = useP2PEventContext();
  const {
    updateState,
    syncTableRound,
    state: { gameRound, playerRevealCards, table },
  } = usePlayerContext();
  const gameType = useMemo(() => table?.type.gameType, [table]);

  const myAddress = useMemo(() => {
    return player?.id || '';
  }, [player]);

  // ******  shuffle-deck  *******

  useEffect(() => {
    if (!subscribe || !emit) {
      return;
    }

    const unsubscribe = subscribe<CardsDeck>('shuffle-deck-request', async (payload: CardsDeck) => {
      const shuffledDeck = shuffle(payload.cards);
      payload.cards = shuffledDeck;
      emit('shuffle-deck-response', 'dealer', true, payload);
    });

    return () => unsubscribe();
  }, [subscribe, emit, shuffle]);

  // ******  encrypt-deck *******

  useEffect(() => {
    if (!subscribe || !emit || !gameType) {
      return;
    }

    const unsubscribe = subscribe('encrypt-deck-request', async (payload: EncryptDeckEvent) => {
      const { players, deck } = payload;
      const { reEncryptedCards, backupKeys, keys } = await encryptDeck(gameType, deck.cards, deck.numOfPlayers);
      deck.cards = reEncryptedCards;
      backup(backupKeys, deck);

      //deal keys for players
      for (const player of players) {
        for (const cardIndex of deck.playerCardsIndexes[player.address]) {
          const key = await encrypt(keys[cardIndex], player.player.encryptionPublicKey);

          if (!deck.playerEncryptedKeys[player.address][cardIndex]) {
            deck.playerEncryptedKeys[player.address][cardIndex] = [];
          }
          deck.playerEncryptedKeys[player.address][cardIndex].push(key);
        }
      }

      emit('encrypt-deck-response', 'dealer', true, deck);
    });

    return () => unsubscribe();
  }, [subscribe, emit, backup, replaceKey, encrypt, encryptDeck, gameType]);

  // ******  handle-player-reveal-his-cards-p2p *******

  useEffect(() => {
    if (!subscribeP2P || !gameRound) {
      return;
    }

    const unsubscribe = subscribeP2P<PlayerRevealHisCardsPayload>('player-reveal-his-cards', async (_, message) => {
      const playerAddress = message.from;
      console.debug(`recieved player reveal his cards event from ${playerAddress}`);

      const { cardsKeys, roundId } = message.payload;

      if (gameRound.id != roundId) {
        return console.error('player-reveal-his-cards round id is not equal');
      }

      try {
        const cards: Card[] = cardsKeys.map((ck) => {
          let card = gameRound.deck.cards[ck.index];
          for (const key of ck.keys) {
            card = decryptCard(card, [key]);
          }
          return card;
        });

        if (!validateRevealCards(cards)) {
          return console.error('validateRevealCards validation failed');
        }

        const updatedPlayerRevealCards = playerRevealCards || {};
        updatedPlayerRevealCards[message.from] = cards;

        updateState({
          playerRevealCards: {
            ...updatedPlayerRevealCards,
          },
        });
      } catch (error) {
        console.error(`failed to handle player reveal his crads event with error: ${error}`);
      }
    });

    return () => unsubscribe();
  }, [
    gameRound,
    playerRevealCards,
    subscribeP2P,
    decryptCard,
    syncTableRound,
    validatePlayerRevealHisCardEvent,
    validateRevealCards,
    updateState,
  ]);

  // ******  deal-player-cards-keys  ******

  useEffect(() => {
    if (!subscribe || !emit || !gameType) {
      return;
    }

    const unsubscribe = subscribe<DealPlayerCardsKeysEvent>(
      'deal-player-cards-request',
      async (payload: DealPlayerCardsKeysEvent) => {
        const { deck } = payload;
        let response = false;
        try {
          const cardKeys: CardKeys[] = deck.playerCardsIndexes[myAddress].map((index) => {
            return {
              index,
              keys: deck.playerEncryptedKeys[myAddress][index],
            };
          });

          const cards = await revealCards(cardKeys, deck);
          if (!cards || !validateRevealCards(cards.map((c) => c.card))) {
            console.error(`misdeal falid to reveal cards: ${JSON.stringify(cards)}`);
          } else {
            backupCards(deck, cards);
            response = true;
          }
        } catch (error) {
          console.error(error);
        }

        emit('deal-player-cards-response', 'dealer', false, response);
      }
    );

    return () => unsubscribe();
  }, [subscribe, emit, revealCards, backupCards, validateRevealCards, gameType, myAddress]);

  // ******  reveal-key-community-cards ******

  useEffect(() => {
    if (!subscribe || !emit || !gameRound || !gameType) {
      return;
    }

    const unsubscribe = subscribe(
      'reveal-key-community-cards-request',
      async (payload: RevealKeyCommunityCardsRequest) => {
        const { stage } = payload;
        const cardsIndexes = nextCommunityCardsIndexes(stage, gameRound.players.length, gameType);

        const keys = await Promise.all(
          cardsIndexes.map(async (index) => {
            return {
              index,
              key: await revealKey(index),
            } as KeyIndex;
          })
        );

        emit('reveal-key-community-cards-response', 'dealer', true, { keys, succuss: true });
      }
    );

    return () => unsubscribe();
  }, [gameRound, gameType, subscribe, emit, revealKey, nextCommunityCardsIndexes]);

  // ******  player-time-to-reveal-cards ******

  useEffect(() => {
    if (!subscribe || !emit) {
      return;
    }

    const unsubscribe = subscribe('player-time-to-reveal-cards-request', async () => {
      const myCards = await reveal();
      emit('player-time-to-reveal-cards-response', 'dealer', false, myCards);
    });

    return () => unsubscribe();
  }, [subscribe, emit, reveal]);

  // ******  encryptor-race ******

  useEffect(() => {
    if (!subscribe || !emit) {
      return;
    }

    const unsubscribe = subscribe('encryptor-race-request', async () => {
      emit('encryptor-race-response', 'dealer', false, true);
    });

    return () => unsubscribe();
  }, [subscribe, emit]);
};
