import { useBalance } from 'wagmi';
import { ethers } from 'ethers';
import { useWagmiContext } from '../../context/WagmiContext';
import { useEffect, useMemo } from 'react';
import { useSmartAccount } from './useSmartAccount';

export const useTokenBalance = () => {
  const { address } = useSmartAccount();
  const { token } = useWagmiContext();

  const { data, refetch } = useBalance({
    address: address,
    token: token?.address,
  });

  const { data: gasData } = useBalance({
    address: address,
  });

  const gas = useMemo(() => {
    return gasData?.value ? parseFloat(ethers.formatUnits(gasData.value, gasData.decimals)) : 0;
  }, [gasData]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 1000 * 30);

    return () => clearInterval(interval);
  }, [refetch]);

  const value = data?.value ? parseFloat(ethers.formatUnits(data.value, data.decimals)) : 0;
  const formatted = value > 0 ? value.toFixed(2).toString() : '0.00';

  return { value, formatted, token, gas };
};
