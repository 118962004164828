"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeekDay = exports.PokerActionType = exports.TableState = exports.RoundStage = exports.HandName = void 0;
var HandName;
(function (HandName) {
    HandName[HandName["StraightFlush"] = 0] = "StraightFlush";
    HandName[HandName["FourOfKind"] = 1] = "FourOfKind";
    HandName[HandName["FullHouse"] = 2] = "FullHouse";
    HandName[HandName["Flush"] = 3] = "Flush";
    HandName[HandName["Straight"] = 4] = "Straight";
    HandName[HandName["ThreeOfKind"] = 5] = "ThreeOfKind";
    HandName[HandName["TwoPair"] = 6] = "TwoPair";
    HandName[HandName["OnePair"] = 7] = "OnePair";
    HandName[HandName["HighCard"] = 8] = "HighCard";
})(HandName || (exports.HandName = HandName = {}));
var RoundStage;
(function (RoundStage) {
    RoundStage[RoundStage["DealCards"] = 0] = "DealCards";
    RoundStage[RoundStage["PreFlop"] = 1] = "PreFlop";
    RoundStage[RoundStage["Flop"] = 2] = "Flop";
    RoundStage[RoundStage["Turn"] = 3] = "Turn";
    RoundStage[RoundStage["River"] = 4] = "River";
    RoundStage[RoundStage["Showdown"] = 5] = "Showdown";
    RoundStage[RoundStage["Presentation"] = 6] = "Presentation";
    RoundStage[RoundStage["Finish"] = 7] = "Finish";
})(RoundStage || (exports.RoundStage = RoundStage = {}));
var TableState;
(function (TableState) {
    TableState["WaitingForPlayers"] = "WaitingForPlayers";
    TableState["InProgress"] = "InProgress";
    TableState["Break"] = "Break";
})(TableState || (exports.TableState = TableState = {}));
var PokerActionType;
(function (PokerActionType) {
    PokerActionType["Blind"] = "blind";
    PokerActionType["Call"] = "call";
    PokerActionType["Raise"] = "raise";
    PokerActionType["Fold"] = "fold";
    PokerActionType["Check"] = "check";
    PokerActionType["CheckOrFold"] = "check-fold";
    PokerActionType["AllIn"] = "all-in";
})(PokerActionType || (exports.PokerActionType = PokerActionType = {}));
var WeekDay;
(function (WeekDay) {
    WeekDay[WeekDay["Sunday"] = 0] = "Sunday";
    WeekDay[WeekDay["Monday"] = 1] = "Monday";
    WeekDay[WeekDay["Tuesday"] = 2] = "Tuesday";
    WeekDay[WeekDay["Wednesday"] = 3] = "Wednesday";
    WeekDay[WeekDay["Thursday"] = 4] = "Thursday";
    WeekDay[WeekDay["Friday"] = 5] = "Friday";
    WeekDay[WeekDay["Saturday"] = 6] = "Saturday";
})(WeekDay || (exports.WeekDay = WeekDay = {}));
