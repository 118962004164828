import React, { useCallback, useMemo } from 'react';
import { Card, CardContent, Grid, Typography, TypographySystem } from '@mui/joy';
import { useAuthContext } from '../../context/AuthContext';
import { useCountdown } from '../../hook/helper/useCountdown';
import { TournamentEvent, TournamentState } from '@ztp/shared';
import { useTranslation } from 'react-i18next';

type ContianerType = 'general' | 'transperent';

interface TourneyStatusProps {
  tournament?: TournamentEvent;
  level?: keyof TypographySystem;
  fontSize?: string;
  color: string;
  contianerType: ContianerType;
  hideTimer?: boolean;
}

interface NextStateAndTimeLeft {
  state: TournamentState;
  timeLeftFormat: string;
}

export const TourneyStatus: React.FC<TourneyStatusProps> = ({
  tournament,
  level,
  color,
  contianerType,
  fontSize,
  hideTimer,
}) => {
  const { player } = useAuthContext();
  const { t: statusT } = useTranslation('translation', { keyPrefix: 'tourney.status' });
  const { t: stateT } = useTranslation('translation', { keyPrefix: 'tourney.state' });
  const { t: nextStateT } = useTranslation('translation', { keyPrefix: 'tourney.next.state' });

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const blindTime = useMemo(() => {
    if (!tournament) {
      return null;
    }
    return new Date(tournament.nextBlind);
  }, [tournament]);

  const breakTime = useMemo(() => {
    if (!tournament) {
      return null;
    }
    return new Date(tournament.nextBreak);
  }, [tournament]);

  const backFromBreakTime = useMemo(() => {
    if (!tournament?.backFromBreak) {
      return null;
    }
    return new Date(tournament.backFromBreak);
  }, [tournament]);

  const knockoutTime = useMemo(() => {
    if (!tournament?.closeTime) {
      return null;
    }
    return new Date(tournament.closeTime);
  }, [tournament]);

  const { timeLeftFormat: blindLeftFormat } = useCountdown(blindTime);
  const { timeLeftFormat: breakLeftFormat, timeLeft: breakLeft } = useCountdown(breakTime);
  const { timeLeftFormat: backLeftFormat } = useCountdown(backFromBreakTime);
  const { timeLeftFormat: knockoutLeftFormat, timeLeft: knockoutLeft } = useCountdown(knockoutTime);

  const nextState = useMemo<NextStateAndTimeLeft>(() => {
    if (tournament?.state == 'break') {
      return { state: tournament.stateBeforeBreak!, timeLeftFormat: backLeftFormat };
    }

    if (knockoutLeft > 0 && knockoutLeft < breakLeft) {
      return { state: 'knockout', timeLeftFormat: knockoutLeftFormat };
    }

    return { state: 'break', timeLeftFormat: breakLeftFormat };
  }, [tournament, backLeftFormat, breakLeftFormat, knockoutLeftFormat, breakLeft, knockoutLeft]);

  const myPlayer = useMemo(() => {
    return tournament?.players.find((p) => p.address == address);
  }, [tournament, address]);

  const remainingPlayers = useMemo(() => {
    return tournament?.players.filter((p) => p.state != 'eliminated').length || 0;
  }, [tournament]);

  const renderStatusGrid = useCallback(() => {
    if (!tournament) {
      return <></>;
    }

    return (
      <Grid width={'100%'} container spacing={0.2}>
        <Grid xs={4}>
          <Typography sx={{ color: color, fontSize: fontSize }} level={level}>
            {stateT(tournament.state)}
          </Typography>
        </Grid>
        <Grid xs={8}>
          <Typography sx={{ color: color, fontSize: fontSize, whiteSpace: 'pre' }} level={level}>
            {`${nextStateT(nextState.state)}`}
            {`  (${nextState.timeLeftFormat})`}
          </Typography>
        </Grid>

        <Grid xs={4}>
          <Typography
            sx={{ color: color, fontSize: fontSize }}
            level={level}
          >{`${statusT('prize')}: $${tournament.prizePool}`}</Typography>
        </Grid>

        <Grid xs={8}>
          {myPlayer?.rank && myPlayer.rank > 0 ? (
            <Typography
              sx={{ color: color, fontSize: fontSize }}
              level={level}
            >{`${statusT('rank')}: ${myPlayer?.rank}/${remainingPlayers}`}</Typography>
          ) : (
            <Typography
              sx={{ color: color, fontSize: fontSize }}
              level={level}
            >{`${statusT('players')}: ${remainingPlayers}`}</Typography>
          )}
        </Grid>
        {/* <Grid xs={8}>
          <Typography sx={{ color: color, whiteSpace: 'pre', fontSize: fontSize }} level={level}></Typography>
        </Grid> */}
        <Grid xs={12}>
          <Typography sx={{ color: color, whiteSpace: 'pre', fontSize: fontSize }} level={level}>
            {`${statusT('stake')}: ${tournament.blindSmall}/${tournament.blindBig}`}
            {tournament.ante && tournament.ante > 0 && `  (${tournament.ante})`}
            {!hideTimer && `  (${blindLeftFormat})`}
          </Typography>
        </Grid>
      </Grid>
    );
  }, [
    tournament,
    color,
    fontSize,
    level,
    blindLeftFormat,
    myPlayer,
    nextState,
    nextStateT,
    remainingPlayers,
    stateT,
    statusT,
    hideTimer,
  ]);

  if (!tournament) {
    return <></>;
  }

  if (contianerType == 'general') {
    return (
      <Card sx={{ marginLeft: '10px', padding: '6px 4px 6px 12px', height: '70px' }} variant="solid" color="primary">
        <CardContent>{renderStatusGrid()}</CardContent>
      </Card>
    );
  } else {
    return renderStatusGrid();
  }
};
