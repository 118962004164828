import React from 'react';
import { useResponsive } from '../../hook/helper/useResponsive';
import CashTableDesktop from './CashTableDesktop';
import CashTableMobile from './CashTableMobile';
import { useDealerEventHandler } from '../../hook/events/useDealerEventHandler';
import { useBakupperKey } from '../../hook/events/useBakupperKey';
import { useBakupperCards } from '../../hook/events/useBakupperCards';
import { useBackupCards } from '../../hook/events/useBackupCards';

const CashTable: React.FC = () => {
  const { isMobile } = useResponsive();
  useDealerEventHandler();
  useBackupCards();
  useBakupperKey();
  useBakupperCards();

  return isMobile ? <CashTableMobile /> : <CashTableDesktop />;
};

export default CashTable;
