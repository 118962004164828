import React, { useEffect, useMemo } from 'react';
import { Box, Checkbox, Sheet, Typography } from '@mui/joy';
import { usePlayerContext } from '../../context/PlayerContext';
import { PokerActionType } from '@ztp/shared';
import palette from '../../styles/theme';
import { useTranslation } from 'react-i18next';
import { Styles } from '../../styles/type';
import { usePlayerPreAction } from '../../hook/games/usePlayerPreAction';

interface PlayerPreActionProps {
  myTurn: boolean;
  agreeCallAmount: number;
  handleSelectAutoAction: (action: PokerActionType | null) => void;
  autoAction: PokerActionType | null;
}

const PlayerPreAction: React.FC<PlayerPreActionProps> = ({
  myTurn,
  handleSelectAutoAction,
  autoAction,
  agreeCallAmount,
}) => {
  const { state } = usePlayerContext();
  const { t } = useTranslation('translation', { keyPrefix: 'action' });
  const { actions, callAmount, allInAmount } = usePlayerPreAction();

  const round = state.gameRound;

  const small = state.table?.type?.small || 1;
  const decimals = useMemo(() => {
    if (small < 0.1) {
      return 2;
    }
    if (small < 1) {
      return 1;
    }
    return 0;
  }, [small]);

  const actionColor = useMemo(() => {
    const actionColorMap = new Map<PokerActionType, string>();
    actionColorMap.set(PokerActionType.Fold, palette.text.danger);
    actionColorMap.set(PokerActionType.CheckOrFold, palette.text.danger);
    actionColorMap.set(PokerActionType.Check, palette.text.primary);
    actionColorMap.set(PokerActionType.Raise, palette.text.primary);
    actionColorMap.set(PokerActionType.Call, palette.text.primary);

    return actionColorMap;
  }, []);

  useEffect(() => {
    if (!autoAction) {
      return;
    }

    if (actions.every((a) => a != autoAction)) {
      if (autoAction == PokerActionType.CheckOrFold) {
        handleSelectAutoAction(PokerActionType.Fold);
      } else {
        handleSelectAutoAction(null);
      }
    }
  }, [actions, autoAction, handleSelectAutoAction]);

  useEffect(() => {
    if (!autoAction) {
      return;
    }

    if (autoAction === PokerActionType.Call && callAmount != agreeCallAmount) {
      handleSelectAutoAction(null);
    }
    if (autoAction == PokerActionType.Check && callAmount > 0) {
      handleSelectAutoAction(null);
    }
  }, [autoAction, handleSelectAutoAction, callAmount, agreeCallAmount]);

  if (myTurn || !round) {
    return <></>;
  }

  return (
    <Box sx={styles.container}>
      {actions.map((action) => (
        <Sheet
          key={action}
          sx={{
            flex: 1,
            padding: '8px',
            minWidth: '90px',
            maxHeight: '34px',
            minHeight: '34px',
            width: 'auto',
            borderRadius: '5%',
            lineHeight: 0,
            marginLeft: '2px',
          }}
          variant="solid"
        >
          <Checkbox
            color="warning"
            size="sm"
            checked={autoAction == action}
            onChange={(e) => handleSelectAutoAction(e.target.checked ? action : null)}
            label={
              <Box display={'flex'} flexDirection="column" overflow={'hidden'}>
                <Typography sx={{ color: actionColor.get(action) }} level="body-sm">
                  {`${t(action)} `}
                  {action == 'call' && (
                    <Typography sx={{ color: palette.text.secondary, textOverflow: 'ellipsis' }} level="body-sm">
                      {callAmount.toFixed(decimals)}
                    </Typography>
                  )}
                </Typography>
              </Box>
            }
          />
        </Sheet>
      ))}
    </Box>
  );
};

const styles: Styles = {
  container: {
    flexDirection: 'row' as const,
    flex: 1,
    display: 'flex',
    flexFlow: 'row',
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
    justifyItems: 'flex-end',
    marginRight: '5px',
  },
};

export default PlayerPreAction;
