import React, { FC } from 'react';
import { SliderProps } from './Slider';
import { Swiper } from 'swiper/react';
import { Pagination, Autoplay, EffectCoverflow, Navigation } from 'swiper/modules';
import styles from '../../styles/swiper.module.css';

const SliderDesktop: FC<SliderProps> = ({ children }) => {
  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={1}
      effect={'coverflow'}
      loop={true}
      navigation={true}
      autoplay={{
        delay: 5000,
        pauseOnMouseEnter: true,
        disableOnInteraction: true,
      }}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, Autoplay, EffectCoverflow, Navigation]}
      className={styles.swiper}
    >
      {children}
    </Swiper>
  );
};

export default SliderDesktop;
