import { Box } from '@mui/joy';
import React, { useMemo } from 'react';
import Text from '../Text/Text';
import { Styles } from '../../styles/type';
import { useChipsFormat } from '../../hook/games/useChipsFormat';

export interface BetChipsProps {
  amount: number;
  smallBlind: number;
}

interface ChipColor {
  color: string;
  dark: string;
}

function darkenColor(hex: string, percent: number): string {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Calculate the darken amount
  r = Math.round(r * (1 - percent / 100));
  g = Math.round(g * (1 - percent / 100));
  b = Math.round(b * (1 - percent / 100));

  // Ensure values are within 0-255 range
  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  // Convert back to hex
  const newHex = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();

  return newHex;
}

const BetChips: React.FC<BetChipsProps> = ({ amount, smallBlind }) => {
  const { round } = useChipsFormat();
  const unit = useMemo(() => {
    if (smallBlind >= 1) {
      return 1;
    } else if (smallBlind >= 0.1) {
      return 0.1;
    }
    return 0.01;
  }, [smallBlind]);

  const roundAmount: number = useMemo(() => round(amount), [round, amount]);

  const units = useMemo(() => {
    return [unit, unit * 10, unit * 100, unit * 1000, unit * 5000, unit * 10000, unit * 50000, unit * 100000];
  }, [unit]);

  const colorMap = useMemo(() => {
    const map = new Map<number, ChipColor>();
    map.set(units[0], { color: '#E32937', dark: darkenColor('#E32937', 25) });
    map.set(units[1], { color: '#008000', dark: darkenColor('#008000', 25) });
    map.set(units[2], { color: '#0000FF', dark: darkenColor('#0000FF', 25) });
    map.set(units[3], { color: '#17202A', dark: darkenColor('#17202A', 25) });
    // map.set(units[4], { color: '#DE3163', dark: darkenColor('#DE3163', 25) });
    // map.set(units[5], { color: '#FFFF00', dark: darkenColor('#FFFF00', 25) });
    // map.set(units[6], { color: '#000080', dark: darkenColor('#000080', 25) });
    map.set(units[4], { color: '#800080', dark: darkenColor('#800080', 25) });
    map.set(units[5], { color: '#CD7F32', dark: darkenColor('#CD7F32', 25) });
    map.set(units[6], { color: '#C0C0C0', dark: darkenColor('#C0C0C0', 25) });
    map.set(units[7], { color: '#E3C329', dark: darkenColor('#E3C329', 25) });
    return map;
  }, [units]);

  const amountUnits = useMemo(() => {
    let remainingAmount = roundAmount;
    const currentAmountUnits = [];
    for (let x = units.length - 1; x >= 0 && remainingAmount > 0; x--) {
      while (remainingAmount >= units[x]) {
        currentAmountUnits.push(units[x]);
        remainingAmount -= units[x];
      }
    }
    return currentAmountUnits;
  }, [roundAmount, units]);

  if (roundAmount <= 0) {
    return <></>;
  }

  let firstHalf = amountUnits;
  let secondHalf: number[] = [];

  // Only split if the length is greater than 10
  if (amountUnits.length > 10) {
    const splitIndex = Math.ceil(amountUnits.length / 2);
    firstHalf = amountUnits.slice(0, splitIndex);
    secondHalf = amountUnits.slice(splitIndex);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
        <svg height={`${20 + 4 * firstHalf.length}px`} width={'65px'} fill="none">
          {firstHalf.map((u, i) => (
            <g key={i} transform={`translate(0, ${4 * (firstHalf.length - 1 - i)})`}>
              <path
                d="M55.825 12.7172C55.8256 12.5561 55.8476 9.7272 55.8476 9.7272L55.2362 10.4372C53.0813 6.33922 45.2341 3.30094 35.8828 3.29846C27.15 3.29641 19.7273 5.93843 17.0269 9.6271L15.9528 9.54546L15.9346 12.7036C15.9333 17.8995 24.8611 22.1179 35.8767 22.1224C46.8924 22.1268 55.8219 17.9165 55.825 12.7172Z"
                fill={colorMap.get(u)?.dark}
              />
              <path
                d="M35.9027 18.8217C46.9174 18.8258 55.8481 14.6165 55.85 9.42C55.8519 4.22349 46.9242 0.00757567 35.9095 0.00350043C24.8948 -0.000574804 15.9641 4.20873 15.9622 9.40524C15.9603 14.6017 24.888 18.8177 35.9027 18.8217Z"
                fill={colorMap.get(u)?.color}
              />
              <path
                d="M35.9041 14.9365C42.3691 14.9388 47.6109 12.4677 47.612 9.41694C47.6131 6.36621 42.3731 3.89117 35.9081 3.88877C29.4431 3.88638 24.2013 6.35755 24.2001 9.40828C24.199 12.459 29.4391 14.9341 35.9041 14.9365Z"
                fill={colorMap.get(u)?.color}
              />
              <path
                d="M49.3979 5.07224L51.9905 3.84911C50.5048 2.89341 48.6408 2.07593 46.5097 1.44165L43.8722 2.68491C46.0814 3.26837 47.9745 4.088 49.3979 5.07224Z"
                fill="white"
              />
              <path
                d="M35.9093 1.70664C37.1632 1.70777 38.3835 1.77424 39.5567 1.89893L39.5561 0.157723C38.3724 0.0536748 37.1553 -0.00236028 35.9082 7.62426e-05C34.5976 -0.000154524 33.317 0.0589715 32.0769 0.174125L32.0757 1.91877C33.3059 1.78095 34.5867 1.7045 35.9093 1.70664Z"
                fill="white"
              />
              <path
                d="M52.238 9.41939C52.2383 9.87332 52.1529 10.3173 51.9955 10.7499L55.6542 10.7517C55.7858 10.3188 55.8559 9.87111 55.8538 9.42062C55.8527 8.66522 55.6641 7.92959 55.305 7.22263L51.5638 7.22127C52.0021 7.91727 52.237 8.65533 52.238 9.41939Z"
                fill="white"
              />
              <path
                d="M45.0015 15.8151L47.5947 17.0401C49.6299 16.3458 51.3757 15.474 52.7283 14.4752L50.0976 13.2307C48.8424 14.2673 47.0947 15.1512 45.0015 15.8151Z"
                fill="white"
              />
              <path
                d="M27.8266 2.71211L25.1941 1.47099C23.0703 2.11022 21.2239 2.93372 19.75 3.89091L22.3432 5.11586C23.7506 4.12824 25.6279 3.30347 27.8266 2.71211Z"
                fill="white"
              />
              <path
                d="M35.901 17.1174C34.5818 17.1171 33.2963 17.0427 32.0702 16.9034L32.069 18.648C33.3075 18.7633 34.5915 18.8238 35.9021 18.824C37.1475 18.825 38.3661 18.7684 39.5511 18.6681L39.5505 16.9269C38.379 17.0517 37.155 17.1186 35.9045 17.1192L35.901 17.1174Z"
                fill="white"
              />
              <path
                d="M21.641 13.1653L19.0035 14.4085C20.3425 15.4141 22.075 16.2937 24.0987 16.994L26.6912 15.7708C24.6105 15.0975 22.8765 14.2039 21.6394 13.1601L21.641 13.1653Z"
                fill="white"
              />
              <path
                d="M19.5706 9.40813C19.5696 8.64407 19.8053 7.90775 20.2447 7.21254L16.5036 7.21118C16.1464 7.91453 15.9537 8.65149 15.9548 9.40689C15.9551 9.86082 16.0226 10.3019 16.1537 10.7369L19.8124 10.7388C19.6537 10.3068 19.5675 9.86028 19.5706 9.40813Z"
                fill="white"
              />
              <path
                d="M15.9511 9.54024L15.9312 12.7018C15.9315 13.1558 16.0023 13.5986 16.1317 14.0371L16.1375 10.6674C16.0329 10.2986 15.973 9.92692 15.9648 9.54737L15.9511 9.54024Z"
                fill="#C7C7C7"
              />
              <path
                d="M18.9916 14.398L18.9884 17.7123C20.3308 18.7196 22.065 19.5958 24.0921 20.2978L24.0953 16.9922C22.0648 16.2884 20.3307 15.4035 18.9916 14.398Z"
                fill="#C7C7C7"
              />
              <path
                d="M35.9021 18.824C34.5915 18.8238 33.3127 18.7616 32.0724 18.6498L32.0676 21.9502C33.2993 22.0619 34.5747 22.1223 35.875 22.1258C37.1203 22.1268 38.3424 22.072 39.524 21.9699L39.5304 18.6747C38.354 18.7752 37.1405 18.8301 35.9038 18.8292L35.9021 18.824Z"
                fill="#C7C7C7"
              />
              <path
                d="M52.7612 14.4532C51.4016 15.4658 49.6437 16.3443 47.5963 17.0453L47.5934 20.3336C49.646 19.6309 51.4022 18.7472 52.7584 17.7328L52.7612 14.4532Z"
                fill="#C7C7C7"
              />
              <path
                d="M55.6508 10.75L55.7778 13.3645C55.8101 13.1501 55.827 12.9321 55.8268 12.7138C55.8271 12.57 55.8464 10.2851 55.8516 9.81215C55.8406 9.85877 55.833 9.90716 55.8238 9.95034C55.7924 10.2219 55.7353 10.4844 55.6559 10.7483L55.6508 10.75Z"
                fill="#C7C7C7"
              />
            </g>
          ))}
        </svg>

        {secondHalf.length > 0 && (
          <svg height={`${20 + 4 * secondHalf.length}px`} width={'65px'} fill="none">
            {secondHalf.map((u, i) => (
              <g key={i} transform={`translate(0, ${4 * (secondHalf.length - 1 - i)})`}>
                <path
                  d="M55.825 12.7172C55.8256 12.5561 55.8476 9.7272 55.8476 9.7272L55.2362 10.4372C53.0813 6.33922 45.2341 3.30094 35.8828 3.29846C27.15 3.29641 19.7273 5.93843 17.0269 9.6271L15.9528 9.54546L15.9346 12.7036C15.9333 17.8995 24.8611 22.1179 35.8767 22.1224C46.8924 22.1268 55.8219 17.9165 55.825 12.7172Z"
                  fill={colorMap.get(u)?.dark}
                />
                <path
                  d="M35.9027 18.8217C46.9174 18.8258 55.8481 14.6165 55.85 9.42C55.8519 4.22349 46.9242 0.00757567 35.9095 0.00350043C24.8948 -0.000574804 15.9641 4.20873 15.9622 9.40524C15.9603 14.6017 24.888 18.8177 35.9027 18.8217Z"
                  fill={colorMap.get(u)?.color}
                />
                <path
                  d="M35.9041 14.9365C42.3691 14.9388 47.6109 12.4677 47.612 9.41694C47.6131 6.36621 42.3731 3.89117 35.9081 3.88877C29.4431 3.88638 24.2013 6.35755 24.2001 9.40828C24.199 12.459 29.4391 14.9341 35.9041 14.9365Z"
                  fill={colorMap.get(u)?.color}
                />
                <path
                  d="M49.3979 5.07224L51.9905 3.84911C50.5048 2.89341 48.6408 2.07593 46.5097 1.44165L43.8722 2.68491C46.0814 3.26837 47.9745 4.088 49.3979 5.07224Z"
                  fill="white"
                />
                <path
                  d="M35.9093 1.70664C37.1632 1.70777 38.3835 1.77424 39.5567 1.89893L39.5561 0.157723C38.3724 0.0536748 37.1553 -0.00236028 35.9082 7.62426e-05C34.5976 -0.000154524 33.317 0.0589715 32.0769 0.174125L32.0757 1.91877C33.3059 1.78095 34.5867 1.7045 35.9093 1.70664Z"
                  fill="white"
                />
                <path
                  d="M52.238 9.41939C52.2383 9.87332 52.1529 10.3173 51.9955 10.7499L55.6542 10.7517C55.7858 10.3188 55.8559 9.87111 55.8538 9.42062C55.8527 8.66522 55.6641 7.92959 55.305 7.22263L51.5638 7.22127C52.0021 7.91727 52.237 8.65533 52.238 9.41939Z"
                  fill="white"
                />
                <path
                  d="M45.0015 15.8151L47.5947 17.0401C49.6299 16.3458 51.3757 15.474 52.7283 14.4752L50.0976 13.2307C48.8424 14.2673 47.0947 15.1512 45.0015 15.8151Z"
                  fill="white"
                />
                <path
                  d="M27.8266 2.71211L25.1941 1.47099C23.0703 2.11022 21.2239 2.93372 19.75 3.89091L22.3432 5.11586C23.7506 4.12824 25.6279 3.30347 27.8266 2.71211Z"
                  fill="white"
                />
                <path
                  d="M35.901 17.1174C34.5818 17.1171 33.2963 17.0427 32.0702 16.9034L32.069 18.648C33.3075 18.7633 34.5915 18.8238 35.9021 18.824C37.1475 18.825 38.3661 18.7684 39.5511 18.6681L39.5505 16.9269C38.379 17.0517 37.155 17.1186 35.9045 17.1192L35.901 17.1174Z"
                  fill="white"
                />
                <path
                  d="M21.641 13.1653L19.0035 14.4085C20.3425 15.4141 22.075 16.2937 24.0987 16.994L26.6912 15.7708C24.6105 15.0975 22.8765 14.2039 21.6394 13.1601L21.641 13.1653Z"
                  fill="white"
                />
                <path
                  d="M19.5706 9.40813C19.5696 8.64407 19.8053 7.90775 20.2447 7.21254L16.5036 7.21118C16.1464 7.91453 15.9537 8.65149 15.9548 9.40689C15.9551 9.86082 16.0226 10.3019 16.1537 10.7369L19.8124 10.7388C19.6537 10.3068 19.5675 9.86028 19.5706 9.40813Z"
                  fill="white"
                />
                <path
                  d="M15.9511 9.54024L15.9312 12.7018C15.9315 13.1558 16.0023 13.5986 16.1317 14.0371L16.1375 10.6674C16.0329 10.2986 15.973 9.92692 15.9648 9.54737L15.9511 9.54024Z"
                  fill="#C7C7C7"
                />
                <path
                  d="M18.9916 14.398L18.9884 17.7123C20.3308 18.7196 22.065 19.5958 24.0921 20.2978L24.0953 16.9922C22.0648 16.2884 20.3307 15.4035 18.9916 14.398Z"
                  fill="#C7C7C7"
                />
                <path
                  d="M35.9021 18.824C34.5915 18.8238 33.3127 18.7616 32.0724 18.6498L32.0676 21.9502C33.2993 22.0619 34.5747 22.1223 35.875 22.1258C37.1203 22.1268 38.3424 22.072 39.524 21.9699L39.5304 18.6747C38.354 18.7752 37.1405 18.8301 35.9038 18.8292L35.9021 18.824Z"
                  fill="#C7C7C7"
                />
                <path
                  d="M52.7612 14.4532C51.4016 15.4658 49.6437 16.3443 47.5963 17.0453L47.5934 20.3336C49.646 19.6309 51.4022 18.7472 52.7584 17.7328L52.7612 14.4532Z"
                  fill="#C7C7C7"
                />
                <path
                  d="M55.6508 10.75L55.7778 13.3645C55.8101 13.1501 55.827 12.9321 55.8268 12.7138C55.8271 12.57 55.8464 10.2851 55.8516 9.81215C55.8406 9.85877 55.833 9.90716 55.8238 9.95034C55.7924 10.2219 55.7353 10.4844 55.6559 10.7483L55.6508 10.75Z"
                  fill="#C7C7C7"
                />
              </g>
            ))}
          </svg>
        )}
      </Box>
      <Text style={styles.chipsAmount}>{roundAmount}</Text>
    </Box>
  );
};

const styles: Styles = {
  chipsAmount: {
    color: 'white',
    marginTop: '2px',
  },
};

export default BetChips;
