import { useCallback, useEffect, useState } from 'react';
import { Player } from '@ztp/shared';
//import { useEns } from '../web3/useEns';

export const usePlayerNickname = (player?: Player) => {
  //const { lookupENSName } = useEns();
  const [nickname, setNickname] = useState(player?.id?.substring(0, 8) || '');

  const getNickname = useCallback((p: Player) => {
    if (p.nickname) {
      return p.nickname;
    }

    // const ens = await lookupENSName(player.id);
    // if (ens) {
    //   return setNickname(ens);
    // }
    const address = p.smartAccount || p.id;
    return address.substring(0, 8);
  }, []);

  const updateNickname = useCallback(async () => {
    if (!player) {
      return;
    }

    const n = getNickname(player);
    setNickname(n);
  }, [getNickname, player]);

  useEffect(() => {
    updateNickname();
  }, [updateNickname]);

  return { nickname, getNickname };
};
