import { useCallback } from 'react';
import { GameRound, GameType, RoundStage } from '@ztp/shared';

const useGameRules = () => {
  const playerCardsNumber = useCallback((gameType: GameType) => {
    return gameType == 'omaha' ? 4 : 2;
  }, []);

  const playerCardsIndexes = useCallback((playerAddress: string, gameRound: GameRound, gameType: GameType) => {
    const nPlayer = gameRound.players.length;
    const playerIndex = gameRound.players.findIndex((p) => p.address == playerAddress);
    const dealerIndex = gameRound.dealerIndex;

    const nCrads = gameType == 'omaha' ? 4 : 2;

    const cardsIndexes: number[] = [];
    for (let x = 0; x < nCrads; x++) {
      cardsIndexes.push(((playerIndex + dealerIndex + nPlayer - 1) % nPlayer) + x * nPlayer);
    }

    return cardsIndexes;
  }, []);

  const nextCommunityCardsIndexes = useCallback((stage: RoundStage, numberOfPlayer: number, gameType: GameType) => {
    const nCrads = gameType == 'omaha' ? 4 : 2;
    const communityStartIndex = numberOfPlayer * nCrads;

    if (stage == RoundStage.Flop) {
      return [communityStartIndex, communityStartIndex + 1, communityStartIndex + 2];
    } else if (stage == RoundStage.Turn) {
      return [communityStartIndex + 3];
    } else if (stage == RoundStage.River) {
      return [communityStartIndex + 4];
    }
    return [];
  }, []);

  const communityCardsIndexes = useCallback((numberOfPlayer: number, gameType: GameType) => {
    const nCrads = gameType == 'omaha' ? 4 : 2;
    const communityStartIndex = numberOfPlayer * nCrads;
    const indexes = Array(5)
      .fill(0)
      .map((_, i) => communityStartIndex + i);

    return indexes;
  }, []);

  return { playerCardsNumber, playerCardsIndexes, nextCommunityCardsIndexes, communityCardsIndexes };
};

export default useGameRules;
