import React, { useState } from 'react';
import { Divider, Tab, TabList, TabPanel, Tabs, Typography, tabClasses } from '@mui/joy';
import palette from '../styles/theme';
import { SxProps } from '@mui/material';
import TableTypeList from '../components/TypeTypeList/TableTypeList';

const TableListContainer: React.FC<any> = () => {
  const [index, setIndex] = useState(0);

  return (
    <>
      <Tabs
        aria-label="tabs"
        value={index}
        onChange={(_, value) => setIndex(value as number)}
        sx={{
          bgcolor: 'transparent',
          height: '100%',
          width: '100%',
        }}
      >
       

        <TabList
          disableUnderline
          sx={{
            p: 0.5,
            gap: 0.5,
            borderRadius: 'xl',
            bgcolor: 'transparent',
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              boxShadow: 'sm',
              bgcolor: palette.primary.solidBg,
            },
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <Tab sx={index == 0 ? styles.selected : styles.unSelected} disableIndicator>
            Tournament
            <Typography>New!</Typography>
          </Tab>
          <Tab sx={index == 1 ? styles.selected : styles.unSelected} disableIndicator>
            Cash Game
          </Tab>
        </TabList>

        <TabPanel sx={{ height: '100%', width: '100%' }} value={0}>
          <TableTypeList gameFormat="tourney" />
        </TabPanel>
        <TabPanel sx={{ height: '100%', width: '100%' }} value={1}>
          <TableTypeList gameFormat="cash" />
        </TabPanel>
      </Tabs>
    </>
  );
};

const styles = {
  selected: {
    color: palette.text.primary,
    borderWidth: '1px',
    borderColor: palette.primary.solidBorder,
    hover: { color: 'transparent' },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  } as SxProps,
  unSelected: {
    color: palette.text.primary,
    borderWidth: '0px',
    borderColor: 'transperant',
    hover: { color: 'transparent' },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  } as SxProps,
};

export default TableListContainer;
