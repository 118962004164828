"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateCards = exports.communityCardsIndexes = exports.nextCommunityCardsIndexes = exports.playerCardsIndexes = exports.playerCardsNumber = void 0;
const __1 = require("../");
const playerCardsNumber = (gameType) => {
    return gameType == 'omaha' ? 4 : 2;
};
exports.playerCardsNumber = playerCardsNumber;
const playerCardsIndexes = (playerIndex, dealerIndex, nPlayer, gameType) => {
    const nCrads = gameType == 'omaha' ? 4 : 2;
    const cardsIndexes = [];
    for (let x = 0; x < nCrads; x++) {
        cardsIndexes.push(((playerIndex + dealerIndex + nPlayer - 1) % nPlayer) + x * nPlayer);
    }
    return cardsIndexes;
};
exports.playerCardsIndexes = playerCardsIndexes;
const nextCommunityCardsIndexes = (stage, nPlayer, gameType) => {
    const nCrads = gameType == 'omaha' ? 4 : 2;
    const communityStartIndex = nPlayer * nCrads;
    if (stage == __1.RoundStage.Flop) {
        return [communityStartIndex, communityStartIndex + 1, communityStartIndex + 2];
    }
    else if (stage == __1.RoundStage.Turn) {
        return [communityStartIndex + 3];
    }
    else if (stage == __1.RoundStage.River) {
        return [communityStartIndex + 4];
    }
    return [];
};
exports.nextCommunityCardsIndexes = nextCommunityCardsIndexes;
const communityCardsIndexes = (nPlayer, gameType) => {
    const nCrads = gameType == 'omaha' ? 4 : 2;
    const communityStartIndex = nPlayer * nCrads;
    const indexes = Array(5)
        .fill(0)
        .map((_, i) => communityStartIndex + i);
    return indexes;
};
exports.communityCardsIndexes = communityCardsIndexes;
const validateCards = (cards) => {
    return cards.every((c) => c >= 2 && c <= 53);
};
exports.validateCards = validateCards;
