import { useMemo } from 'react';
import { Address, NetworkConfig } from './type';

export const useNetworkConfig = () => {
  const networkConfig = useMemo<NetworkConfig>(() => {
    return {
      name: 'Arbitrum One',
      contracts: {
        CashGameCashier: {
          address: (process.env.REACT_APP_CASH_GAME_CASHIER as Address) || '0x24',
        },
        TournamentCashier: {
          address: (process.env.REACT_APP_TOURNAMENT_CASHIER as Address) || '0x25',
        },
      },
      tokens: [
        {
          symbol: 'USDT',
          address: (process.env.REACT_APP_USDT_ARBITRUM_ADDRESS as Address) || '0x26',
        },
        {
          symbol: 'ZTP',
          address: (process.env.REACT_APP_ZTP_TOKEN_ADDRESS as Address) || '0x26',
        },
      ],
    };
  }, []);

  return { networkConfig };
};
