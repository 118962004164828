import { useEffect } from 'react';
import { BackupCardsPayload, GameRound, RestorePlayerCardsKeysRequest } from '@ztp/shared';
import { useWsContext } from '../../context/WebSocketContext';
import { useP2PEventContext } from '../../context/P2PEventContext';
import usePersistState from '../state/usePersistState';
import { useTabActive } from './useTabActive';

interface PlayerCardsPartMap {
  [key: string]: BackupCardsPayload;
}

export const useBakupperCards = () => {
  const { subscribe: subscribeP2P, emit: emitP2P, ready: readyP2P } = useP2PEventContext();
  const { subscribe, emit, ready } = useWsContext();
  const { isTabActive } = useTabActive();

  const { state: keyParts, setState: setKeyParts, reload } = usePersistState<PlayerCardsPartMap>('cardParts', {});

  useEffect(() => {
    if (!readyP2P || !subscribeP2P) {
      return;
    }

    const unsubscribe = subscribeP2P<BackupCardsPayload>('backup-cards', (_, message) => {
      console.debug(`recieved backup cards request from ${message.from}`);
      setKeyParts((prev) => {
        return {
          ...prev,
          [message.from]: message.payload,
        };
      });
    });

    return () => unsubscribe();
  }, [readyP2P, subscribeP2P, emitP2P, setKeyParts]);

  useEffect(() => {
    if (!ready || !subscribe) {
      return;
    }

    const unsubscribe = subscribe<GameRound>('game-round-ended', () => {
      setKeyParts({});
    });

    return () => unsubscribe();
  }, [ready, subscribe, setKeyParts]);

  useEffect(() => {
    if (!ready || !keyParts || !emit || !subscribe) {
      return;
    }

    const unsubscribe = subscribe<RestorePlayerCardsKeysRequest>(
      'restore-player-cards-keys-request',
      (request: RestorePlayerCardsKeysRequest) => {
        console.debug(`recieved restore cards reqeust for ${request.playerAddress}`);

        if (!keyParts[request.playerAddress]) {
          return;
        }

        const part = keyParts[request.playerAddress];
        if (!part) {
          return;
        }

        if (part.roundId != request.roundId) {
          return;
        }

        emit('restore-player-cards-keys-response', 'dealer', true, {
          roundId: part.roundId,
          cardsParts: part.cardsParts,
          playerAddress: request.playerAddress,
        });
        console.info(`sent restore cards parts, roundId: ${part.roundId}, for player: ${request.playerAddress}`);
      }
    );

    return () => unsubscribe();
  }, [ready, emit, subscribe, keyParts]);

  useEffect(() => {
    if (isTabActive) {
      reload();
    }
  }, [isTabActive, reload]);
};
