import { Box } from '@mui/joy';
import React, { useMemo } from 'react';
import PlayerBet from '../PlayerBet/PlayerBet';
import { useAuthContext } from '../../context/AuthContext';
import { CashTablePlayer, RoundStage, TableState } from '@ztp/shared';
import CardView from '../Card/CardView';
import CardPair from '../Card/CardPair';
import DealerPosition from '../DealerPosition/DealerPosition';
import TurnTimer from '../TurnTimer/TurnTimer';
import Crown from '../Crown/Crown';
import logo from '../../assets/logo.png';
import '../../styles/effect.css';
import Pots from '../Pots/Pots';
import TotalPots from '../Pots/TotalPots';
import { useEndGamePresenter } from '../../hook/games/useEndGamePresenter';
import { useCashGameSoundEffect } from '../../hook/sound/useCashGameSoundEffect';
import { TableViewProps } from './TableView';
import PlayerAvatar from '../PlayerAvatar/PlayerAvatar';
import PlayerState from '../PlayerState/PlayerState';
import PlayerNotification from '../PlayerNotification/PlayerNotification';
import { TourneyStatus } from '../Tournament/TourneyStatus';
import { usePlayerContext } from '../../context/PlayerContext';
import palette from '../../styles/theme';
import PlayerRank from '../PlayerRank/PlayerRank';

interface TableViewMobileProps extends TableViewProps {}

interface PlayerPosition {
  position: number;
  px: number; // Table Player point x
  py: number; // Table Player point y
  dx: number; // Dealer position point x
  dy: number; // Dealer position point y
  bx: number; // Player bet point x
  by: number; // Player bet point y
}

const logoPX = 700;
const logoPY = 465;
const cardWidth = 180;
const cardHeight = 250;
const communityCardsPX = 290;
const communityCardsPY = 1010;
const communityCardsMargin = 8;
const tourneyStatusPX = 480;
const tourneyStatusPY = 1400;
const potsPX = 450;
const potsPY = 590;
const totalPotsPX = 600;
const totalPotsPY = 525;
const playerWidth = 280;
const playerHeight = 290;
const timerWidth = 280;
const timerHeight = 20;

export const TableViewMobile: React.FC<TableViewMobileProps> = ({ table, round, tournament }) => {
  const { player } = useAuthContext();
  const {
    state: { tourney },
  } = usePlayerContext();
  const { potWinners } = useEndGamePresenter();
  useCashGameSoundEffect(potWinners);

  const myAddress = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const isTournamnet = !!table.tournamentId;
  const communityCards = round?.deck.communityCards || [];
  const tableType = table.type;
  const active = table.tableState === TableState.InProgress;
  const pots = round?.pots;
  const stakePot = round?.stakePot || 0;
  const playerTurn = round?.currentPlayerTurn.toLocaleLowerCase();
  const dealerSeatNumber = round?.dealerSeatNumber || 0;
  const roundStage = round?.roundStage || RoundStage.Finish;

  const players: CashTablePlayer[] = useMemo(
    () => (table ? Object.keys(table.players).map((k) => table.players[k]) : []),
    [table]
  );

  const myPlayer = players.find((p) => p.address.toLocaleLowerCase() == myAddress.toLocaleLowerCase());

  const playersPositions = useMemo(() => {
    const positions: PlayerPosition[] = [];

    if (tableType.maxPlayer <= 6) {
      positions.push({ position: 0, px: 630, py: 1860, dx: 635, dy: 1860, bx: 700, by: 1700 });
      positions.push({ position: 0, px: 70, py: 1700, dx: 260, dy: 1700, bx: 340, by: 1620 });
      // positions.push({ position: 0, px: 10, py: 1250, dx: 190, dy: 1250, bx: 320, by: 1350 });
      positions.push({ position: 0, px: 10, py: 660, dx: 190, dy: 660, bx: 320, by: 740 });
      // positions.push({ position: 0, px: 70, py: 220, dx: 250, dy: 220, bx: 380, by: 420 });
      positions.push({ position: 0, px: 630, py: 30, dx: 630, dy: 30, bx: 710, by: 375 });
      positions.push({ position: 0, px: 1130, py: 220, dx: 1130, dy: 220, bx: 970, by: 420 });
      // positions.push({ position: 0, px: 1180, py: 660, dx: 1180, dy: 660, bx: 1020, by: 740 });
      positions.push({ position: 0, px: 1130, py: 1700, dx: 1130, dy: 1700, bx: 1000, by: 1620 });
    } else if (tableType.maxPlayer <= 9) {
      positions.push({ position: 0, px: 630, py: 1860, dx: 635, dy: 1860, bx: 700, by: 1700 });
      positions.push({ position: 0, px: 70, py: 1700, dx: 260, dy: 1700, bx: 340, by: 1620 });
      positions.push({ position: 0, px: 10, py: 1250, dx: 190, dy: 1250, bx: 320, by: 1350 });
      positions.push({ position: 0, px: 10, py: 660, dx: 190, dy: 660, bx: 320, by: 740 });
      positions.push({ position: 0, px: 70, py: 220, dx: 250, dy: 220, bx: 380, by: 420 });
      positions.push({ position: 0, px: 630, py: 30, dx: 630, dy: 30, bx: 710, by: 375 });
      positions.push({ position: 0, px: 1130, py: 220, dx: 1130, dy: 220, bx: 970, by: 420 });
      positions.push({ position: 0, px: 1180, py: 660, dx: 1180, dy: 660, bx: 1020, by: 740 });
      positions.push({ position: 0, px: 1130, py: 1700, dx: 1130, dy: 1700, bx: 1000, by: 1620 });
    } else {
      console.error('Max players for table is 9 !!!!!!!!!!!');
    }

    let currentPosition = myPlayer?.seatNumber || 0;
    positions.forEach((p) => {
      p.position = currentPosition;
      currentPosition++;
      if (currentPosition == positions.length) {
        currentPosition = 0;
      }
    });

    return positions.sort((p1, p2) => (p1.position < p2.position ? -1 : 1));
  }, [tableType, myPlayer]);

  return (
    <div
      style={{
        flex: 1,
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        overflow: 'hidden' /* Prevents scrolling */,
        alignSelf: 'center',
        justifySelf: 'center',
        flexDirection: 'column' as const,
        fontSize: '34px',
      }}
    >
      <svg width="100%" height="100%" viewBox="0 0 1500 2200" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <radialGradient id="pipeGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
            <stop offset="0%" style={{ stopColor: '#2a2a2a' }} />
            <stop offset="30%" style={{ stopColor: '#2a2a2a' }} />
            <stop offset="60%" style={{ stopColor: '#2a2a2a' }} />
            <stop offset="100%" style={{ stopColor: '#0e0e0e' }} />
          </radialGradient>

          <pattern id="roughPattern" patternUnits="userSpaceOnUse" width="1" height="1">
            <circle cx="0.5" cy="0.5" r="0.50" fill="#07332D" />
            <circle cx="0.9" cy="0.9" r="0.35" fill="#06302A" />
            <circle cx="0.7" cy="0.1" r="0.35" fill="#06302A" />
            <circle cx="0.5" cy="0.5" r="0.35" fill="#06302A" />
            <circle cx="0.1" cy="0.7" r="0.35" fill="#06302A" />
            <circle cx="0.3" cy="0.3" r="0.50" fill="#06302A" />
          </pattern>
        </defs>
        {/* ---- TABLE BOARD ----- */}
        <rect
          x="100"
          y="100"
          width="1300"
          height="2050"
          rx="550"
          fill="url(#pipeGradient)"
          stroke="#000000"
          strokeWidth="0.01"
        />
        <rect
          x="200"
          y="200"
          width="1100"
          height="1850"
          rx="450"
          fill="url(#roughPattern)"
          stroke="#000000"
          strokeWidth="0.01"
        />

        <foreignObject x={logoPX} y={logoPY} width={150} height={150}>
          <Box sx={{ opacity: 0.2 }}>
            <img
              style={{
                height: '100%',
                width: '100%',
              }}
              src={logo}
              alt="Zero Trust Poker Logo"
            />
          </Box>
        </foreignObject>

        {/* ---- Total Pots ----- */}
        <foreignObject x={totalPotsPX} y={totalPotsPY} width={320} height={120}>
          {roundStage > RoundStage.PreFlop && <TotalPots pots={pots} />}
        </foreignObject>

        {/* ------- POT ------ */}
        <foreignObject x={potsPX} y={potsPY} width="580" height="350">
          {roundStage != RoundStage.Finish && (
            <Pots
              pots={pots}
              stakePot={stakePot}
              distributePotIndex={potWinners?.potIndex}
              smallBlind={tableType.small}
            />
          )}
        </foreignObject>

        {/* ---- Community Cards ----- */}
        {communityCards.map((c, i) => {
          return (
            <foreignObject
              key={i}
              x={communityCardsPX + i * (cardWidth + communityCardsMargin)}
              y={communityCardsPY}
              width={cardWidth}
              height={cardHeight}
            >
              <CardView key={c.card} card={c.card} />
            </foreignObject>
          );
        })}

        {/* ---- Players ----- */}
        {players.map((p) => {
          const roundPlayer = round?.players.find(
            (rp) => rp.address.toLocaleLowerCase() == p.address.toLocaleLowerCase()
          );
          return (
            <React.Fragment key={p.address}>
              <foreignObject
                x={playersPositions[p.seatNumber].bx}
                y={playersPositions[p.seatNumber].by}
                width="130"
                height="120"
              >
                {roundStage < RoundStage.Presentation && <PlayerBet player={p} />}
              </foreignObject>

              <foreignObject
                x={playersPositions[p.seatNumber].px + 90}
                y={playersPositions[p.seatNumber].py - 60}
                width="100"
                height="100"
              >
                <Crown
                  visable={
                    roundStage == RoundStage.Presentation &&
                    !!potWinners?.winners &&
                    potWinners.winners.some((w) => w.playerAddress == p.address)
                  }
                />
              </foreignObject>

              <foreignObject
                key={p.address}
                x={playersPositions[p.seatNumber].px}
                y={playersPositions[p.seatNumber].py}
                width={playerWidth}
                height={playerHeight}
              >
                <PlayerRank tournament={tournament} playerAddress={p.address}>
                  <PlayerAvatar avatarId={p.player.avatarId} />
                </PlayerRank>
              </foreignObject>

              <foreignObject
                x={playersPositions[p.seatNumber].dx}
                y={playersPositions[p.seatNumber].dy}
                width="60"
                height="50"
              >
                <DealerPosition visable={roundStage < RoundStage.Presentation && dealerSeatNumber == p.seatNumber} />
              </foreignObject>

              {roundPlayer && active && (
                <foreignObject
                  x={playersPositions[p.seatNumber].px + 20}
                  y={playersPositions[p.seatNumber].py + 55}
                  width={playerWidth - 40}
                  height={playerHeight - 150}
                >
                  <CardPair roundPlayer={roundPlayer} />
                </foreignObject>
              )}

              <foreignObject
                x={playersPositions[p.seatNumber].px}
                y={playersPositions[p.seatNumber].py + 190}
                width={playerWidth}
                height={140}
              >
                <PlayerState
                  player={p}
                  visible={roundStage < RoundStage.Presentation && p.address.toLocaleLowerCase() == playerTurn}
                />
              </foreignObject>

              <foreignObject
                x={playersPositions[p.seatNumber].px}
                y={playersPositions[p.seatNumber].py + 170}
                width={playerWidth}
                height={100}
              >
                <PlayerNotification active={active} player={p} potWinners={potWinners} />
              </foreignObject>

              <foreignObject
                x={playersPositions[p.seatNumber].px}
                y={playersPositions[p.seatNumber].py + playerHeight + 4} // Position timer below the player
                width={timerWidth}
                height={timerHeight}
              >
                <TurnTimer
                  visible={roundStage < RoundStage.Presentation && p.address.toLocaleLowerCase() == playerTurn}
                  tableType={tableType}
                />
              </foreignObject>
            </React.Fragment>
          );
        })}

        {/* ---- Tourney Status */}
        {isTournamnet && (
          <foreignObject
            x={tourneyStatusPX}
            y={tourneyStatusPY} // Position timer below the player
            width={800}
            height={150}
          >
            <TourneyStatus
              contianerType="transperent"
              fontSize="34px"
              hideTimer={true}
              color={palette.text.litePrimary}
              tournament={tourney?.find((t) => t.id == table.tournamentId)}
            />
          </foreignObject>
        )}
      </svg>
    </div>
  );
};

export default TableViewMobile;
