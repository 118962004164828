import { useEffect, useState } from 'react';

const SEC = 1000;
const MIN = SEC * 60;
const HOUR = MIN * 60;

export const useCountdown = (targetDate: Date | null | undefined) => {
  const [timeLeftFormat, setTimeLeftFormat] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [done, setDone] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!targetDate) {
      return;
    }

    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = targetDate.getTime() - now;

      if (difference <= 0) {
        setTimeLeftFormat('');
        setTimeLeft(0);
        setDone(true);
        return;
      }

      setTimeLeft(difference);

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      if (days > 0) {
        timer = setTimeout(calculateTimeLeft, HOUR);
        setTimeLeftFormat(`${days}d ${hours}h`);
      } else if (hours > 0) {
        timer = setTimeout(calculateTimeLeft, MIN);
        setTimeLeftFormat(`${hours}h ${minutes}m`);
      } else if (minutes >= 10) {
        timer = setTimeout(calculateTimeLeft, MIN);
        setTimeLeftFormat(`${minutes}m`);
      } else if (minutes > 0) {
        timer = setTimeout(calculateTimeLeft, SEC);
        setTimeLeftFormat(`${minutes}m ${seconds}s`);
      } else {
        timer = setTimeout(calculateTimeLeft, SEC);
        setTimeLeftFormat(`${seconds}s`);
      }
    };

    calculateTimeLeft();

    return () => clearInterval(timer);
  }, [targetDate]);

  return { timeLeftFormat, timeLeft, done };
};
