import React from 'react';
import logo from '../assets/logo.png';
import { useAuthContext } from '../context/AuthContext';
import ConnectButton from '../components/Auth/ConnectButton';
import { SelectToken } from '../components/Cryptocurrency/SelectToken';
import Typography from '@mui/joy/Typography';
import styles from '../styles/shell.module.css';
import { useDataDogLogger } from '../hook/logger/useDataDogLogger';
import ProfileButton from '../components/Profile/ProfileButton';
import { useI18nLoader } from '../hook/helper/useI18nLoader';
import Loading from '../components/Loading/Loading';
import SendButton from '../components/Cryptocurrency/SendButton';
import ReciveButton from '../components/Cryptocurrency/ReciveButton';
import { Divider } from '@mui/joy';
import { useResponsive } from '../hook/helper/useResponsive';
import usePwaDialog from '../hook/helper/usePwaDialog';

export default function Shell({ children }: any) {
  const { authenticated } = useAuthContext();
  const { initialized } = useI18nLoader();
  const { isMobile } = useResponsive();
  usePwaDialog();
  useDataDogLogger();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <header>
          {initialized && (
            <div className={styles.header}>
              <div className={styles.logo}>
                <img
                  style={{ height: '48px', width: '48px', marginRight: '5px' }}
                  src={logo}
                  alt="Zero Trust Poker Logo"
                />
                {!isMobile && (
                  <Typography sx={{ color: 'white' }} level="h1">
                    Zero Trust Poker
                  </Typography>
                )}
              </div>

              <div className={styles.buttons}>
                {authenticated == 'authenticated' ? (
                  <>
                    <SendButton />
                    <ReciveButton />
                    <SelectToken />
                    <ProfileButton />
                  </>
                ) : (
                  <ConnectButton />
                )}
              </div>
            </div>
          )}
          <Divider sx={{ width: '100%', margin: '10px' }} />
        </header>
        <main className={styles.main}>{initialized ? children : <Loading size={120} />}</main>
      </div>
    </div>
  );
}
