import React, { useState, useEffect, useMemo } from 'react';

const usePwaInstalled = () => {
  const mediaQuery = useMemo(() => window.matchMedia('(display-mode: standalone)'), []);
  const [isInstalled, setIsInstalled] = useState(mediaQuery.matches);

  useEffect(() => {
    const handleChange = (e: MediaQueryListEvent) => {
      setIsInstalled(e.matches);
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [mediaQuery]);

  useEffect(() => {
    const handleAppInstalled = () => {
      setIsInstalled(true);
    };

    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  return { isInstalled };
};

export default usePwaInstalled;
