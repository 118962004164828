import { useCallback, useState } from 'react';
import { ethers } from 'ethers';
import { CashGameCashierFactory } from '@ztp/shared';
import { TransactionError } from './type';
import { useTokenBalance } from './useTokenBalance';
import { TableType } from '@ztp/shared';
import { useContractAddress } from './useContractAddress';
import { useContarctAllowance } from './useContarctAllowance';
import { sleep } from '../../helper/sleep';
import ReactGA from 'react-ga4';
import { useSmartAccount } from './useSmartAccount';

type ProgressState = 'user-approval' | 'user-transaction' | 'waiting-for-transaction' | null;

export const useCashContract = () => {
  const [isSucces, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<TransactionError>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [progressState, setProgressState] = useState<ProgressState>(null);
  const balance = useTokenBalance();
  const { smartClient } = useSmartAccount();
  const { contractAddress } = useContractAddress('cash-game');
  const { approve } = useContarctAllowance('cash-game');

  const FINALIZED_DELY = 10000;

  const cashIn = useCallback(
    async (tableType: TableType, amount: number) => {
      if (!smartClient) {
        return;
      }

      if (amount > balance.value) {
        return setError('insufficient-balance');
      }

      ReactGA.event({
        category: 'Button',
        action: 'cash-in',
        label: 'click',
      });

      setLoading(true);
      setProgressState('user-approval');

      try {
        const approved = await approve(balance.token!.address, amount, balance.value);
        if (!approved) {
          console.warn('user not approve');
          return setError('execution-failed');
        }
        setProgressState('user-transaction');

        const iface = new ethers.Interface(CashGameCashierFactory.abi);
        const data = iface.encodeFunctionData('cashIn', [
          balance.token!.address,
          ethers.parseUnits(amount.toFixed(6), 6),
          BigInt(tableType.id),
        ]);

        const approvalTx = await smartClient.sendUserOperation({
          uo: {
            target: contractAddress as `0x${string}`,
            data: data as `0x${string}`,
            value: 0n,
          },
        });

        console.info(approvalTx.hash);

        setProgressState('waiting-for-transaction');
        await sleep(FINALIZED_DELY);

        if (!approvalTx.hash) {
          return setError('execution-failed');
        }

        ReactGA.event({
          category: 'Button',
          action: 'cash-in',
          label: 'success',
        });

        setSuccess(true);
      } catch (error) {
        console.error(error);
        setError('execution-failed');
      } finally {
        setLoading(false);
      }
    },
    [balance, contractAddress, smartClient, setError, setLoading, setSuccess, approve]
  );

  const rebuy = useCallback(
    async (tableType: TableType, amount: number, tableId: string) => {
      if (!smartClient) {
        return;
      }

      if (amount > balance.value) {
        return setError('insufficient-balance');
      }

      setLoading(true);
      setProgressState('user-approval');

      try {
        const approved = await approve(balance.token!.address, amount, balance.value);
        if (!approved) {
          console.warn('user not approve');
          return setError('execution-failed');
        }
        setProgressState('user-transaction');

        const iface = new ethers.Interface(CashGameCashierFactory.abi);
        const data = iface.encodeFunctionData('reBuy', [
          balance.token!.address,
          ethers.parseUnits(amount.toFixed(6), 6),
          BigInt(tableType.id),
          tableId,
        ]);

        const approvalTx = await smartClient.sendUserOperation({
          uo: {
            target: contractAddress as `0x${string}`,
            data: data as `0x${string}`,
            value: 0n,
          },
        });

        setProgressState('waiting-for-transaction');
        await sleep(FINALIZED_DELY);

        if (!approvalTx.hash) {
          return setError('execution-failed');
        }

        setSuccess(true);
      } catch (error) {
        console.error(error);
        setError('execution-failed');
      } finally {
        setLoading(false);
        setProgressState(null);
      }
    },
    [balance, contractAddress, approve, smartClient, setError, setLoading, setSuccess]
  );

  const reset = useCallback(() => {
    setSuccess(false);
    setError(undefined);
    setLoading(false);
    setProgressState(null);
  }, []);

  return { isSucces, isLoading, progressState, error, cashIn, rebuy, reset };
};
