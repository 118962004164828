import { useEffect } from 'react';
import { CurrentTablePayload, TableCurrentResponse } from '@ztp/shared';
import useLazyApi from '../api/useLazyApi';
import { usePlayerContext } from '../../context/PlayerContext';

export const useTableCurrent = (enabled: boolean, tableId?: string) => {
  const { updateState, clearState } = usePlayerContext();
  const { code, data, fetch } = useLazyApi<TableCurrentResponse, CurrentTablePayload>('POST', 'table/current', {
    refetchInterval: 5 * 1000,
  });

  useEffect(() => {
    if (enabled && tableId) {
      fetch({
        tableId: tableId,
      });
    }
  }, [enabled, tableId]);

  useEffect(() => {
    if (code == 404) {
      return clearState();
    }

    if (data) {
      updateState({
        table: data.table,
        gameRound: data.gameRound,
      });
    }
  }, [data, code, updateState, clearState]);
};
