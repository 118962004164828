import React from 'react';
import { usePlayerContext } from '../context/PlayerContext';
import { useTableEventHandler } from '../hook/events/useTableEventHandler';
import CashTable from '../components/CashTable/CashTable';
import TableListContainer from './TableListContainer';
import TourneyTable from '../components/TourneyTable/TourneyTable';
import { useTourneyEventHandler } from '../hook/events/useTourneyEventHandler';
import { useAuthContext } from '../context/AuthContext';

const GameContainer: React.FC<any> = () => {
  const { state } = usePlayerContext();
  const { authenticated } = useAuthContext();

  useTourneyEventHandler();
  useTableEventHandler();

  if (state.table) {
    if (authenticated == 'unauthenticated') {
      return <></>;
    }

    console.warn(JSON.stringify(state.table));
    if (state.table.type.gameFormat == 'cash') {
      return <CashTable />;
    }
    return <TourneyTable />;
  }

  return <TableListContainer />;
};

export default GameContainer;
