import { useCallback, useEffect, useState } from 'react';
import { openDB, IDBPDatabase } from 'idb';

interface DBSchema {
  keyval: {
    key: string;
    value: any;
  };
}

let db: IDBPDatabase<DBSchema> | null = null;

async function initDB() {
  db = await openDB<DBSchema>('ZERO-TRUST-POKER-DB', 1, {
    upgrade(db) {
      db.createObjectStore('secrets');
    },
  });
}

async function getFromDB(key: string) {
  return db?.get('secrets', key);
}

async function setToDB(key: string, val: any) {
  return db?.put('secrets', val, key);
}

export function useSecurePersistState<T>(key: string, defualtState: T | undefined) {
  const [state, setValue] = useState<T | undefined>();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initDB().then(() => {
      getFromDB(key)
        .then((val) => {
          setValue(val || defualtState);
        })
        .finally(() => setInitialized(true));
    });
  }, [setValue, defualtState, key]);

  const setState = useCallback(
    (value: T | undefined) => {
      setValue(value);
      setToDB(key, value);
    },
    [setValue, key]
  );

  const reload = useCallback(() => {
    getFromDB(key).then((val) => {
      if (val) {
        setValue(val);
      }
    });
  }, [key]);

  return { state, setState, initialized, reload };
}
