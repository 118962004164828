import React, { useCallback, useEffect, useState } from 'react';
import { useResponsive } from '../../hook/helper/useResponsive';
import { Box, Button, Checkbox, Typography } from '@mui/joy';
import palette from '../../styles/theme';

interface PwaDialogProps {
  install: () => void;
  close: () => void;
  updateHide: (hide: boolean) => void;
}

const PwaDialog: React.FC<PwaDialogProps> = ({ install, close, updateHide }) => {
  const { isIOS } = useResponsive();
  const [hide, setHide] = useState(false);

  const handleHide = useCallback(
    (hide: boolean) => {
      setHide(hide);
      updateHide(hide);
    },
    [setHide, updateHide]
  );

  return (
    <Box
      height={'220px'}
      width={'280px'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'space-around'}
    >
      {isIOS ? (
        <Typography level="body-sm">
          {`For an enhanced experience, we recommend installing Zero Trust Poker on your device`}
          <br />
          <br />
          {`To install the app, tap the Share icon, and then select "Add to Home Screen"`}
        </Typography>
      ) : (
        <Typography level="body-sm">
          {`For an enhanced experience, we recommend installing Zero Trust Poker on your device`}
          <br />
          <br />
        </Typography>
      )}

      <Checkbox
        checked={hide}
        size="sm"
        onChange={(e) => handleHide(e.target.checked)}
        color="warning"
        label={`Don't show again`}
      />

      {isIOS ? (
        <Button
          variant="solid"
          color="neutral"
          sx={{
            color: palette.text.primary,
            minHeight: '40px',
            height: '40px',
            maxHight: '40px',
            borderRadius: 5,
            borderWidth: 0.5,
            hover: { borderColor: 'red', backgroundColor: 'transparent' },
          }}
          onClick={() => close()}
        >
          <Typography color="neutral" level="body-lg">
            {`Close`}
          </Typography>
        </Button> // Just a close button for iOS users
      ) : (
        <Box display={'flex'} flexDirection={'row'}>
          <Button
            onClick={() => close()}
            variant="solid"
            color="neutral"
            sx={{
              color: palette.text.primary,
              minHeight: '40px',
              height: '40px',
              maxHight: '40px',
              borderRadius: 5,
              borderWidth: 0.5,

              hover: { borderColor: 'red', backgroundColor: 'transparent' },
            }}
          >
            <Typography color="neutral" level="body-lg">
              {`Later`}
            </Typography>
          </Button>
          <Button
            onClick={() => {
              install();
              close();
            }}
            variant="solid"
            color="neutral"
            sx={{
              color: palette.text.secondary,
              minHeight: '40px',
              height: '40px',
              maxHight: '40px',
              borderRadius: 5,
              borderWidth: 0.5,
              marginLeft: '10px',
              hover: { borderColor: 'red', backgroundColor: 'transparent' },
            }}
          >
            <Typography level="body-lg">{`Install`}</Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PwaDialog;
