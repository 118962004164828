import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/joy';
import Text from '../Text/Text';
import { Styles } from '../../styles/type';
import { motion } from 'framer-motion';
import { CashTablePlayer, PokerActionType, PotWinners, RoundStage } from '@ztp/shared';
import { useAuthContext } from '../../context/AuthContext';
import { usePlayerContext } from '../../context/PlayerContext';
import { useTranslation } from 'react-i18next';
import palette from '../../styles/theme';

export interface PlayerProps {
  player: CashTablePlayer;
  potWinners?: PotWinners;
  active: boolean;
}

interface PopupMessage {
  text: string;
  color: string;
}

const MSG_TTL = 4000;

const PlayerNotification: React.FC<PlayerProps> = ({ player, active, potWinners }) => {
  const { state } = usePlayerContext();
  const { t: tAction } = useTranslation('translation', { keyPrefix: 'action' });
  const { t: tHandname } = useTranslation('translation', { keyPrefix: 'handname' });
  const { player: myPlayer } = useAuthContext();
  const [message, setMessage] = useState<PopupMessage | null>(null);

  const myAddress = useMemo(() => {
    return myPlayer?.id || '';
  }, [myPlayer]);

  const actionColor = useMemo(() => {
    const actionColorMap = new Map<PokerActionType, string>();
    actionColorMap.set(PokerActionType.Fold, palette.text.danger);
    actionColorMap.set(PokerActionType.Check, palette.info.solidBg);
    actionColorMap.set(PokerActionType.Raise, palette.success.solidBg);
    actionColorMap.set(PokerActionType.Call, palette.success.solidBg);

    return actionColorMap;
  }, []);

  const lastAction = useMemo(() => {
    if (myAddress == player.address) {
      return null;
    }

    if (state.gameRound?.actionHistory && state.gameRound?.actionHistory.length > 0) {
      const lastPlayerAction = state.gameRound?.actionHistory[state.gameRound.actionHistory.length - 1];
      if (
        !lastPlayerAction ||
        lastPlayerAction.playerAddress != player.address ||
        lastPlayerAction.action == PokerActionType.Blind
      ) {
        return null;
      }
      return lastPlayerAction.action;
    }
    return null;
  }, [state, myAddress, player]);

  useEffect(() => {
    if (!lastAction || !active) {
      return;
    }

    setMessage({
      text: tAction(lastAction),
      color: actionColor.get(lastAction) || palette.info.solidBg,
    });
  }, [lastAction, tAction, active, actionColor]);

  useEffect(() => {
    const players = state.gameRound?.players;
    if (!active || !players) {
      return;
    }

    if (state.gameRound?.roundStage !== RoundStage.Presentation || !potWinners) {
      return;
    }

    if (!potWinners.eligiblePlayers.some((p) => p == player.address)) {
      return;
    }

    const thisRoundPlayer = players.find((p) => p.address == player.address);
    if (!thisRoundPlayer || !thisRoundPlayer.handName) {
      return;
    }

    setMessage({
      text: tHandname(thisRoundPlayer.handName.toString()),
      color: palette.info.solidBg,
    });
  }, [state, player, tHandname, potWinners, active]);

  useEffect(() => {
    if (state.gameRound?.roundStage === RoundStage.Finish) {
      setMessage(null);
    }
  }, [state]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMessage(null);
    }, MSG_TTL);

    return () => clearTimeout(timeout);
  }, [message]);

  return (
    <motion.div
      initial={{ opacity: message ? 0.5 : 1 }}
      animate={{ opacity: message ? 1 : 0 }}
      transition={{ duration: 0.25 }}
      style={{ zIndex: 52 }}
    >
      <Box sx={styles.contianer}>
        <Box sx={[styles.textContainer, { backgroundColor: message?.color }]}>
          <Text style={styles.text}>{message?.text}</Text>
        </Box>
      </Box>
    </motion.div>
  );
};

export default PlayerNotification;

const styles: Styles = {
  contianer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row', // You can leave this, but we need to ensure the span is allowed to wrap inside.
    alignItems: 'center', // Vertical alignment of items in the center
    justifyContent: 'center', // Center horizontally if needed
    minHeight: '55px', // The minimum height of the container
    wordBreak: 'break-word', // Ensure long words break inside the container
    overflowWrap: 'break-word', // Ensure long words wrap inside the container
    flexWrap: 'wrap', // Allow items inside to wrap
    padding: '0px',
    minWidth: '80%', // The container takes up at least 80% of the available space
    borderWidth: 0,
    borderRadius: '5%',
    lineHeight: 1.2, // Proper line height for the text to be readable
  },
  text: {
    padding: 1,
    color: 'white',
    fontSize: '24px',
    textAlign: 'center', // Center the text
    whiteSpace: 'normal', // Allow text to wrap to the next line
    flex: '1 1 100%', // Allow the text to take up full width and wrap
  },
};
