import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useWeb3Connect } from '../../hook/web3/useWeb3Connect';
import palette from '../../styles/theme';
import { useAuthContext } from '../../context/AuthContext';
import { useWeb3Disconnect } from '../../hook/web3/useWeb3Disconnect';
import { useSmartAccount } from '../../hook/web3/useSmartAccount';
import Loading from '../Loading/Loading';

const ConnectButton: React.FC = () => {
  const { t } = useTranslation('translation');
  const { connect } = useWeb3Connect();
  const { isConnected, wagmiIsConnected } = useSmartAccount();
  const { authenticated } = useAuthContext();
  const { disconnect } = useWeb3Disconnect();

  useEffect(() => {
    if (isConnected && authenticated != 'authenticated') {
      connect();
    }
  }, [isConnected, authenticated, connect]);

  if (wagmiIsConnected && !isConnected) {
    return <Loading size={36} />;
  }

  return (
    <>
      {isConnected && (
        <Button
          variant="solid"
          color="neutral"
          sx={{
            color: palette.text.primary,
            minHeight: '40px',
            height: '40px',
            maxHight: '40px',
            borderRadius: 20,
            borderWidth: 0.5,
            hover: { borderColor: 'red', backgroundColor: 'transparent' },
          }}
          onClick={() => disconnect()}
        >
          <Typography color="neutral" level="body-lg">
            {t('disconnect-wallet.btn')}
          </Typography>
        </Button>
      )}
      <Button
        variant="solid"
        color="neutral"
        sx={{
          color: palette.text.primary,
          minHeight: '40px',
          height: '40px',
          maxHight: '40px',
          borderRadius: 20,
          borderWidth: 0.5,
          hover: { borderColor: 'red', backgroundColor: 'transparent' },
        }}
        onClick={() => connect()}
      >
        <Typography level="body-lg">{isConnected ? t('connect-wallet.sign') : t('connect-wallet.btn')}</Typography>
      </Button>
    </>
  );
};

export default ConnectButton;
