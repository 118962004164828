import React, { useCallback, useEffect, useMemo } from 'react';
import useLazyApi from '../../hook/api/useLazyApi';
import { Table, TournamentTablesPayload, CashTablesPayload, TournamentEvent } from '@ztp/shared';
import Loading from '../Loading/Loading';
import { IconButton, Table as MuiTable } from '@mui/joy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import palette from '../../styles/theme';
import { usePlayerContext } from '../../context/PlayerContext';
import { useModal } from '../../context/ModalContext';
import { TableContainer } from '@mui/material';

interface TableListProps {
  tournament: TournamentEvent;
  typeId?: number;
}

const TableList: React.FC<TableListProps> = ({ tournament, typeId }) => {
  const { updateState, addTourney } = usePlayerContext();
  const { close } = useModal();
  const {
    data: tournamnetTables,
    fetch: fetchTournament,
    loading: tournamentLoading,
  } = useLazyApi<Table[], TournamentTablesPayload>('POST', 'tournament/tables');
  const {
    data: cashTables,
    fetch: fetchCash,
    loading: cashLoading,
  } = useLazyApi<Table[], CashTablesPayload>('POST', 'cash-table/tables');

  const tables = useMemo(() => {
    return tournamnetTables || cashTables;
  }, [tournamnetTables, cashTables]);

  const loading = useMemo(() => {
    return cashLoading || tournamentLoading;
  }, [cashLoading, tournamentLoading]);

  const hadleViewTable = useCallback(
    (table: Table) => {
      updateState({
        table: table,
      });
      if (tournament) {
        addTourney(tournament);
      }
      close();
    },
    [updateState, addTourney, close, tournament]
  );

  useEffect(() => {
    if (tournament) {
      fetchTournament({
        tournamentId: tournament.id,
      });
    } else if (typeId) {
      fetchCash({
        typeId,
      });
    }
  }, [tournament, typeId, fetchTournament, fetchCash]);

  if (loading) {
    return <Loading size={60} />;
  }

  if (!tables) {
    return 'N/A';
  }

  return (
    <TableContainer sx={{ width: 400, maxHeight: 500, overflowY: 'auto' }}>
      <MuiTable color="neutral" variant="solid" sx={{ width: '400px', maxHeight: '500px' }}>
        <thead>
          <tr>
            <th style={{ backgroundColor: palette.neutral.solidBg, width: '65%' }}>Table ID</th>
            <th style={{ backgroundColor: palette.neutral.solidBg, width: '20%' }}>Players</th>
            <th style={{ backgroundColor: palette.neutral.solidBg, width: '15%' }}></th>
          </tr>
        </thead>
        <tbody>
          {tables.map((table) => (
            <tr key={table.id}>
              <td>{table.id}</td>
              <td style={{ textAlign: 'center' }}>{Object.keys(table.players).length}</td>
              <td>
                <IconButton
                  onClick={() => hadleViewTable(table)}
                  variant="plain"
                  sx={{
                    '&:hover': {
                      backgroundColor: palette.primary.softHoverBg,
                    },
                  }}
                >
                  <VisibilityIcon sx={{ fontSize: 22, color: 'white' }} />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </MuiTable>
    </TableContainer>
  );
};

export default TableList;
