export class FuncComposite {
  array: (() => any)[] = [];

  add(func: () => any) {
    this.array.push(func);
  }

  run() {
    this.array.forEach((f) => f());
  }
}
