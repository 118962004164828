import React from 'react';
import { CssVarsProvider, extendTheme } from '@mui/joy';
import palette from '../styles/theme';

export function ThemeProvider({ children }: any) {
  const bootstrapTheme = extendTheme({
    //@ts-expect-error add missing
    transitions: {
      duration: {
        enteringScreen: 100,
      },
    },
    colorSchemes: {
      light: { palette },
      dark: { palette },
    },
    fontFamily: {
      body: 'Orbitron',
      display: 'Orbitron',
      fallback: 'Orbitron',
    },
  });

  return <CssVarsProvider theme={bootstrapTheme}>{children}</CssVarsProvider>;
}
