import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, ButtonGroup, Typography } from '@mui/joy';
import { usePlayerContext } from '../../context/PlayerContext';
import useLazyApi from '../../hook/api/useLazyApi';
import { BetActionPayload, PokerActionType, RoundStage } from '@ztp/shared';
import palette from '../../styles/theme';
import { useTranslation } from 'react-i18next';
// import { Styles } from '../../styles/type';
import { usePlayerAction } from '../../hook/games/usePlayerAction';
import PlayerPreAction from './PlayerPreAction';
import RaiseSlider from './RaiseSlider';
import useWsSender from '../../hook/events/useWsSender';

interface PlayerActionProps {
  myTurn: boolean;
}

const PlayerActions: React.FC<PlayerActionProps> = ({ myTurn }) => {
  const { state } = usePlayerContext();
  const { t } = useTranslation('translation', { keyPrefix: 'action' });
  const [myRaise, setMyRaise] = useState<number>(0);
  const [autoAction, setAutoAction] = useState<PokerActionType | null>(null);
  const { actions, minRaise, maxRaise, canRaise, callAmount, allInAmount } = usePlayerAction(myRaise, autoAction);
  const [agreeCallAmount, setAgreeCallAmount] = useState(0);
  const { loading: apiLoading, fetch } = useLazyApi<boolean, BetActionPayload>('POST', 'bet-action/act');
  const {
    loading: wsLoading,
    send,
    ready,
  } = useWsSender<boolean, BetActionPayload>('game-round-action', 'game-round-action-ack', 'backend');
  const loading = apiLoading || wsLoading;

  const round = state.gameRound;
  const step = state.table?.type?.small || 1;

  //TODO - what about stake pot?
  const totalPotSize = useMemo(() => {
    if (!round?.pots || round.pots.length === 0) {
      return 0;
    }
    return round.pots.reduce((acc, pot) => acc + pot.amount, 0);
  }, [round?.pots]);

  const decimals = useMemo(() => {
    if (step < 0.1) {
      return 2;
    }
    if (step < 1) {
      return 1;
    }
    return 0;
  }, [step]);

  const actionColor = useMemo(() => {
    const actionColorMap = new Map<PokerActionType, string>();
    actionColorMap.set(PokerActionType.Fold, palette.text.danger);
    actionColorMap.set(PokerActionType.Check, palette.text.primary);
    actionColorMap.set(PokerActionType.Raise, palette.text.primary);
    actionColorMap.set(PokerActionType.Call, palette.text.primary);

    return actionColorMap;
  }, []);

  const handleSelectAutoAction = useCallback(
    (action: PokerActionType | null) => {
      setAutoAction(action);
      if (action === PokerActionType.Call) {
        setAgreeCallAmount(callAmount);
      }
    },
    [callAmount, setAutoAction, setAgreeCallAmount]
  );

  const handleAction = useCallback(
    (action: PokerActionType, amount?: number) => {
      const payload = {
        playerAddress: '',
        tableId: state.table?.id || '',
        actionType: action,
        amount: action == PokerActionType.Raise ? amount || 0 : 0,
      };

      if (ready) {
        send(payload);
      } else {
        fetch(payload).catch(() => {});
      }
    },
    [fetch, send, ready, state]
  );

  useEffect(() => {
    setMyRaise(minRaise);
  }, [minRaise]);

  useEffect(() => {
    if (myTurn && autoAction) {
      setAutoAction(null);
      let action = autoAction;

      if (action === PokerActionType.Call && callAmount != agreeCallAmount) {
        return;
      }
      if (action == PokerActionType.Check && callAmount > 0) {
        return;
      }

      if (action == PokerActionType.CheckOrFold) {
        if (callAmount > 0) {
          action = PokerActionType.Fold;
        } else {
          action = PokerActionType.Check;
        }
      }

      handleAction(action);
    }
  }, [autoAction, handleAction, myTurn, callAmount, agreeCallAmount]);

  if (!round) {
    return <></>;
  }

  if (!myTurn) {
    return (
      <PlayerPreAction
        agreeCallAmount={agreeCallAmount}
        autoAction={autoAction}
        handleSelectAutoAction={handleSelectAutoAction}
        myTurn={myTurn}
      />
    );
  }

  return (
    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end', width: '100%' }}>
      <ButtonGroup sx={{ flex: 1, justifySelf: 'center' }} color="primary" aria-label="outlined button group">
        {actions.map((action) => (
          <Button
            sx={{
              flex: 1,
              color: palette.text.primary,
              hover: { color: 'transparent' },
              maxHeight: '34px',
              minHeight: '34px',
              width: 'auto',
              minWidth: '90px',
              padding: '4px',
              fontWeight: '400',
              textAlign: 'center',
              justifyContent: 'center',
            }}
            disabled={loading}
            key={`player_action_${action}`}
            size="md"
            color="primary"
            onClick={() => handleAction(action, myRaise)}
          >
            <Box display={'flex'} flexDirection="column">
              <Typography sx={{ color: actionColor.get(action) }} level="body-sm">
                {`${t(action)} `}
                {action == 'raise' && (
                  <Typography sx={{ color: palette.text.secondary }} level="body-sm">
                    {myRaise.toFixed(decimals)}
                  </Typography>
                )}
                {action == 'call' && (
                  <Typography sx={{ color: palette.text.secondary }} level="body-sm">
                    {callAmount.toFixed(decimals)}
                  </Typography>
                )}
                {action == 'all-in' && (
                  <Typography sx={{ color: palette.text.secondary }} level="body-sm">
                    {allInAmount.toFixed(decimals)}
                  </Typography>
                )}
              </Typography>
            </Box>
          </Button>
        ))}
      </ButtonGroup>
      {(canRaise || myRaise === maxRaise) && (
        <Box sx={{ mt: 0, px: 0 }}>
          <RaiseSlider
            value={myRaise}
            setValue={setMyRaise}
            minRaise={minRaise}
            maxRaise={maxRaise}
            bb={state.table!.type.big}
            step={step}
            loading={loading}
            horizontal={false}
            isPreflop={round?.roundStage === RoundStage.PreFlop}
            potSize={totalPotSize}
            raise={(amount) => handleAction(PokerActionType.Raise, amount)}
            allin={() => handleAction(PokerActionType.AllIn)}
          />
        </Box>
      )}
    </Box>
  );
};

export default PlayerActions;
