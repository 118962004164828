import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { useMemo } from 'react';

export const useResponsive = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isIOS = useMemo(() => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream;
  }, []);

  return { isMobile, isIOS };
};
