import React, { useContext, useState } from 'react';
import { Snackbar } from '@mui/joy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface ISnackbarContext {
  showMessage: (msg: string, type: MsgType) => void;
}

type MsgType = 'success' | 'danger' | 'warning';

const defaultValue = {} as ISnackbarContext;

const SnackbarContext = React.createContext<ISnackbarContext>(defaultValue);
export const useSnackbar = () => useContext(SnackbarContext);

export function SnackbarContextProvider({ children }: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<MsgType>('success');
  const [msg, setMsg] = useState<string>('');

  const showMessage = (msg: string, type: MsgType) => {
    setMsg(msg);
    setOpen(true);
    setType(type);
  };

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      <>
        <Snackbar
          variant="soft"
          color={type}
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          startDecorator={<CheckCircleIcon />}
        >
          {msg}
        </Snackbar>
        {children}
      </>
    </SnackbarContext.Provider>
  );
}
