import React, { FC, ReactNode } from 'react';
import { useResponsive } from '../../hook/helper/useResponsive';
import SliderMobile from './SliderMobile';
import SliderDesktop from './SliderDesktop';

export interface SliderProps {
  children: ReactNode;
}

const Slider: FC<SliderProps> = (props) => {
  const { isMobile } = useResponsive();

  return isMobile ? <SliderMobile {...props} /> : <SliderDesktop {...props} />;
};

export default Slider;
