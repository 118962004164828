import React from 'react';

export interface SuitProps {
  suit: number;
}

const Suit: React.FC<SuitProps> = ({ suit }) => {
  switch (suit) {
    case 0: //clube
      return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 240 260">
          <g fill="#000" fillOpacity="1" stroke="none" strokeOpacity="1" transform="translate(-288.156 -348.063)">
            <path
              strokeDasharray="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="4"
              strokeWidth="3"
              d="M408.156 351.672c-31.188 0-56.5 25.312-56.5 56.5 0 17.83 8.376 33.65 21.22 44.094 4.335 3.526 2.478 7.354-1.938 4.968-8.027-4.335-17.094-7.093-26.875-7.093-30.084 0-54.5 24.416-54.5 54.5s24.416 54.5 54.5 54.5c20.052 0 37.604-10.835 47.03-27 .922-1.58 1.663-3.75 2.97-3.75 1.787 0 2.625 2.6 2.625 7.468 0 19.539-8.449 42.365-20.938 68.594 5.393-1.136 21.733-4.031 32.406-4.031 10.674 0 27.013 2.895 32.407 4.031-12.49-26.229-20.938-49.055-20.938-68.594 0-4.868.838-7.468 2.625-7.468 1.307 0 2.048 2.17 2.969 3.75 9.427 16.165 26.978 27 47.031 27 30.084 0 54.5-24.416 54.5-54.5s-24.416-54.5-54.5-54.5c-9.781 0-18.848 2.758-26.875 7.093-4.417 2.386-6.274-1.442-1.937-4.968 12.843-10.444 21.218-26.263 21.218-44.094 0-31.188-25.312-56.5-56.5-56.5z"
              opacity="1"
            ></path>
          </g>
        </svg>
      );
    case 1: //diamond
      return (
        <svg viewBox="0 0 240 260" xmlns="http://www.w3.org/2000/svg" version="1">
          <g transform="translate(-288.156 -348.063)">
            <path
              fill="red"
              fillOpacity="1"
              stroke="none"
              strokeDasharray="none"
              strokeLinecap="square"
              strokeLinejoin="round"
              strokeMiterlimit="3.999"
              strokeOpacity="1"
              strokeWidth="0.5"
              d="M408.156 352.451c-22.387 47.762-55.395 89.812-100 125.611 44.605 35.8 77.613 77.85 100 125.612 22.388-47.762 55.396-89.812 100-125.611-44.604-35.8-77.612-77.85-100-125.612z"
            ></path>
          </g>
        </svg>
      );
    case 2: //heart
      return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 200 200">
          <linearGradient gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#F26A61"></stop>
            <stop offset="0.1" stopColor="#EB6158"></stop>
            <stop offset="0.5" stopColor="#DC4239"></stop>
            <stop offset="0.8" stopColor="#D32B2B"></stop>
            <stop offset="1" stopColor="#D02027"></stop>
          </linearGradient>
          <path
            fill="red"
            fillOpacity="1"
            stroke="none"
            strokeLinejoin="round"
            strokeWidth="2.273"
            d="M52.502 0C23.521 0 0 25.4 0 56.696 0 120.364 59.473 137.056 100 200c38.315-62.556 100-81.668 100-143.304C200 25.4 176.48 0 147.498 0 126.476 0 108.383 13.404 100 32.689 91.617 13.404 73.524 0 52.502 0z"
          ></path>
        </svg>
      );
    case 3: //spade
      return (
        <svg xmlns="http://www.w3.org/2000/svg" baseProfile="full" viewBox="68.547 122.681 537.423 635.165">
          <path
            fillRule="evenodd"
            stroke="#000"
            strokeWidth="1.825"
            d="M172.533 348.49c-195.31 199.54-5.353 344.87 149.07 249.6.841 49.146-37.692 95.028-61.394 138.9h166.73c-24.41-42.64-65.17-89.61-66.66-138.9 157.66 90.57 325.33-67.37 150.39-249.6-91.22-100.08-148.24-177.95-169.73-204.42-19.602 25.809-71.82 101.7-168.41 204.42z"
          ></path>
        </svg>
      );
  }

  return <></>;
};

export default Suit;
