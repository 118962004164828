import { useCallback } from 'react';
import { useDisconnect } from 'wagmi';
import useLazyApi from '../api/useLazyApi';
import { useAuthContext } from '../../context/AuthContext';

export const useWeb3Disconnect = () => {
  const { fetch } = useLazyApi<boolean, any>('POST', 'auth/sign-out');
  const { disconnect: wagmiDisconnect } = useDisconnect();
  const { clearAuth } = useAuthContext();

  const disconnect = useCallback(async () => {
    await fetch().catch(() => {});
    wagmiDisconnect();
    clearAuth();
  }, [wagmiDisconnect, clearAuth, fetch]);

  return { disconnect };
};
