"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNextEvent = getNextEvent;
exports.getNextDateOfWeekday = getNextDateOfWeekday;
exports.eventOpenTime = eventOpenTime;
exports.eventStatTime = eventStatTime;
exports.eventCloseTime = eventCloseTime;
function getNextEvent(events) {
    const now = new Date();
    now.setUTCSeconds(0, 0); // Ignore seconds and milliseconds
    let closestEvent = events[0];
    let closestEventDate;
    events.forEach((event) => {
        const eventDate = eventStatTime(event);
        if (eventDate >= now && (!closestEventDate || eventDate < closestEventDate)) {
            closestEvent = event;
            closestEventDate = eventDate;
        }
    });
    return closestEvent;
}
function getNextDateOfWeekday(weekday, hour, minute) {
    const today = new Date();
    const currentDay = today.getUTCDay();
    let diff = (weekday + 7 - currentDay) % 7;
    today.setUTCDate(today.getUTCDate() + diff);
    today.setUTCHours(hour, minute, 0, 0);
    return today;
}
function eventOpenTime(event) {
    const [hours, minutes] = event.openTime.split(':').map(Number);
    const eventDate = getNextDateOfWeekday(event.day, hours, minutes);
    return eventDate;
}
function eventStatTime(event) {
    const [hours, minutes] = event.startTime.split(':').map(Number);
    const eventDate = getNextDateOfWeekday(event.day, hours, minutes);
    return eventDate;
}
function eventCloseTime(event) {
    const [hours, minutes] = event.closeTime.split(':').map(Number);
    const eventDate = getNextDateOfWeekday(event.day, hours, minutes);
    return eventDate;
}
