import React from 'react';
import EthIcon from '../../assets/coins/eth.svg';
import ArbhIcon from '../../assets/coins/arb.svg';
import MaticIcon from '../../assets/coins/matic.svg';

interface NetworkIconProps {
  chainId: number;
}

interface index {
  [key: string]: any;
}

const chainMap: index = {
  1: EthIcon,
  42161: ArbhIcon,
  137: MaticIcon,
};

export const NetworkIcon: React.FC<NetworkIconProps> = ({ chainId }) => {
  return <img src={chainMap[chainId]} style={{ width: '3vh', height: '3vh' }} />;
};
