import React from 'react';
import { Styles } from '../../styles/type';
import logo from '../../assets/logo.png';
import { Box } from '@mui/joy';

// If you're using CSS-in-JS libraries like styled-components, you can define your styles there instead
const Card = () => {
  return (
    <Box sx={styles.card}>
      <svg width="100%" height="100%" viewBox="0 0 200 280" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="redRhombusPattern" width="56" height="76" patternUnits="userSpaceOnUse">
            <polygon points="15,0 30,15 15,30 0,15" fill="#0b2c56" />
          </pattern>
        </defs>
        <rect x="0" y="0" width="200" height="280" fill="url(#redRhombusPattern)" />

        <circle cx={100} cy={115} r="74" fill="white" />

        <foreignObject x={30} y={58} width="140" height="140">
          <img src={logo} style={{ width: '100%', height: '100%' }} />
        </foreignObject>
      </svg>
    </Box>
  );
};

const styles: Styles = {
  card: {
    width: '100%',
    backgroundColor: 'white',
    border: 'solid',
    borderRadius: '10px',
    borderWidth: '3px',
    borderColor: '#0b2c56',
    paddingTop: '10px',
    display: 'flex', // this would be equivalent to the default in React Native
    flexDirection: 'column' as const, // default flexDirection in web is row, in React Native is column
  },
};

export default Card;
