import { useCallback, useMemo } from 'react';
import { Player, RevealCard, BackupCardsPayload, PlayerCardPart, CardsDeck } from '@ztp/shared';
import { useAuthContext } from '../../context/AuthContext';
import { useP2PEventContext } from '../../context/P2PEventContext';
import useMental from '../cryptography/useMental';
import { usePlayerContext } from '../../context/PlayerContext';
import useRevealMyCards from './useRevealMyCards';

interface CardsState {
  roundId: string;
  serverPublicKey: string;
  cards: RevealCard[];
  minShares: number;
  totalShares: number;
}

interface PlayerCardParts {
  parts: PlayerCardPart[];
}

export const useBackupCards = () => {
  const { reveal } = useRevealMyCards();
  const { player } = useAuthContext();
  const {
    state: { table },
  } = usePlayerContext();
  const { emit: emitP2P, ready: readyP2P } = useP2PEventContext();
  const { shareKey, calculateShares } = useMental();

  const myAdress = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const sharedParts = useCallback(
    async (state: CardsState) => {
      const playerCardParts: PlayerCardParts[] = [];
      for (let x = 0; x < state.totalShares; x++) {
        playerCardParts.push({
          parts: [],
        });
      }

      for (const rc of state.cards) {
        for (let keyIndex = 0; keyIndex < rc.keys.length; keyIndex++) {
          const parts = await shareKey(rc.keys[keyIndex], state.serverPublicKey, state.totalShares, state.minShares);

          for (const partIndex in parts) {
            playerCardParts[partIndex].parts.push({
              cardIndex: rc.index,
              index: keyIndex,
              part: parts[partIndex],
              minShares: state.minShares,
            });
          }
        }
      }

      return playerCardParts;
    },
    [shareKey]
  );

  const availablePlayers = useCallback(() => {
    if (!table) {
      return;
    }
    const players = Object.values(table.players)
      .filter((p) => p.address != myAdress)
      .map((p) => p.player);

    return players;
  }, [table, myAdress]);

  const sendBackupP2P = useCallback(
    async (parts: PlayerCardPart[], dstPlayer: Player, state: CardsState) => {
      if (!readyP2P || !emitP2P) {
        return;
      }
      emitP2P<BackupCardsPayload>('backup-cards', dstPlayer.encryptionPublicKey, dstPlayer.id, {
        cardsParts: parts,
        roundId: state.roundId,
      });
    },
    [readyP2P, emitP2P]
  );

  const backupCards = useCallback(
    async (deck: CardsDeck, cards: RevealCard[]) => {
      if (!table) {
        return;
      }

      const { totalShares, minShares } = calculateShares(Object.values(table.players).length || 1);
      const state: CardsState = {
        serverPublicKey: deck.serverPublicKey,
        roundId: deck.roundId,
        cards,
        totalShares,
        minShares,
      };

      const parts = await sharedParts(state);
      const players = availablePlayers();
      if (!players || !parts) {
        return;
      }

      for (const player of players) {
        const index = players.indexOf(player);
        const part = parts[index];
        sendBackupP2P(part.parts, player, state);
      }
    },
    [calculateShares, reveal, sharedParts, sendBackupP2P, availablePlayers, table]
  );

  return { backupCards };
};
