import React from 'react';
import Text from '../Text/Text';
import { Box } from '@mui/joy';
import { Styles } from '../../styles/type';

interface DealerPositionProps {
  visable: boolean;
}

const DealerPosition: React.FC<DealerPositionProps> = ({ visable }) => {
  if (!visable) {
    return <></>;
  }

  return (
    <Box style={styles.dealerBackround}>
      <Text style={styles.text}>{'D'}</Text>
    </Box>
  );
};

const styles: Styles = {
  dealerBackround: {
    backgroundColor: 'gold',
    borderRadius: '50%',
    border: 'solid',
    borderWidth: '0px 0px 5px 0px',
    borderColor: '#e5c100',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '1px 1px 5px 0px gold',
  },
  text: {
    fontFamily: 'Audiowide',
    fontSize: '30px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#f8f9fa',
  },
};

export default DealerPosition;
