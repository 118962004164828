const palette = {
  primary: {
    solidBg: '#161717',
    solidBorder: '#2f2f2f',
    solidHoverBg: '#242424',
    solidHoverBorder: '#0b2c56',
    solidActiveBg: '#0b2c56',
    solidActiveBorder: '#0b2c56',
    solidDisabledBg: '#0b2c56',
    solidDisabledBorder: '#0b2c56',
    plainColor: '#0b2c56',
    plainHoverBg: '#0b2c56',
    plainActiveBg: '#0b2c56',
    plainDisabledColor: '#0b2c56',
    outlinedColor: '#0b2c56',
    outlinedBorder: '#0b2c56',
    outlinedHoverBg: '#0b2c56',
    outlinedHoverBorder: '#0b2c56',
    outlinedActiveBg: '#0b2c56',
    outlinedDisabledColor: '#0b2c56',
    outlinedDisabledBorder: '#0b2c56',
    softColor: '#234166',
    softBg: '#0b2c56',
    softHoverBg: '#0b2c56',
    softActiveBg: '#0b2c56',
    softDisabledColor: '#0b2c56',
    softDisabledBg: '#0b2c56',
    solidColor: '#0b2c56',
  },
  text: {
    primary: '#f8f9fa',
    litePrimary: '#E1E1E1',
    secondary: 'gold',
    tertiary: 'white',
    danger: '#dc3545',
    dark: 'black',
    success: '#198754',
  },
  neutral: {
    solidBg: '#161717',
    solidBorder: '#2f2f2f',
    solidHoverBg: '#242424',
    solidHoverBorder: '#2f2f2f',
    solidActiveBg: '#233649',
    solidActiveBorder: '#242424',
    solidDisabledBg: '#6c757d',
    solidDisabledBorder: '#6c757d',
    // btn-light
    softColor: '#000',
    softBg: '#f8f9fa',
    softBorder: '#f8f9fa',
    softHoverBg: '#f9fafb',
    softHoverBorder: '#f9fafb',
    softActiveBg: '#f9fafb',
    softActiveBorder: '#f9fafb',
    softDisabledBg: '#f8f9fa',
    softDisabledBorder: '#f8f9fa',
    800: 'white',
  },
  success: {
    solidBg: '#198754',
    solidBorder: '#198754',
    solidHoverBg: '#157347',
    solidHoverBorder: '#146c43',
    solidActiveBg: '#146c43',
    solidActiveBorder: '#13653f',
    solidDisabledBg: '#198754',
    solidDisabledBorder: '#198754',
  },
  danger: {
    solidBg: '#dc3545',
    solidBorder: '#dc3545',
    solidHoverBg: '#bb2d3b',
    solidHoverBorder: '#b02a37',
    solidActiveBg: '#b02a37',
    solidActiveBorder: '#a52834',
    solidDisabledBg: '#dc3545',
    solidDisabledBorder: '#dc3545',
  },
  warning: {
    solidColor: '#000',
    solidBg: 'gold',
    solidBorder: '#ffc107',
    solidHoverBg: '#ffca2c',
    solidHoverBorder: '#ffc720',
    solidActiveBg: '#ffcd39',
    solidActiveBorder: '#ffc720',
    solidDisabledBg: '#ffc107',
    solidDisabledBorder: '#ffc107',
  },
  info: {
    solidColor: '#000',
    solidBg: '#6c757d',
    solidBorder: '#0dcaf0',
    solidHoverBg: '#31d2f2',
    solidHoverBorder: '#25cff2',
    solidActiveBg: '#3dd5f3',
    solidActiveBorder: '#25cff2',
    solidDisabledBg: '#0dcaf0',
    solidDisabledBorder: '#0dcaf0',
  },
};

export default palette;
