import { useEffect, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const loadLocaleData = (locale: string) => {
  switch (locale) {
    case 'en':
    default:
      return import('../../i18n/translations/en.json');
  }
};

export const useI18nLoader = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init(
        {
          fallbackLng: 'en',
          lng: 'en',
          interpolation: {
            escapeValue: false,
          },
        },
        (err, t) => {
          if (err) return console.error(err);
          t('key');
        }
      );

    i18n.on('languageChanged', (lng) => {
      loadLocaleData(lng).then((resources) => {
        console.debug('Loaded: ', lng, ' ', resources);
        i18n.addResourceBundle(lng, 'translation', resources.default, true, true);

        setInitialized(true);
      });
    });
  }, []);

  return { initialized };
};
