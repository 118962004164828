import useSound from 'use-sound';
import { useDebouncedCallback } from 'use-debounce';
import playerTurnSound from '../../assets/sound/playerTurn.mp3';
import dealOneCardSound from '../../assets/sound/dealOneCard.mp3';
import dealTwoCardsSound from '../../assets/sound/dealTwoCards.mp3';
import dealThreeCardsSound from '../../assets/sound/dealThreeCards.mp3';
import winnerSound from '../../assets/sound/winner.mp3';
import checkSound from '../../assets/sound/check.mp3';
import raiseOrCallSound from '../../assets/sound/raiseOrCall.wav';
import allinSound from '../../assets/sound/allin.wav';
import foldSound from '../../assets/sound/fold.mp3';
import { usePlayerContext } from '../../context/PlayerContext';

export type SoundEffect =
  | 'player-turn'
  | 'dealing-player-cards'
  | 'winner'
  | 'dealing-flop'
  | 'dealing-turn'
  | 'dealing-river'
  | 'check'
  | 'raise'
  | 'call'
  | 'allin'
  | 'fold';

export const useSoundEffect = () => {
  const { settings } = usePlayerContext(); // Fetch settings from context
  const adjustedVolume = settings.soundEnabled ? settings.volume / 100 : 0; // Adjust volume based on enabled state and value

  const [playPlayerTurnSound] = useSound(playerTurnSound, { volume: adjustedVolume });
  const [playDealingOneCard] = useSound(dealOneCardSound, { volume: adjustedVolume });
  const [playDealingTwoCards] = useSound(dealTwoCardsSound, { volume: adjustedVolume });
  const [playDealingThreeCards] = useSound(dealThreeCardsSound, { volume: adjustedVolume, playbackRate: 1 });
  const [playWinnerSound] = useSound(winnerSound, { volume: adjustedVolume });
  const [playCheckSound] = useSound(checkSound, { volume: adjustedVolume / 2 });
  const [playRaiseOrCallSound] = useSound(raiseOrCallSound, { volume: adjustedVolume });
  const [playAllinSound] = useSound(allinSound, { volume: adjustedVolume });
  const [playFoldSound] = useSound(foldSound, { volume: adjustedVolume });

  const play = (sound: SoundEffect) => {
    switch (sound) {
      case 'player-turn':
        playPlayerTurnSound();
        break;
      case 'dealing-player-cards':
        playDealingTwoCards();
        break;
      case 'dealing-flop':
        playDealingThreeCards();
        break;
      case 'dealing-turn':
      case 'dealing-river':
        playDealingOneCard();
        break;
      case 'winner':
        playWinnerSound();
        break;
      case 'check':
        playCheckSound();
        break;
      case 'raise':
        playRaiseOrCallSound();
        break;
      case 'call':
        playRaiseOrCallSound();
        break;
      case 'allin':
        playAllinSound();
        break;
      case 'fold':
        playFoldSound();
        break;
    }
  };

  const playDebounced = useDebouncedCallback(play, 100);

  return { play: playDebounced };
};
