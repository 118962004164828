import { useEffect, useState } from 'react';
import { usePlayerContext } from '../../context/PlayerContext';
import { RoundStage, PotWinners } from '@ztp/shared';

const DELAY_PER_POT = 4 * 1000; //4 sec

export const useEndGamePresenter = () => {
  const { state } = usePlayerContext();
  const [lock, setLock] = useState('');
  const [potWinners, setPotWinners] = useState<PotWinners>();
  const round = state.gameRound;

  useEffect(() => {
    if (round && round.id !== lock) {
      setPotWinners(undefined);
    }
  }, [round, lock]);

  useEffect(() => {
    if (!round || round.roundStage !== RoundStage.Presentation) {
      return;
    }

    if (round.id == lock) {
      return;
    }

    let actualPots = round.pots;
    if (round.pots.length > 1) {
      actualPots = round.pots.filter((p) => p.eligiblePlayers.length > 1);
    }

    if (actualPots.length == 0 || !actualPots[0].winners) {
      return;
    }

    setLock(round.id);
    setTimeout(() => {
      setPotWinners({
        potIndex: 0,
        winners: actualPots[0].winners,
        eligiblePlayers: actualPots[0].eligiblePlayers,
      });

      if (actualPots.length > 1) {
        let x = 0;
        const interval = setInterval(() => {
          x++;
          if (x > actualPots.length - 1) {
            clearInterval(interval);
            setTimeout(() => {
              setPotWinners(undefined);
            }, 2000);
            return;
          }

          setPotWinners({
            potIndex: x,
            winners: actualPots[x].winners,
            eligiblePlayers: actualPots[x].eligiblePlayers,
          });
        }, DELAY_PER_POT);
      }
    }, 500);
  }, [round, lock]);

  return { potWinners };
};
