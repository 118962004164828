import { ChainNotConfiguredError, createConnector } from '@wagmi/core';
import type { IProvider } from '@web3auth/base';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK, UX_MODE, ADAPTER_STATUS, WALLET_ADAPTERS } from '@web3auth/base';
import { AuthAdapter } from '@web3auth/auth-adapter';
import { Chain, getAddress, SwitchChainError, UserRejectedRequestError } from 'viem';

export function Web3AuthConnector(chains: Chain[]) {
  let walletProvider: IProvider | null = null;

  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x' + chains[0].id.toString(16),
    rpcTarget: chains[0].rpcUrls.default.http[0], // This is the public RPC we have added, please pass on your own endpoint while creating an app
    displayName: chains[0].name,
    tickerName: chains[0].nativeCurrency?.name,
    ticker: chains[0].nativeCurrency?.symbol,
    blockExplorerUrl: chains[0].blockExplorers?.default.url[0] as string,
  };

  const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });

  const web3AuthInstance = new Web3AuthNoModal({
    clientId: 'BKpzZhz0LIh-phmIXjaXDiDO7GrElwanQnwYTXpCo03R2NYTVbZhUCN75hdMltpqZgaxsVhkOa3fMwDVAmEivuA',
    chainConfig,
    privateKeyProvider,
    // uiConfig: {
    //   appName: name,
    //   defaultLanguage: 'en',
    //   logoLight: 'https://web3auth.io/images/web3authlog.png',
    //   logoDark: 'https://web3auth.io/images/web3authlogodark.png',
    //   mode: 'light',
    // },
    web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
    enableLogging: true,
  });

  const authAdapter = new AuthAdapter({
    adapterSettings: {
      uxMode: UX_MODE.POPUP,
    },
  });

  web3AuthInstance.configureAdapter(authAdapter);

  return createConnector<IProvider>((config) => ({
    id: 'ztpoker',
    name: 'Zero Trust Poker',
    type: 'Web3Auth',

    async connect(params = {}) {
      const loginParams = params.chainId as any;
      try {
        config.emitter.emit('message', {
          type: 'connecting',
        });
        const provider = await this.getProvider();

        provider.on('accountsChanged', this.onAccountsChanged);
        provider.on('chainChanged', this.onChainChanged);
        provider.on('disconnect', this.onDisconnect.bind(this));

        if (!web3AuthInstance.connected) {
          if (loginParams) {
            await web3AuthInstance.connectTo(WALLET_ADAPTERS.AUTH, loginParams);
          }
        }

        const currentChainId = await this.getChainId();
        const accounts = await this.getAccounts();

        return { accounts, chainId: currentChainId };
      } catch (error) {
        this.onDisconnect();
        throw new UserRejectedRequestError('Something went wrong' as unknown as Error);
      }
    },
    async getAccounts() {
      const provider = await this.getProvider();
      if (provider) {
        const acc = await provider.request<unknown, string[]>({
          method: 'eth_accounts',
        });
        if (acc) {
          return acc.map((x) => getAddress(x || ''));
        }
      }
      return [];
    },
    async getChainId() {
      const provider = await this.getProvider();
      const chainId = await provider.request<unknown, number>({ method: 'eth_chainId' });
      return Number(chainId);
    },
    async getProvider(): Promise<IProvider> {
      if (walletProvider) {
        return walletProvider;
      }
      if (web3AuthInstance.status === ADAPTER_STATUS.NOT_READY) {
        await web3AuthInstance.init();
      }

      walletProvider = web3AuthInstance.provider;
      return walletProvider || ({} as IProvider);
    },
    async isAuthorized() {
      try {
        const accounts = await this.getAccounts();
        return !!accounts.length;
      } catch {
        return false;
      }
    },
    async switchChain({ chainId }): Promise<Chain> {
      try {
        const chain = config.chains.find((x) => x.id === chainId);
        if (!chain) throw new SwitchChainError(new ChainNotConfiguredError());

        await web3AuthInstance.addChain({
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: `0x${chain.id.toString(16)}`,
          rpcTarget: chain.rpcUrls.default.http[0],
          displayName: chain.name,
          blockExplorerUrl: chain.blockExplorers?.default.url || '',
          ticker: chain.nativeCurrency?.symbol || 'ETH',
          tickerName: chain.nativeCurrency?.name || 'Ethereum',
          decimals: chain.nativeCurrency?.decimals || 18,
          logo: chain.nativeCurrency?.symbol
            ? `https://images.toruswallet.io/${chain.nativeCurrency?.symbol.toLowerCase()}.svg`
            : 'https://images.toruswallet.io/eth.svg',
        });
        await web3AuthInstance.switchChain({ chainId: `0x${chain.id.toString(16)}` });
        config.emitter.emit('change', {
          chainId,
        });
        return chain;
      } catch (error: unknown) {
        throw new SwitchChainError(error as Error);
      }
    },
    async disconnect(): Promise<void> {
      await web3AuthInstance.logout();
      const provider = await this.getProvider();
      provider.removeListener('accountsChanged', this.onAccountsChanged);
      provider.removeListener('chainChanged', this.onChainChanged);
    },
    onAccountsChanged(accounts) {
      if (accounts.length === 0) config.emitter.emit('disconnect');
      else
        config.emitter.emit('change', {
          accounts: accounts.map((x) => getAddress(x)),
        });
    },
    onChainChanged(chain) {
      const chainId = Number(chain);
      config.emitter.emit('change', { chainId });
    },
    onDisconnect(): void {
      config.emitter.emit('disconnect');
    },
  }));
}
