import { useCallback } from 'react';
import { useSignMessage } from 'wagmi';
import { useSearchParams } from 'react-router-dom';
import useCryptoApi from '../cryptography/useCryptoApi';
import { useAuthContext } from '../../context/AuthContext';
import useLazyApi from '../api/useLazyApi';
import { ChallengePlayload, Player, SignInPlayload } from '@ztp/shared';
import ReactGA from 'react-ga4';
import { useModal } from '../../context/ModalContext';
import Connect from '../../components/Auth/Connect';
import { useSmartAccount } from './useSmartAccount';

export const useWeb3Connect = () => {
  const { ownerAddress, address, isConnected, wagmiIsConnected } = useSmartAccount();
  const { setAuth, setKeyPairs, keyPairs } = useAuthContext();
  const { fetch: signInApi } = useLazyApi<Player, SignInPlayload>('POST', 'auth/sign-in');
  const { fetch: createChallenge } = useLazyApi<string, ChallengePlayload>('POST', 'auth/challenge');
  const { signMessageAsync } = useSignMessage();
  const { generateKeyPairs } = useCryptoApi();
  const [searchParams] = useSearchParams();
  const { showModal } = useModal();

  const getKeys = useCallback(async () => {
    if (keyPairs) {
      const { encryptionKeyPair, signingKeyPair } = keyPairs;
      return { encryptionKeyPair, signingKeyPair };
    }
    return await generateKeyPairs();
  }, [keyPairs, generateKeyPairs]);

  const signIn = useCallback(async () => {
    if (!ownerAddress) {
      return;
    }

    ReactGA.event({
      category: 'Button',
      action: 'sign-in',
      label: 'click',
    });

    try {
      const keys = await getKeys();
      if (!keys.encryptionKeyPair || !keys.signingKeyPair) {
        console.error('failed to generate key pair');
        return;
      }

      const { encryptionKeyPair, signingKeyPair } = keys;

      const challengeMessage = await createChallenge({ playerAddress: ownerAddress });
      if (!challengeMessage) {
        console.error('failed to fetch challenge message');
        return;
      }

      const message = {
        encryptionPublicKey: encryptionKeyPair.publicKey,
        challengeMessage,
      };

      const signedMessage = await signMessageAsync({
        message: JSON.stringify(message),
      });

      const referral = searchParams.get('ref');

      const player = await signInApi({
        playerAddress: ownerAddress,
        smartAccount: address,
        encryptionPublicKey: keys.encryptionKeyPair.publicKey,
        signedMessage,
        referral: referral || undefined,
      }).catch(() => {});

      if (player) {
        setKeyPairs({ encryptionKeyPair, signingKeyPair, challengeMessage, signedMessage });
        setAuth(player);
        console.info('Login suceessfully');
      }

      ReactGA.event({
        category: 'Button',
        action: 'sign-in',
        label: 'success',
      });
    } catch (error) {
      console.error('failed to sign-in err: ', error);
    }
  }, [
    signInApi,
    signMessageAsync,
    getKeys,
    createChallenge,
    setKeyPairs,
    setAuth,
    searchParams,
    ownerAddress,
    address,
  ]);

  const connect = useCallback(async () => {
    if (isConnected) {
      signIn();
    } else if (!wagmiIsConnected) {
      showModal('Connect', Connect, {});
    }
  }, [isConnected, wagmiIsConnected, signIn, showModal]);

  return { connect };
};
