import React from 'react';

interface CrownProps {
  visable: boolean;
}

const Crown: React.FC<CrownProps> = ({ visable }) => {
  if (!visable) {
    return <></>;
  }

  return (
    <svg
      width="100%"
      height="100%"
      x="0px"
      y="0px"
      viewBox="0 0 864 864"
      // style={{enableNackground:new 0 0 864 864}}
    >
      <path
        fill="gold"
        d="M37.7,391.9c4.4,0,8.4-1.4,11.6-3.8c23,27.3,110.3,132.3,110.3,132.3c0,0,11.6,72.1,17,108.5
	c71.4-53.7,160.1-85.5,256.2-85.5c96.2,0,184.9,31.8,256.2,85.5c5.4-36.4,17-108.5,17-108.5s87.3-105,110.3-132.3
	c3.3,2.4,7.3,3.8,11.6,3.8c11.1,0,20-8.9,20-20c0-11-8.9-20-20-20c-11,0-19.9,8.9-19.9,20c0,4.8,1.7,9.1,4.5,12.5
	c-90.8,62-143,95.9-146.5,94.8c-6.5-2,38.1-137.5,47.1-157.1c2.8,1.5,5.9,2.4,9.3,2.4c11,0,20-9,20-20c0-11-9-20-20-20
	c-11,0-20,9-20,20c0,5.8,2.5,10.9,6.4,14.6c-27,32-128.2,152.3-133,112.8c-0.1-2.8-0.1-5.7-0.1-8.7c0-28.9,1.4-63.4,2.9-91
	c0-0.3,0-1.2,0.1-2.1v-0.1c0.7-13.7,1.4-25.5,2-34.1c0.3,0,0.5,0.1,0.8,0.1c11,0,19.9-8.9,19.9-20c0-11-8.9-19.9-19.9-19.9
	c-11,0-20,8.9-20,19.9c0,8.4,5.2,15.5,12.5,18.5l-17,44.4c-0.1,0-1,2.4-1.1,2.8c0,0-40.1,90.6-58,89.3
	c-16.9-2-53.4-183.2-60.8-224.3c8.6-2.2,14.9-10,14.9-19.2c0-11-8.9-20-19.9-20c-11,0-19.9,8.9-19.9,20c0,9.7,7,17.8,16.2,19.6
	c-7.5,41.6-43.9,221.9-60.7,224c-17.8,1.3-58-89.3-58-89.3c-0.1-0.4-1-2.8-1.1-2.8l-17-44.4c7.3-3,12.4-10.1,12.4-18.5
	c0-11-8.9-19.9-20-19.9c-11,0-20,8.9-20,19.9c0,11.1,8.9,20,20,20c0.3,0,0.5-0.1,0.8-0.1c0.6,8.6,1.3,20.4,2,34.1v0.1
	c0.1,0.9,0.1,1.8,0.1,2.1c1.5,27.7,2.9,62.1,2.9,91c0,3,0,5.9-0.1,8.7c-4.8,39.5-106-80.8-133-112.8c3.9-3.6,6.4-8.8,6.4-14.6
	c0-11-8.9-20-20-20c-11,0-20,9-20,20c0,11,8.9,20,20,20c3.4,0,6.5-0.9,9.3-2.4c9,19.6,53.6,155.1,47.1,157.1
	c-3.5,1.1-55.8-32.9-146.5-94.8c2.8-3.4,4.5-7.8,4.5-12.5c0-11-8.9-20-20-20c-11,0-20,8.9-20,20C17.7,383,26.6,391.9,37.7,391.9z"
      />
    </svg>
  );
};

export default Crown;
