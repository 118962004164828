import { useEffect, useMemo, useState } from 'react';
import { usePlayerContext } from '../../context/PlayerContext';
import { PokerActionType } from '@ztp/shared';

export const usePlayerLastAction = () => {
  const { state } = usePlayerContext();
  const [lastPlayer, setLastPlayer] = useState('');
  const [action, setAction] = useState<PokerActionType>();

  const lastAction = useMemo(() => {
    if (state.gameRound?.actionHistory && state.gameRound?.actionHistory.length > 0) {
      const lastPlayerAction = state.gameRound?.actionHistory[state.gameRound.actionHistory.length - 1];
      if (
        !lastPlayerAction ||
        lastPlayerAction.action == PokerActionType.Blind ||
        lastPlayerAction.playerAddress == lastPlayer
      ) {
        return null;
      }
      return lastPlayerAction;
    }
    return null;
  }, [state, lastPlayer]);

  useEffect(() => {
    if (lastAction) {
      setLastPlayer(lastAction.playerAddress);
      setAction(lastAction.action);
    }
  }, [lastAction]);

  return { action, lastPlayer };
};
