import React from 'react';
import { Button } from '@mui/joy';
import palette from '../../styles/theme';
import { useModal } from '../../context/ModalContext';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import Recive from './Recive';
import { useResponsive } from '../../hook/helper/useResponsive';

const ReciveButton: React.FC = () => {
  // const { t } = useTranslation('translation');
  const { showModal } = useModal();
  const { isMobile } = useResponsive();

  return (
    <Button
      variant="solid"
      color="success"
      size="lg"
      sx={{
        color: palette.text.primary,
        minHeight: '40px',
        minWidth: isMobile ? '0px' : 'auto',
        width: isMobile ? '0px' : 'auto',
        height: '40px',
        maxHight: '40px',
        borderRadius: isMobile ? 50 : 20,
        borderWidth: 0.5,
        hover: { borderColor: 'red', backgroundColor: 'transparent' },
      }}
      onClick={() => showModal('Deposit', Recive, {})}
    >
      {!isMobile && 'Deposit'}
      <CallReceivedIcon sx={{ marginLeft: isMobile ? '0px' : '4px' }} />
    </Button>
  );
};

export default ReciveButton;
