import React, { useContext, useState } from 'react';
import { WagmiProvider, createConfig, http } from 'wagmi';
import { arbitrum } from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Token } from '../hook/web3/type';
import { coinbaseWallet, walletConnect, metaMask } from 'wagmi/connectors';
import { Web3AuthConnector } from '../components/Auth/Web3AuthConnector';

const queryClient = new QueryClient();
const projectId = 'fbf88f89399319ea99470d2a34c5da08';
const metadata = {
  name: 'ZTPoker',
  description: 'Zero Trust Poker',
  url: 'https://play.ztpoker.com', // origin must match your domain & subdomain
  icons: ['https://play.ztpoker.com/static/media/logo.9d1e2241a9d47fe468ef.png'],
};

const chains = [arbitrum];

const config = createConfig({
  //@ts-expect-error is ok
  chains, // required
  metadata, // required
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: true, // Optional - true by default
  pollingInterval: 10000,
  transports: {
    [arbitrum.id]: http(),
  },
  connectors: [
    Web3AuthConnector(chains),
    coinbaseWallet(),
    walletConnect({
      projectId,
      qrModalOptions: {
        themeMode: 'dark',
      },
      showQrModal: true,
    }),
    metaMask(),
  ],
});

interface IWagmiContext {
  token?: Token;
  setToken: (token: Token | undefined) => void;
}

const defaultValue = {
  // eslint-disable-next-line
  setToken: (token: Token | undefined) => {},
} as IWagmiContext;

const WagmiContext = React.createContext<IWagmiContext>(defaultValue);
export const useWagmiContext = () => useContext(WagmiContext);

export function WagmiContextProvider({ children }: any) {
  const [token, setToken] = useState<Token>();

  return (
    <WagmiContext.Provider value={{ setToken, token }}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
      </WagmiProvider>
    </WagmiContext.Provider>
  );
}
