import { useMemo } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import { usePlayerContext } from '../../context/PlayerContext';
import { PokerActionType } from '@ztp/shared';

export const usePlayerPreAction = () => {
  const { state } = usePlayerContext();
  const { player } = useAuthContext();
  const round = state.gameRound;

  const address = useMemo(() => {
    return player?.id || '';
  }, [player]);

  const currentBet = useMemo(() => {
    if (!round?.currentBets) {
      return 0;
    }
    const bets = Object.values(round?.currentBets);
    if (bets.length == 0) {
      return 0;
    }

    return Math.max(...bets);
  }, [round]);

  const myCurrentBet = useMemo(() => {
    if (round?.currentBets[address]) {
      return round?.currentBets[address];
    }
    return 0;
  }, [round, address]);

  const callAmount = useMemo(() => {
    return currentBet - myCurrentBet;
  }, [currentBet, myCurrentBet]);

  const allInAmount = useMemo(() => state.table?.players[address]?.balance || 0, [state, address]);

  const actions = useMemo(() => {
    if (!round) {
      return [];
    }

    const roundPlayer = round?.players.find((p) => p.address == address);
    if (roundPlayer?.status != 'in') {
      return [];
    }

    const balance = state.table?.players[address]?.balance || 0;
    const canCall = balance > currentBet && callAmount > 0;

    const actions = [];
    if (currentBet > 0) {
      if (callAmount == 0) {
        actions.push(PokerActionType.CheckOrFold);
        actions.push(PokerActionType.Check);
      } else if (canCall) {
        actions.push(PokerActionType.Fold);
        actions.push(PokerActionType.Call);
      } else {
        actions.push(PokerActionType.Fold);
      }
    } else {
      actions.push(PokerActionType.CheckOrFold);
      actions.push(PokerActionType.Check);
    }
    actions.push(PokerActionType.AllIn);

    return actions;
  }, [state, address, currentBet, callAmount, round]);

  return { actions, callAmount, myCurrentBet, allInAmount };
};
