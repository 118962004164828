import React from 'react';
import { Box, IconButton, Slider } from '@mui/joy';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { usePlayerContext } from '../../context/PlayerContext';
import palette from '../../styles/theme';

interface SoundButtonProps {
  hideVol?: boolean;
}

const SoundButton: React.FC<SoundButtonProps> = ({ hideVol }) => {
  const { settings, updateSettings } = usePlayerContext();

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      updateSettings({ volume: newValue });
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        color="primary"
        sx={{
          '--IconButton-size': '48px',
          flexGrow: 0,
          padding: '0',
          // Create space between the icon and the slider
          maxHeight: '32px',
          maxWidth: '32px',
          display: 'flex',
          color: palette.text.primary, // Text color from the palette
        }}
        onClick={() => {
          const enabled = !settings.soundEnabled;
          const newVolume = enabled ? 50 : 0;
          updateSettings({ soundEnabled: enabled, volume: newVolume });
        }}
      >
        {settings.soundEnabled ? <VolumeDownIcon sx={{ color: 'white' }} /> : <VolumeOffIcon sx={{ color: 'white' }} />}
      </IconButton>
      {!hideVol && (
        <Slider
          value={typeof settings.volume === 'number' ? settings.volume : 0}
          onChange={handleVolumeChange}
          min={0}
          max={100}
          sx={{
            marginLeft: '5px',
            width: '95px',
            flexGrow: 2,
            '& .MuiSlider-thumb': {
              height: 15,
              width: 15,
              bgcolor: palette.neutral.softBg, // Use a neutral background color for the thumb
              '&:hover': {
                bgcolor: palette.neutral.softHoverBg, // Hover color for the thumb
              },
              '&:focus, &:focus-visible': {
                // Targeting focus and focus-visible states
                outline: 'none !important',
                boxShadow: 'none !important', // Removing any box-shadow or glow effect
                borderColor: 'transparent !important', // Ensuring no border is visible
              },
            },
            '& .MuiSlider-track': {
              bgcolor: palette.warning.solidBg, // Use a success color for the slider track
            },
            '& .MuiSlider-rail': {
              bgcolor: palette.neutral.solidBg, // Use a neutral color for the slider rail
            },
          }}
        />
      )}
    </Box>
  );
};

export default SoundButton;
