import React, { CSSProperties, ReactNode } from 'react';

type Style = CSSProperties | CSSProperties[];

interface ViewProps {
  children?: ReactNode;
  style?: Style;
  onClick?: () => void;
}

const defaultStyle: CSSProperties = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  border: 'none',
  position: 'relative',
  boxSizing: 'border-box',
};

const View: React.FC<ViewProps> = ({ children, style = {}, onClick, ...props }) => {
  // Flatten style array if provided
  const flattenedStyle = Array.isArray(style)
    ? style.reduce((acc, curr) => ({ ...acc, ...curr }), {})
    : style;

  const combinedStyles: CSSProperties = {
    ...defaultStyle,
    ...flattenedStyle,
  };

  return (
    <div style={combinedStyles} onClick={onClick} {...props}>
      {children}
    </div>
  );
};

export default View;
